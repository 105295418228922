import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P82-E1",
    audio: "Audios/2.31.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page82/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 340,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      //   fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
		<headphone name="2.31"></headphone >
			Study the words in <span style="color: rgb(28, 190, 215)">blue</span> in the quiz. Which are nouns and which are verbs? Write
			the noun and verb forms for each word. Listen and check.
								`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex; flex-wrap: wrap; gap: 100px">
			<div>
				<i style="font-weight: bold;">director (n)</i>
			</div>

			<div>
				<i style="font-weight: bold;">direct (v)</i>
			</div>

		</div>

		<div style="margin-top:30px">
			<img src="img/FriendsPlus/Page82/12.jpg" width="1200px" />
	  </div>	

	  <h1 style="margin-top: 30px">Answers</h1>

	  	<div style="display: flex; flex-wrap: wrap;gap:30px;margin-top: 30px;">
	  		<p>#</p>
	  		<p>#</p>
	  		<p>#</p>
	  		<p>#</p>
	  		<p>#</p>
	  		<p>#</p>
	  		<p>#</p>
	  		<p>#</p>
		</div>
		
					
			
								`,
        answer: [
          "director (n), direct (v)",
          "award (v), award (n)",
          "writer (n), write (v)",
          "actor (n), act (v)",
          "adaptation (n), adapt (v)",
          "winner (n), win (v)",
          "star (n), star (v)",
          "nomination (n), nominate (v)",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P82-E2",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 340,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      //   fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		Do the quiz.
								`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:30px">
			<img src="img/FriendsPlus/Page82/12.jpg" width="1200px" />
	  	</div>	
								`,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P82-E3",
    audio: "Audios/2.32.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page82/key/E3answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      //   fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		<headphone name="2.32"></headphone >
		Listen to the recorded quiz to check your answers. Correct any wrong answers.
								`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<h1 style="margin-top: 30px">Answers</h1>

		<div style="display: flex; flex-wrap: wrap;gap:30px;margin-top: 30px;">
			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>#</span>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>#</span>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>#</span>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>#</span>
			</div>
			
		</div>

		

		

		
								`,
        answer: ["c", "b", "b", "c"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P82-E4",
    audio: "Audios/2.32.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page82/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 140,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		<headphone name="2.32"></headphone >
		Listen again and answer the questions.
								`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<div style="display: flex; flex-wrap: wrap;gap:30px;margin-top: 30px;">
			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>How many Oscars was Titanic awarded?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>When was the first story in <i>The Dark Knight</i>
				graphic novel series published?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>How many times has Robert Downey Jr.
				received an Oscar nomination for Best
				Actor?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>How many Oscar nominations have
				Jennifer Lawrence and Bradley Cooper
				received in total?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>
			
		</div>

		

		

		
								`,
        answer: ["eleven", "1987", "two times", "eleven"],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P82-E5",
    audio: "Audios/2.33.mp3",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 140,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		<headphone name="2.33"></headphone >
		<span style="color: rgb(248,129,37)">PRONUNCIATION: /g/ and /dʒ/</span> Listen and repeat.

								`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div class="container">
			<div style="display: flex; flex-wrap: wrap;gap:10px;justify-content: space-between;margin-top:30px; width:500px">

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">1</span>
					<span>manga</span>
				</div>

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">5</span>
					<span>largest</span>
				</div>

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">2</span>
					<span>guess</span>
				</div>

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">6</span>
					<span>gossip</span>
				</div>

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">3</span>
					<span>original</span>
				</div>

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">7</span>
					<span>intelligence</span>
				</div>

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">4</span>
					<span>game</span>
				</div>

				<div class="col-5">
					<span style="font-weight: bold; margin-right: 10px">8</span>
					<span>generosity</span>
				</div>

			</div>
		</div>
		
								`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P82-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 140,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
		Work in pairs. Nominate your favourite book, actor, film, director or writer
		for an award.

								`,
        color: "rgb(248,129,37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px">
			<img src="img/FriendsPlus/Page82/13.jpg" />
		</div>
		
								`,
        answer: [],
      },
    ],
  },
};

export default json;
