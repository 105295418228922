import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P84-E1",
    audio: "Audios/2.34.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page84/key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
		<headphone name="2.34"></headphone >
			Choose the correct words in the dialogue. Then watch or listen and check.
			What genres of films do they mention? Which film do Sammy and Daisy decide to watch?
						`,
        color: "rgb(220,30,116)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 600,
          //   textAlign: "center",
          fontSize: 22,
          color: "black",
          //   maxLength: 5,
        },
        answers: [
          "They mention adventure films, science fiction and fantasy.",
          "They decide to watch Karo's Two Dreams.",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
          fontSize: "22px",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          "How / When",
          "What / When",
          "acts / stars",
          "classic / fan",
          "fancy / prefer",
          "fantasy / fiction",
          "results / reviews",
        ],

        answers: ["0-0", "1-0", "2-4", "3-4", "4-0", "5-4", "6-4"],
        initialValue: {},
      },
      Layout: `
		  <div style="position: relative;margin-top:30px ">
			  <img src="img/FriendsPlus/Page84/13.jpg" width="1200px" />
	
			  <div style="position: absolute;top:33px;left:315px">
				  <input id='0' type='Circle' style="letter-spacing: 10px;"/>
			  </div>
	
			  <div style="position: absolute;top:86px;left:316px">
			  	<input id='1' type='Circle' style="letter-spacing: 10px;"/>
			  </div>
	
			  <div style="position: absolute;top:114px;left:319px">
				  <input id='2' type='Circle' style="letter-spacing: 10px;"/>
			  </div>
			  <div style="position: absolute;top:143px;left:270px;font-size:20px">
				  <input id='3' type='Circle' style="letter-spacing: 10px;"/>
			  </div>

			  <div style="position: absolute;top:167px;left:288px;">
				  <input id='4' type='Circle' style="letter-spacing: 10px;"/>
			  </div>

			  <div style="position: absolute;top:218px;left:200px;">
				  <input id='5' type='Circle' style="letter-spacing: 10px;"/>
			  </div>

			  <div style="position: absolute;top:379px;left:398px;">
				  <input id='6' type='Circle' style="letter-spacing: 10px;"/>
			  </div>
			
		  </div>
		  
		  <p style="font-size: 25px;font-weight: bold;margin-top:30px">What genres of films do they mention?</p>
		  <span><input id="0" style="letter-spacing: 10px;"/></span>

		  <p style="font-size: 25px;font-weight: bold;margin-top:30px">Which film do Sammy and Daisy decide to watch?</p>

		  <span><input id="1" style="letter-spacing: 10px;"/></span>
		  
			  
	
			  
	
					`,
    },
  },
  2: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P84-E2",
    audio: "Audios/2.34.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page84/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "28px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		<headphone name="2.34"></headphone >
		Cover the dialogue and complete the Key Phrases. Watch or listen again and
		check.

								`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="position: relative;margin-top:30px ">
			  <img src="img/FriendsPlus/Page84/14.jpg" width="1000px" />
	
			  <div style="position: absolute;top:148px;left:344px">
			  	<span >#</span>
			  </div>

			  <div style="position: absolute;top:189px;left:270px">
			  	<span >#</span>
			  </div>

			  <div style="position: absolute;top:230px;left:193px">
			  	<span >#</span>
			  </div>

			  <div style="position: absolute;top:270px;left:100px">
			  	<span >#</span>
			  </div>

			  <div style="position: absolute;top:312px;left:330px">
			  	<span >#</span>
			  </div>

			  <div style="position: absolute;top:352px;left:327px">
			  	<span >#</span>
			  </div>
	
		  </div>

		
								`,
        answer: [
          "going to the cinema tonight",
          "of his",
          "fancy",
          "rather see",
          "something I haven't seen",
          "into that kind of",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P84-E3",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "28px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		Work in pairs. Practise the dialogue.
								`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="position: relative;margin-top:30px ">
			<img src="img/FriendsPlus/Page84/15.jpg" width="1200px" />
		</div>

		
								`,
        answer: [
          "going to the cinema tonight",
          "of his",
          "fancy",
          "rather see",
          "something I haven't seen",
          "into that kind of",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P84-E4",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page84/key/E4answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `We can use <i style="font-weight: bold;">like</i> with an <i style="font-weight: bold;">-ing</i> form or with a to-infinitive. Match the examples with the uses. Two examples may match the same use. ",
       `,
        color: "rgb(220,30,116)",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "524px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "641px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "99px",
              left: "524px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "99px",
              left: "641px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "524px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "641px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "176px",
              left: "524px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
        ],
        answers: ["0-5", "2-3", "4-5", "1-6"],
        initialValue: [],
      },
      Layout: `
		  
      <table style="border: 3px solid rgb(237,170,187);margin-top:30px;border-spacing:0;border-collapse: separate;border-radius:25px;overflow:hidden;position:relative;font-size:25px;color:black">
        <thead style="text-align:center;background-color: rgb(245,211,219);">
          <th style="border-right:1px solid white">Examples</th>
          <th style="border-left:1px solid white"">Uses of LIKE</th>
        </thead>
        <tbody>

          <tr style="background-color: rgb(245,211,219);">
            <td style="padding:0 120px 0 30px;border-top:1px solid white;border-right:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px">1</span>
                <span style="font-size:25px">I like swimming in my free time</span>
              </div>
              <div><input id="0" type="boxMatch" /></div>
            </td>
            
            <td style="padding:0 20px 0 50px;border-top:1px solid white;border-left:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px">a</span>
                <span style="font-size:25px">A custom</span>
              </div>
              <div><input id="1" type="boxMatch" /></div>
            </td>
          </tr>

          <tr style="background-color: rgb(245,211,219);">
            <td style="padding:0 120px 0 30px;border-top:1px solid white;border-right:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px">2</span>
                <span style="font-size:25px"> I like to swim only when it’s hot.</span>
              </div>
              <div><input id="2" type="boxMatch" /></div>
            </td>
            
            <td style="padding:0 20px 0 50px;border-top:1px solid white;border-left:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px">b</span>
                <span style="font-size:25px">A preference or a good idea</span>
              </div>
              <div><input id="3" type="boxMatch" /></div>
            </td>
          </tr>

          <tr style="background-color: rgb(245,211,219);">
            <td style="padding:0 120px 0 30px;border-top:1px solid white;border-right:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px">3</span>
                <span style="font-size:25px"> My dad likes to go fishing at weekends.</span>
              </div>
              <div><input id="4" type="boxMatch" /></div>
            </td>
            
            <td style="padding:0 20px 0 50px;border-top:1px solid white;border-left:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px">c</span>
                <span style="font-size:25px">An activity you enjoy</span>
              </div>
              <div><input id="5" type="boxMatch" /></div>
            </td>
          </tr>

          <tr style="background-color: rgb(245,211,219);">
            <td style="padding:0 120px 0 30px;border-top:1px solid white;border-right:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px">4</span>
                <span style="font-size:25px"> Asian people like to eat with chopsticks.</span>
              </div>
              <div><input id="6" type="boxMatch" /></div>
            </td>
            
            <td style="padding:0 20px 0 50px;border-top:1px solid white;border-left:1px solid white">
              <div>
                <span style="font-weight: bold; margin-right: 10px"></span>
                <span style="font-size:25px"></span>
              </div>
            </td>
          </tr>

        </tbody>
</table>
	
		  `,
    },
  },
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P84-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "28px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Talk about which type of films you like watching, and which ones you
        aren’t keen on.
								`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="position: relative;margin-top:30px;margin-left:200px ">
			<img src="img/FriendsPlus/Page84/16.jpg"/>
		</div>

		
								`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P84-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "28px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in pairs. Read the situation, prepare and practise a new dialogue using
        the key phrases and the dialogue in exercise 1 to help you.
								`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="position: relative;margin-top:30px;margin-left:200px ">
			<img src="img/FriendsPlus/Page84/17.jpg"/>
		</div>

		
								`,
        answer: [],
      },
    ],
  },
};

export default json;
