import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P40-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page40/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 350,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
		Read the article. Which problems does the text mention?
			`,
        color: "red",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div class="container">
			<div style="border: 1px solid red; display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
			<div style="margin-right:20px"><span>pollution</span></div>
			<div style="margin-right:20px"><span>resources</span> </div>
			<div style="margin-right:20px"><span>farming</span></div>
			<div><span>crime</span> </div>
			</div>

			
		</div>
		<div style = "margin-top:20px">
		<img src ="img/FriendsPlus/Page40/6.jpg" style="width:800px; height:800px"/>
		</div>

		<div style = "margin-top:20px">
		ANSWERS: 
		#
		</div>
	
	
	
			
			`,
        answer: ["resources, farming"],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P40-E2",
    audio: "Audios/1.32.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page40/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 1050,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		<headphone name='1.32'></headphone >
		Read and listen to the article again and answer the questions.
			`,
        color: "red",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div class="container">
		
			<div style="margin-top: 30px;">
			<h2>1 What problem will we have in the next thirty years?</h2>
				#
			</div>

			<div style="margin-top: 30px;">
			<h2>2 Why are insects the solution?</h2>
				#
			</div>

			<div style="margin-top: 30px;">
			<h2>3 What does the word they in bold refer to?</h2>
				#
			</div>


			<div style="margin-top: 30px;">
			<h2>4 In what parts of the world do people eat insects?</h2>
				#
			</div>
		</div>
	
			
			`,
        answer: [
          `We will have to produce enough protein for billions more mouths.`,
          `Insects are a great source of protein and don’t need as much space or water as farm animals.`,
          `They refers to the chefs from the Nordic Food Lab.`,
          `Asia, Africa and South America.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P40-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    hideBtnFooter: true,
    inputSize: 1050,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		<span style="color:red">VOCABULARY PLUS</span> Use a dictionary to
check the meaning of the words in <span style="color:rgb(3,174,239)">blue</span> in the text. 

			`,
        color: "red",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style = "margin-top:20px">
			<img src ="img/FriendsPlus/Page40/6.jpg" style="width:1000px; height:1000px"/>
		</div>
			`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P40-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in pairs. How worried are you about world hunger? Is it a good idea to eat insects? Why / Why not?
		`,
        color: "red",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
         <img src="img/FriendsPlus/Page40/7.jpg"/>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
