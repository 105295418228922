import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P21-E1',
		exerciseKey: 'img/FriendsPlus/Page21/E1/Key/answerKey.png',
		component: T6v2,
		hintBox: [
			{
				src: ['had', 'helped', 'been', 'tried'],
				borderColor: 'rgb(28, 192, 217)',
				width: 420,
			},
		],
		inputSize: 110,
		stylesTextInput: {
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '1',
				title: `
					Cover the article on page 20. Complete the sentences with the words in the box. Then check your answers.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<hintBox id='0'></hintBox>

					<div style='margin-top: 20px;'><img src='img/FriendsPlus/Page20/E1/1.jpg' width='70%' /></div>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-wrap: wrap;'>
						<div style='display: flex; gap: 20px; flex-direction: column;'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>He has # to create fragrances.</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>She has # anosmic since birth.</div>
							</div>
						</div>

						<div style='display: flex; gap: 20px; flex-direction: column;'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>She has never # different perfumes.</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>Meals haven’t # much flavour.</div>
							</div>
						</div>
					</div>
        `,
				answer: ['helped', 'been', 'tried', 'had'],
			},
		],
	},
	2: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P21-E1',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page21/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
					Read the sentences in exercise 1 again. Then choose the correct words to complete the rules.
				`,
				color: 'rgb(0, 104, 180)',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: '-40px',
					fontWeight: 600,
					fontStyle: 'italic',
					color: '',
					// fontSize: 27,
					borderRadius: '50%',
					// marginInline: '-1px',
				},
				selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
				limitSelect: 1,
				listWordsStyle: {},
				listWords: [
					`started / finished`,
					`be / have`,
					`Regular / Irregular`,
					`affirmative / negative`,
				],
				answers: ['0-0', '1-4', '2-0', '3-4'],
				initialValue: [],
			},
			Layout: `
				<div><img src='img/FriendsPlus/Page21/E2/1.jpg' width='45%' /></div>

			  <div style="margin-top: 20px; width: 600px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(92, 201, 222);
			    font-size: 20px;">
			    <div>
			      <div style="color: rgb(255, 255, 255); font-weight: bold; text-transform: uppercase; margin-right: 10px;
			        background-color: rgb(92, 201, 222); padding: 5px 15px; border-radius: 15px;">
			        <span>RULES</span>
			      </div>
			    </div>
			    <div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">1</div>
			        <div>
								The present perfect describes life 
								experiences or recent events that <input id='0' type='Circle' /> 
								in the past and continue up to the present.
			        </div>
			      </div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">2</div>
			        <div>
								We form the present perfect with <input id='1' type='Circle' /> 
								and a past participle.
			        </div>
			      </div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">3</div>
			        <div>
								<input id='2' type='Circle' /> past participles end in <i>-ed</i>.
			        </div>
			      </div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">4</div>
			        <div>
							We use <i>not</i> or <i>never</i> to make <input id='3' type='Circle' /> sentences.
			        </div>
			      </div>
			    </div>
			  </div>
			  `,
		},
	},
	3: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P21-E1',
		exerciseKey: 'img/FriendsPlus/Page21/E3/Key/answerKey.png',
		component: T6v2,
		inputSize: 220,
		stylesTextInput: {
			fontSize: '26px',
			textAlign: 'center',
			background: 'transparent',
			border: 'none',
		},
		titleQuestion: [
			{
				num: '3',
				title: `
				Complete the sentences with the correct present perfect form of the verbs in brackets.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='position: relative;'>
						<div><img src='img/FriendsPlus/Page21/E3/1.jpg' width='70%' /></div>
						<div style='position: absolute; top: 138px; left: 161px;'>#</div>
						<div style='position: absolute; top: 241px; left: 62px;'>#</div>
						<div style='position: absolute; top: 309px; left: 157px;'>#</div>
					</div>
        `,
				answer: [
					'have visited',
					'have tried',
					`have not decided | haven't decided`,
				],
			},
		],
	},
	4: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P21-E4',
		exerciseKey: 'img/FriendsPlus/Page21/E4/Key/answerKey.png',
		component: T6v2	,
		hintBox: [
			{
				src: ['not begin', 'buy', 'not eat', 'not see', 'speak'],
				borderColor: 'rgb(28, 192, 217)',
				width: 500,
			},
		],
		inputSize: 180,
		stylesTextInput: {
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '4',
				title: `
					Read the Study Strategy. Complete the sentences using the present perfect form of the verbs.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='display:flex'>
						<img src='img/FriendsPlus/Page21/E4/1.jpg' width='45%' />
						<div style='margin-top: 20px;'><hintBox id='0'></hintBox></div>
					</div>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>You <input id="0" width="180px" /> any breakfast!</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>We # a lot of English today.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>I <input id="2" width="180px" />  that film. Is it good?</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>The match <input id="3" width="180px" /> . It starts in ten minutes.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>She # fantastic new bike.</div>
						</div>
					</div>
        `,
				answer: [
					`have not eaten | haven't eaten`,
					'have spoken',
					`have not seen | haven't seen`,
					`has not begun | hasn't begun`,
					'has bought',
				],
			},
		],
	},
	5: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P21-E5',
		exerciseKey: 'img/FriendsPlus/Page21/E5/Key/answerKey.png',
		component: T6v2,
		inputSize: 80,
		stylesTextInput: {
			fontSize: '20px',
			background: 'transparent',
			textAlign: 'center',
			borderStyle: 'dotted',
		},
		titleQuestion: [
			{
				num: '5',
				title: `				
					Study the examples and complete the rules with <i>for</i> and <i>since</i>.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='font-style: italic;'>
						James has worked for a perfume company for twenty years.<br /> 
						Lucy hasn’t had a sense of smell since birth.
					</div>

					<div style="margin-top: 20px; width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(255, 255, 255); border: 1px solid rgb(28, 192, 217); padding: 10px;">
						<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(28, 192, 217); color: white; padding: 5px 10px; border-radius: 20px; text-transform: uppercase;">
							RULES
						</div>

						<div style='display: flex; gap: 20px; flex-direction: column; font-size: 20px; margin-top: 10px; padding: 0 20px'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>We use # to talk about the point in time an activity started.</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>We use # to talk about the period of time up to the present.</div>
							</div>
						</div>
					</div>
        `,
				// title: `
				// <div style="margin-top: 20px; width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(255, 255, 255); border: 1px solid rgb(28, 192, 217); padding: 10px;">
				// 	<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(28, 192, 217); color: white; padding: 5px 10px; border-radius: 20px; text-transform: uppercase;">
				// 	RULES
				// 	</div>

				// 	<div style="margin-top: 10px; padding: 0 20px">
				// 		<div style='font-size: 20px;'>
				// 			<div>
				// 				When did you last <sup>1</sup> <input id='0' width='200px' />?
				// 			</div>
				// 			<div>
				// 				At what age did you first <sup>2</sup> <input id='1' width='170px' />?
				// 			</div>
				// 			<div>
				// 				When was the last time that you <sup>3</sup> <input id='2' width='150px' />?
				// 			</div>
				// 			<div>
				// 				What was the last <sup>4</sup> # that you <sup>5</sup> #?
				// 			</div>
				// 			<div>
				// 				What did you <sup>6</sup> # last <sup>7</sup> #?
				// 			</div>
				// 		</div>
				// 	</div>
				// </div>
				// `,
				answer: ['since', 'for'],
			},
		],
	},
	6: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P21-E6',
		exerciseKey: 'img/FriendsPlus/Page21/E6/Key/answerKey.png',
		component: T6v2,
		inputSize: 180,
		stylesTextInput: {
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '6',
				title: `
					Complete the sentences with the present perfect form of the verbs in brackets and <i>for</i> or <i>since</i>.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>I # this happy # weeks. (not feel)</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>We # him # last month. (not see)</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>They # that dog # years. (have)</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>She # in London # 2010. (live)</div>
						</div>
					</div>
        `,
				answer: [
					`haven't felt`,
					'for',
					`haven't seen`,
					'since',
					'have had',
					'for',
					'has lived',
					'since',
				],
			},
		],
	},
	7: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-US-P9-E7',
		audio: '',
		video: '',
		component: T6v2,
		hintBox: [
			{
				src: ['always', 'for weeks', 'never', 'since', 'this year', 'today'],
				borderColor: 'rgb(28, 192, 217)',
				width: 700,
			},
			{
				src: ['I have never visited Huế.'],
				borderColor: 'rgb(28, 192, 217)',
				arrow: true,
				position: 1,
				width: 350,
			},
			{
				src: ['I haven’t watched TV for weeks.'],
				borderColor: 'rgb(28, 192, 217)',
				arrow: true,
				position: 2,
				width: 420,
			},
		],
		inputSize: 800,
		stylesTextInput: {
			fontSize: '24px',
		},
		titleQuestion: [
			{
				num: '7',
				title: `
				Talk about experiences using the 
				present perfect. Use the time expressions below.
				`,
				color: 'rgb(28, 192, 217)',
				prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
			},
		],
		questionImage: [],
		hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<hintbox id='0'></hintbox>
					<div style='margin-top: 20px;'><hintbox id='1'></hintbox></div>
					<div style='margin-top: 20px; margin-left: 300px;'><hintbox id='2'></hintbox></div>
				`,
				answer: [],
			},
		],
	},
	8: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-US-P9-E7',
		audio: '',
		video: '',
		component: T6,
		finished: {
			text: 'Talk about an unforgettable experience using the present perfect.',
		},
		inputSize: 800,
		stylesTextInput: {
			fontSize: '24px',
		},
		titleQuestion: [
			{
				num: '',
				title: `
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		questionImage: [],
		hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<finished></finished>
				`,
				answer: [],
			},
		],
	},
}

export default json
