import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P32-E1",
    exerciseKey: "img/FriendsPlus/Page32/E1/Key/answerKey.png",
    component: T6v2,
    inputSize: 400,
    stylesTextInput: {
      fontSize: "20px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
					Read the blog post and answer the questions to identify the purpose of the text.
				`,
        color: "rgb(219, 46, 57)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px;'>
						<div style='flex: 2; font-size: 20px; display: flex; gap: 20px; flex-direction: column;'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>Who is writing? Why?<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>Who is the author writing for?<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>What question does she want her readers to answer?<br />#</div>
							</div>
						</div>

						<div style='flex: 3;'><img src='img/FriendsPlus/Page32/E1/1.jpg' width='100%' /></div>
					</div>
				`,
        answer: [
          `Rachel Ridley, she's recommending a blog`,
          `her blog audience`,
          `Does anyone else want some adventure?`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P32-E2",
    exerciseKey: "img/FriendsPlus/Page32/E2/Key/answerKey.png",
    audio: "Audios/1.25.mp3",
    component: T6v2,
    inputSize: 500,
    stylesTextInput: {
      fontSize: "20px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.25'></headphone >
					Read and listen to the text again and answer the questions.
				`,
        color: "rgb(219, 46, 57)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px;'>
						<div style='flex: 2; font-size: 20px; display: flex; gap: 20px; flex-direction: column;'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>How long has Liz Clark been travelling?<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>How did she get her yacht?<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>What does Liz do when she stops somewhere?<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>How does Liz pay for her trip?<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>Why does Rachel offer to join Liz on her journey?<br />#</div>
							</div>
						</div>

						<div style='flex: 3;'><img src='img/FriendsPlus/Page32/E1/1.jpg' width='100%' /></div>
					</div>
				`,
        answer: [
          `For over fifteen years.`,
          `One of her old university professors lent her his yacht.`,
          `She surfs, does yoga and writes her blog.`,
          `She has adverts on her blog.`,
          `Because she wants some adventure.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P32-E3",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 500,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				<span style="color: rgb(219, 46, 57);">VOCABULARY PLUS</span> 
				Use a dictionary to 
				check the meaning of the phrases in <span style='color: rgb(0, 173, 238);'>blue</span> in 
				the text.
				`,
        color: "rgb(219, 46, 57)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page32/E1/1.jpg' width='80%' /></div>
				`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P32-E4",
    component: T6v2,
    textareaStyle: { width: 730 },
    hideBtnFooter: true,
    inputSize: 500,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					Work in pairs. Think of three 
					advantages and three disadvantages of going 
					on a trip on your own.
				`,
        color: "rgb(219, 46, 57)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<textarea id="0" rows="10"></textarea>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
