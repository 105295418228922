import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E1",
    exerciseKey: "img/FriendsPlus/Page33/E1/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
					Study the examples of the present perfect simple 
					and continuous from the blog on page 32. Then 
					complete rules 1-3.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    question: {
      Write: {
        inputStyle: {
          width: 60,
          textAlign: "center",
          fontSize: 22,
        },
        answers: [`has`, `been`],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [`simple / continuous`, `simple / continuous`],
        answers: ["0-0", "1-4"],
        initialValue: [],
      },
      Layout: `
				<div style='font-size: 22px;'>
					<strong>Present perfect simple</strong>
					<div>She has stopped off in Mexico …</div>
					<div>‘I’ve worked hard.’</div>
					<div>‘It hasn’t been always easy or fun.’</div>
					<strong>Present perfect continuous</strong>
					<div>Liz Clark has been living that dream …</div>
					<div>I’ve been following her inspirational blog …</div>
					<div>Adverts on Liz’s blog have been paying for her trip.</div>
				</div>

			  <div style="margin-top: 20px; width: 600px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(92, 201, 222);
			    font-size: 22px;">
			    <div>
			      <div style="color: rgb(255, 255, 255); font-weight: bold; text-transform: uppercase; margin-right: 10px;
			        background-color: rgb(92, 201, 222); padding: 5px 15px; border-radius: 15px;">
			        <span>RULES</span>
			      </div>
			    </div>
			    <div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">1</div>
			        <div>
							The present perfect continuous is formed by: 
							subject + have(n’t) / <input id='0' type='Write' /> 
							(n’t) + <input id='1' type='Write' /> + verb + -ing.
			        </div>
			      </div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">2</div>
			        <div>
								The present perfect <input id='0' type='Circle' />. 
								describes general life experiences or recent events.
			        </div>
			      </div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">3</div>
			        <div>
								The present perfect <input id='1' type='Circle' />. 
								also describes events in the past that are still happening now. It emphasises the duration of the activity.
			        </div>
			      </div>
			    </div>
			  </div>
			  `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E2",
    audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page33/E2/Key/answerKey.png",
    component: T6v2,
    inputSize: 750,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.26'></headphone > 
					<span style="color: rgb(28, 192, 217);">PRONUNCIATION: /æ/ and /ə/</span>
					Listen to the examples. How do we 
					pronounce have and has when they are weak 
					forms? 
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page33/E2/1.jpg' width='40%' /></div>
					<div style='margin-top: 20px; font-weight: bold;'>How do we pronounce have and has when they are weak forms? <br />#</div>
				`,
        answer: [
          `We pronounce have and has with the /ə/ sound when they are weak.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E3",
    exerciseKey: "img/FriendsPlus/Page33/E3/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
					Read the text and choose the correct form of the verbs.
				`,
        color: "rgb(27, 191, 215)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          borderRadius: "50%",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `it’s_been / it’s_been_being`,
          `I’ve_been_sitting / I’ve_sat`,
          `I_haven’t_been_using / I_haven’t_used`,
          `I’ve_been_taking / I’ve_taken`,
          `we’ve_had / we’ve_been_having`,
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
				<div style='position: relative;'>
					<div><img src='img/FriendsPlus/Page33/E3/1.jpg' width='65%' /></div>

					<div style='width: 400px; font-size: 19px; position: absolute; top: 383px; left: 61px; transform: rotate(-1deg);'>
						<span style='font-weight: bold; color: rgb(0, 104, 180);'>Sophie</span> Hey George, how are you? We’re still on 
						our trip in Kenya and <sup>1</sup> <input id='0' type='Circle' /> 
						fantastic. <sup>2</sup> <input id='1' type='Circle' /> in a jeep for 
						the last three hours and the animals don’t seem 
						shy. <sup>3</sup> <input id='2' type='Circle' /> 
						binoculars because they come very close. Look 
						at this photo which <sup>4</sup> <input id='3' type='Circle' /> 
						from the jeep. This is such a great experience – 
						<sup>5</sup> <input id='4' type='Circle' /> a great time. 
						I don’t want it to end!
					</div>
				</div>
				`,
    },
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E4",
    exerciseKey: "img/FriendsPlus/Page33/E4/Key/answerKey.png",
    component: T6v2,
    inputSize: 250,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					Complete the sentences with the correct 
					present perfect form of the verbs in brackets.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>I # (swim) in the Atlantic. I remember it clearly.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>They # (run) for hours, so they’re feeling tired.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>We # (visit) Argentina a couple of times.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div># (you / sit) in the sun for long? Your face is very red.</div>
						</div>
					</div>
				`,
        answer: [
          `have swum`,
          `have been running`,
          `have visited`,
          `Have you been sitting`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E5",
    component: T6v2,
    hideBtnFooter: true,
    hintBox: [
      {
        src: [`do`, `feel`, `listen`, `live`, `speak`, `use`],
        borderColor: "rgb(28, 192, 217)",
        width: 450,
      },
      {
        src: ["Have you been speaking a lot of English recently?"],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 1,
        width: 600,
      },
      {
        src: ["Yes, I have. I’ve been talking to my pen pal online."],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 2,
        width: 600,
      },
    ],
    inputSize: 250,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					Work in pairs. Ask and 
					answer questions using the present perfect 
					continuous, the words in the box and the 
					ideas below.
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintBox id='0'></hintBox>

					<div style='margin-top: 20px; display: flex; gap: 40px;'>
						<div>
							<div>… English recently?</div>
							<div>… in this town for long?</div>
							<div>What … all day?</div>
						</div>
						<div>
							<div>What music … to recently?</div>
							<div>How … lately?</div>
							<div>How long … this book?</div>
						</div>
					</div>

					<div style='margin-top: 30px;'><hintBox id='1'></hintBox></div>
					<div style='margin-top: 30px; margin-left: 100px;'><hintBox id='2'></hintBox></div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
