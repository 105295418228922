import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P42-E2",
    audio: 'Audios/1.33.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page27/key/E2answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `<headphone1 name=1.33 src=''></headphone1> Listen to the adjectives in the box 
        then underline the stressed syllable in each 
        word.`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    isHiddenCheck: true,
    component: Circle_Write,
    hintBox: [{
      src: ["eggs", "fruit", "milk", "pork", "wheat"],
      width: 400,
      borderColor: "none",
      fontColor: "#749b28",
      backgroundBox: "none",
      paddingBlock: 0,

    }],

    question: {
      Write: {
        isHiddenCheck: true,

        inputStyle: { width: 200, textAlign: 'center' },
        answers: [

        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: "transparent",
          // border:"2px solid"
          // fontWeight:"bold"
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: "solid 2px",
          borderColor: "black",
        },
        limitSelect: 15,
        listWords: [
          `auto|ma|tic &emsp; dig|ital &emsp; eco|log|ical &emsp; 
      elect|ron|ic &emsp; hi-|tech &emsp; home-|made &emsp; 
      multi-|fun|ctional &emsp; nat|ural &emsp; re|cy|cled &emsp; 
      second-|hand &emsp; smart &emsp; so|lar &emsp; 
      use|ful &emsp; wat|erproof`,//2

        ],
        answers: ['0-2', '0-8', '0-16', '0-38', '0-46', '0-52', '0-72', '0-78', '0-86', '0-108', '0-112', '0-116', '0-136', '0-142'],
        initialValue: [],
      },
      Layout: `
      <div style='width:20cm; border:2px solid rgb(243 159 88);padding:3mm 5mm;border-radius:15px;text-align:center'>
      
        <input id='0' type='Circle'/>
      </div>

	  			
      `,
    },
  },




  2: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P42-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page42/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Look at photos A-F. Use the adjectives in exercise 1 to describe the objects.
				`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page42/14.jpg" /></div>   
        <h1>ANSWERS</h1>
        <div style="display: flex; flex-wrap: wrap;">
          <p style="font-size:25px; margin-right: 30px;">A#</p>
          <p style="font-size:25px; margin-right: 30px;">B#</p>
          <p style="font-size:25px; margin-right: 30px;">C#</p>
          <p style="font-size:25px; margin-right: 30px;">D#</p>
          <p style="font-size:25px; margin-right: 30px;">E#</p>
          <p style="font-size:25px; margin-right: 30px;">F#</p>
        </div>
			
				
				`,
        answer: [
          "automatic",
          "digital, smart",
          "electronic, solar",
          "recycled",
          "second-hand",
          "waterproof",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P42-E3",
    audio: "Audios/1.34.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page42/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    textareaStyle: { width: 730 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        <headphone name="1.34"></headphone >
        Listen to a radio programme about unusual shoes. Why are the shoes unusual?
				`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <h1>ANSWERS</h1>
        <div><textarea id="0" rows="3"></textarea></div>
			
				
				`,
        answer: [
          `Because they get bigger or smaller, so poor children who can't afford new shoes can wear them.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P42-E4",
    audio: "Audios/1.34.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page42/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        <headphone name="1.34"></headphone >
        Listen again and complete the sentences with the correct adjectives in exercise 1.
				`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <p style="font-size: 25px ;margin-top: 20px;">
        <span style="font-weight: bold;">1</span> Murray talks about special shoes that can help children and are # at the
          same time.
        </p>

        <p style="font-size: 25px; margin-top: 20px">
        <span style="font-weight: bold;">2</span> A lot of children have problems because they wear # shoes which are not
          the right size for their feet.
        </p>

        <p style="font-size: 25px; margin-top: 20px">
          <span style="font-weight: bold;">3</span> Murray says that the shoes are very simple
          and not # at all.
        </p>
				

        <p style="font-size: 25px; margin-top: 20px">
          <span style="font-weight: bold;">4</span> The shoes are practical and useful, but
          they aren't # 
        </p>

        <p style="font-size: 25px; margin-top: 20px">
          <span style="font-weight: bold;">5</span> The next story will be about clothes made
          from # and natural materials.
        </p>
				`,
        answer: [
          "ecological",
          "second-hand",
          "hi-tech",
          "waterproof",
          "recycled",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P42-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page42/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 50,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Which of these statements taken from the listening are fact (F) and which are opinion (O)? How do you know?
				`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <p style="font-size: 25px; margin-top: 20px">
          <span style="font-weight: bold;">1</span> There are millions of children in the world
          who don't have shoes #
        </p>

        <p style="font-size: 25px; margin-top: 20px">
          <span style="font-weight: bold;">2</span> That sounds a bit hi-tech #
        </p>

        <p style="font-size: 25px; margin-top: 20px">
          <span style="font-weight: bold;">3</span> That's really useful, isn't it?#
        </p>

        <p style="font-size: 25px; margin-top: 20px">
          <span style="font-weight: bold;">4</span> At the moment, people are wearing them in Ecuador#
        </p>
				`,
        answer: ["F", "O", "O", "F"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P42-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in groups. Think of an invention that can help people. Then ask and answer questions using the key phrases.
        Whose object is the most useful?
		`,
        color: "rgb(248,129,37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page42/15.jpg"/></div>
        <div><img src="img/FriendsPlus/Page42/16.jpg"/></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
