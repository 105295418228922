import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E1",
    audio: "Audios/1.19.mp3",
    video: 'https://cdn.sachso.edu.vn/videos/8sb/Unit 2.mp4',
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page24/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
					<videoimage></videoimage> 
					<headphone name='1.19'></headphone >
					Choose the correct words in the dialogue. 
					Then watch or listen and check. 
					What does Louise want to do next weekend?
					Where does Grace suggest going for lunch?
				`,
        color: "rgb(220, 30, 116)",
      },
    ],
    question: {
      Write: {
        inputStyle: {
          fontSize: "24px",
        },
        answers: [
          "Louise wants to go bowling / She wants to go bowling",
          `Grace suggests a great restaurant near here. / She suggests a great restaurant near here.`,
        ],

        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          borderRadius: "50%",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `been / seen`,
          `been / had`,
          `been / made`,
          `had / taken`,
          `ate / eaten`,
          `had / have`,
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
				<div style='display: flex; flex-direction: column; gap: 10px;'>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Louise</div>
						<div>
							Have you <sup>1</sup> <input id='0' type='Circle' /> to Bowl You Over, the new bowling club?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Grace</div>
						<div>
							Actually, I’ve never <sup>2</sup> <input id='1' type='Circle' /> bowling.<br />
							I’ve heard it’s a bit boring.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Louise</div>
						<div>
							You’ve never <sup>3</sup> <input id='2' type='Circle' /> bowling? <br />
							Seriously? Let’s try it next weekend. We can invite Lori and Jack, too.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Grace</div>
						<div>
							It doesn’t sound much fun to me, but we’ll see what they say.<br />
							I’m hungry. Have you <sup>4</sup> <input id='3' type='Circle' /> lunch?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Louise</div>
						<div>
							No, I haven’t <sup>5</sup> <input id='4' type='Circle' /> since breakfast.<br />
							Let’s have lunch together.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Grace</div>
						<div>
							What do you fancy eating?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Louise</div>
						<div>
							I don’t know. Fish and chips, I suppose.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Grace</div>
						<div>
							Wait a second. There’s a great restaurant really near here. Come on.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Louise</div>
						<div>
							OK.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Grace</div>
						<div>
							Here it is.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Louise</div>
						<div>
							Can’t we <sup>6</sup> <input id='5' type='Circle' /> fish and chips?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Grace</div>
						<div>
							Why not try something different? The food here is delicious. I think you’ll enjoy it.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Louise</div>
						<div>
							OK. I’ll give it a try.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold;'>Grace</div>
						<div>
							Great.
						</div>	
					</div>
				</div>

				<div style='margin-top: 15px'>
					<div style='font-weight: bold;'>What does Louise want to do next weekend?</div>
					<div><input id='0' type='Write' width='500px' /></div>
					<div style='font-weight: bold;'>Where does Grace suggest going for lunch?</div>
					<div><input id='1' type='Write' width='500px' /></div>
				</div>
				<div></div>
			`,
    },
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E2",
    audio: "Audios/1.19.mp3",
    video: 'https://cdn.sachso.edu.vn/videos/8sb/Unit 2.mp4',
    exerciseKey: "img/FriendsPlus/Page24/E2/Key/answerKey.png",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "20px",
      textAlign: "center	",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<videoimage></videoimage> 
					<headphone name='1.19'></headphone >
					Cover the dialogue and complete the Key Phrases. Watch or listen again and check.
				`,
        color: "rgb(220, 30, 116)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; align-items: start;'>
						<div style='width: 45%;'><img src='img/FriendsPlus/Page24/E2/1.jpg' width='100%' /></div>

						<div style="width: 520px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(246, 211, 219);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(227, 114, 149); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								Key Phrases
							</div>
							<div style="margin-top: 10px; padding: 0 20px">
								<div style="color: rgb(149, 19, 90); font-weight: bold; font-size: 1.6rem">
									Talk about the past
								</div>
						
								<div style='font-size: 20px;'>
									<div>
										I’ve heard it’s a bit <sup>1</sup> #?
									</div>
									<div>
										It doesn’t sound <sup>2</sup> # to me ...
									</div>
									<div>
										What do you fancy <sup>3</sup> #?
									</div>
									<div>
										Can’t we <sup>4</sup> #?
									</div>
									<div>
										Why not try <sup>5</sup> #?
									</div>
									<div>
										I think <sup>6</sup> #?
									</div>
								</div>
							</div>
						</div>
					</div>
        `,
        answer: [
          `boring`,
          `much fun`,
          `eating`,
          `have fish and chips`,
          `something different`,
          `you'll enjoy it`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E3",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "20px",
      textAlign: "center	",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					Practise the dialogue with your partner.
				`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page24/E2/1.jpg' width='55%' /></div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E4",
    audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page24/E4/Key/answerKey.png",
    component: T6v2,
    textareaStyle: { width: 730 },
    inputSize: 200,
    stylesTextInput: {
      fontSize: "20px",
      textAlign: "center	",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
						<headphone name='1.20'></headphone >	 
						<span style="color: rgb(220, 30, 116);">Stress for emphasis</span> 
						Listen and repeat the sentences. Which sentence has more stressed words? Why?
						`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "PRONUNCIATION" },
        // prefix: { icons: ['fas fa-pencil-alt'], text: 'PRONUNCIATION' },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='font-style: italic;'>
						<div>Have you been to Bowl You Over?</div>
						<div>You’ve never been bowling? Seriously?</div>
					</div>

					<div>
						<strong>Answer:</strong>
						<div><textarea id="0" rows="2" ></textarea></div>
					</div>
        `,
        answer: [
          `The second question has more stressed words because Louise is surprised, and we use stress to express surprise.`,
        ],
      },
    ],
  },
  5: {
    nit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E5",
    component: T6v2,
    hintBox: [
      {
        src: ["What do you fancy doing?"],
        borderColor: "rgb(220, 30, 116)",
        arrow: true,
        position: 1,
        width: 350,
      },
      {
        src: ["Let’s watch a scary film after school."],
        borderColor: "rgb(220, 30, 116)",
        arrow: true,
        position: 2,
        width: 470,
      },
      {
        src: ["It doesn’t sound much fun to me."],
        borderColor: "rgb(220, 30, 116)",
        arrow: true,
        position: 1,
        width: 410,
      },
    ],
    inputSize: 200,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				Work in pairs. Read situations 1-3. Take turns to suggest doing something after school. Use the key phrases.
				`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 50px; align-items: start;'>
					
						<div style='display: flex; flex-direction: column;'>
							<div style='font-style: italic;'>
							<div style='display: flex; flex-direction: row;'>
							<div style='font-weight:bold; margin-right:20px'>1</div>
							<div>watch a scary film</div>
							</div>
							<div style='display: flex; flex-direction: row;'>
							<div style='font-weight:bold; margin-right:20px'>2</div>
							<div>go for a bicycle ride</div>
							</div>
							<div style='display: flex; flex-direction: row;'>
							<div style='font-weight:bold; margin-right:20px'>3</div>
							<div>play a video game</div>

							</div>
								
							
							</div>
							</div>
							

						
					</div>

					<div style='margin-top: 50px;'><hintbox id='0'></hintbox></div>
					<div style='margin-top: 20px; margin-left: 300px;'><hintbox id='1'></hintbox></div>
					<div style='margin-top: 40px;'><hintbox id='2'></hintbox></div>
				`,
        answer: [],
      },
    ],
  },
  6: {
    nit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E6",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
				Work in pairs. Read the situation. Then prepare and practise a new dialogue. Use the key phrases and the dialogue in exercise 1 to help you.
				`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; align-items: start;'>
						<div style='order: 2; flex: 1;'><img src='img/FriendsPlus/Page24/E2/1.jpg' width='100%' /></div>

						<div style='order: 1; flex: 1; display: flex; gap: 10px; flex-direction: column;'>
							<div style='width: 520px; background-color: rgba(246, 211, 219); padding: 15px; border-radius: 20px; display: flex; gap: 20px; flex-direction: column;'>
								<div style='border: 2px solid rgb(220, 30, 116); padding: 15px;'>
									<strong>Student A:</strong> 
									You want to go camping in the 
									countryside at the weekend. Listen to what your 
									friend suggests instead and respond.
								</div>
								<div style='border: 2px solid rgb(220, 30, 116); padding: 15px;'>
									<strong>Student B:</strong> 
									You don’t want to go camping in 
									the countryside at the weekend. You fancy 
									watching a film then having dinner. Suggest 
									what type of film and food you want.
								</div>
							</div>

							
						</div>
					</div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
