import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U4-P50-E1",
    audio: "",

    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,

    component: T6v2,
    // maxLength: 6,
    inputSize: 120,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
			Check the meaning of the phrases in the box. Decide in which age group you are most likely to
			do each one.

								`,
        color: "rgb(0,103,180)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
			<div class="container">

				<div style="display: flex; flex-wrap: wrap;justify-content:center;margin-top:20px;">
					<span style="font-size: 20px; background-color:rgb(240,113,75) ; color:white;padding:0px 8px; margin-right:10px">
						10 - 16
					</span>

					<span style="font-size: 20px; background-color:rgb(169,207,56) ; color:white;padding:0px 8px;margin-right:10px">
						16 - 20
					</span>

					<span style="font-size: 20px; background-color:rgb(249,169,86) ; color:white;padding:0px 8px;margin-right:10px">
						20 - 30
					</span>

					<span style="font-size: 20px; background-color:rgb(162,115,109) ; color:white;padding:0px 8px;margin-right:10px">
						30 - 40
					</span>

					<span style="font-size: 20px; background-color:rgb(74,194,203) ; color:white;padding:0px 8px;margin-right:10px">
						40 - 50
					</span>

					<span style="font-size: 20px; background-color:rgb(231,111,145) ; color:white;padding:0px 16px;margin-right:10px">
						50+
					</span>
				</div>


				<div style="border: 2px solid rgb(0,103,180); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a bank account</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a boyfriend / girlfriend</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a degree</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a driving licence</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a job</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a new hobby</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a pension</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a phone</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a social media account</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get married</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get old</p>
					<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get rich</p>
				</div>
	
			</div>
		
			
								`,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U4-P50-E2",
    audio: "Audios/2.02.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/key/E2answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		<headphone name="2.02"></headphone >
		Do the quiz. Compare your answers with a partner's. Then listen and check.
								`,
        color: "rgb(0,103,180)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div class="container">
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          gap: 30px;
          margin-top: 30px;
        "
      >
        <div
          class="col-5"
          style="
            box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
              rgba(17, 17, 26, 0.05) 0px 8px 32px;
            border: 1px solid white;
            border-bottom-right-radius: 20px;
            position: relative;
            padding: 20px;
          "
        >
          <h1
            style="
              background-color: black;
              color: rgb(10, 161, 222);
              padding-left: 10px;
              margin-bottom: 0;
            "
          >
		  0–5 years
          </h1>
          <div
            style="
              background-color: rgb(10, 161, 222);
              color: white;
              margin-top: 0px;
              border-bottom-right-radius: 10px;
			  padding: 10px;

            "
          >
            <p style="font-size: 20px; padding-left: 10px">
              Which of these things can children normally do before they're
              five?
            </p>
            <p style="font-size: 20px; padding-left: 10px;"> <span style="margin-right: 10px">a</span> Get a new hobby</p>
            <p style="font-size: 20px; padding-left: 10px">
			<span style="margin-right: 10px">b</span> Read and write a 200-word story
            </p>
            <p style="font-size: 20px; padding-left: 10px">
			<span style="margin-right: 10px">c</span> Get a bank account
            </p>
          </div>

          <span
            style="
              position: absolute;
              top: -15px;
              right: 40px;
              padding: 8px 20px;
              border-radius: 9999999999px;
              background-color: rgb(69, 184, 230);
              color: white;
              text-align: center;
              font-size: 25px;
            "
            >1</span
          >
        </div>

		<div
          class="col-5"
          style="
            box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
              rgba(17, 17, 26, 0.05) 0px 8px 32px;
            border: 1px solid white;
            border-bottom-right-radius: 20px;
            position: relative;
            padding: 20px;
          "
        >
          <h1
            style="
              background-color: black;
              color: rgb(144, 67, 52);
              padding-left: 10px;
              margin-bottom: 0;
            "
          >
		  	13–19 years
          </h1>
          <div
            style="
              background-color: rgb(241, 73, 78);
              color: white;
              margin-top: 0px;
			  padding: 10px;
              border-bottom-right-radius: 10px;
            "
          >
            <p style="font-size: 20px; padding-left: 10px">
              Most teenagers have got a social media account nowadays. How old
              do you usually need to be to get a social media account?
            </p>
            <span style="font-size: 20px; padding-left: 10px;margin-right: 10px"> <span style="margin-right: 10px">a</span> Thirteen</span>
            <span style="font-size: 20px; padding-left: 10px;margin-right: 10px"> <span style="margin-right: 10px">b</span> Fourteen</span>
            <span style="font-size: 20px; padding-left: 10px;margin-right: 10px"> <span style="margin-right: 10px">c</span> Fifteen</span>
          </div>

          <span
            style="
              position: absolute;
              top: -15px;
              right: 40px;
              padding: 8px 20px;
              border-radius: 9999999999px;
              background-color: rgb(241, 73, 78);
              color: white;
              text-align: center;
              font-size: 25px;
            "
            >3</span
          >
        </div>

		<div
          class="col-5"
          style="
            box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
              rgba(17, 17, 26, 0.05) 0px 8px 32px;
            border: 1px solid white;
            border-bottom-right-radius: 20px;
            position: relative;
            padding: 20px;
          "
        >
          <h1
            style="
              background-color: black;
              color: rgb(121,193,66);
              padding-left: 10px;
              margin-bottom: 0;
            "
          >
            6–12 years
          </h1>
          <div
            style="
              background-color: rgb(121,193,66);
              color: white;
              margin-top: 0px;
			  padding: 10px;

              border-bottom-right-radius: 10px;
            "
          >
            <p style="font-size: 20px; padding-left: 10px">
              Surveys show we are happiest around nine to ten years old. Why?

            </p>
            <p style="font-size: 20px; padding-left: 10px"> <span style="margin-right: 10px">a</span> It's when we get rich</p>
            <p style="font-size: 20px; padding-left: 10px"> <span style="margin-right: 10px">b</span> It's when we get a pension. </p>
            <p style="font-size: 20px; padding-left: 10px"> <span style="margin-right: 10px">c</span> It's when we have the most fun and the fewest worries.</p>
          </div>

          <span
            style="
              position: absolute;
              top: -15px;
              right: 40px;
              padding: 8px 20px;
              border-radius: 9999999999px;
              background-color: rgb(121,193,66);
              color: white;
              text-align: center;
              font-size: 25px;
            "
            >2</span
          >
        </div>

		<div
          class="col-5"
          style="
            box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
              rgba(17, 17, 26, 0.05) 0px 8px 32px;
            border: 1px solid white;
            border-bottom-right-radius: 20px;
            position: relative;
            padding: 20px;
          "
        >
          <h1
            style="
              background-color: black;
              color: rgb(0,177,183);
              padding-left: 10px;
              margin-bottom: 0;
            "
          >
            20–29 years
          </h1>
          <div
            style="
              background-color: rgb(0,177,183);
              color: white;
              margin-top: 0px;
              padding: 10px;
              border-bottom-right-radius: 10px;
            "
          >
            <p style="font-size: 20px; padding-left: 10px">
              When they have got a degree or finished their studies, people in their twenties often
              get a job. Which of these things can't you do in the UK until you're twenty-one?
            </p>
            <p style="font-size: 20px; padding-left: 10px">
              <span style="margin-right: 10px">a</span> Get a driving licence

            </p>
            <p style="font-size: 20px; padding-left: 10px">
              <span style="margin-right: 10px">b</span> Adopt a child
            </p>
            <p style="font-size: 20px; padding-left: 10px">
              <span style="margin-right: 10px">c</span> Get a boyfriend or girlfriend
            </p>
          </div>

          <span
            style="
              position: absolute;
              top: -15px;
              right: 40px;
              padding: 8px 20px;
              border-radius: 9999999999px;
              background-color: rgb(0,177,183);
              color: white;
              text-align: center;
              font-size: 25px;
            "
            >4</span
          >
        </div>

		<div
		class="col-5"
		style="
		  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
			rgba(17, 17, 26, 0.05) 0px 8px 32px;
		  border: 1px solid white;
		  border-bottom-right-radius: 20px;
		  position: relative;
		  padding: 20px;
		"
	  >
		<h1
		  style="
			background-color: black;
			color: rgb(243,114,32);
			padding-left: 10px;
			margin-bottom: 0;
		  "
		>
		 30–49 years
		</h1>
		<div
		  style="
			background-color: rgb(243,114,32);
			color: white;
			margin-top: 0px;
			padding: 10px;
			border-bottom-right-radius: 10px;
		  "
		>
		  <p style="font-size: 20px; padding-left: 10px">
		  At this age, many people are married. According to scientists, if you get married,
		  you'll probably …
		  </p>
		  <p style="font-size: 20px; padding-left: 10px">
			<span style="margin-right: 10px">a</span> be happy

		  </p>
		  <p style="font-size: 20px; padding-left: 10px">
			<span style="margin-right: 10px">b</span> die younger
		  </p>
		  <p style="font-size: 20px; padding-left: 10px">
			<span style="margin-right: 10px">c</span> live longer
		  </p>
		</div>

		<span
		  style="
			position: absolute;
			top: -15px;
			right: 40px;
			padding: 8px 20px;
			border-radius: 9999999999px;
			background-color: rgb(243,114,32);
			color: white;
			text-align: center;
			font-size: 25px;
		  "
		  >5</span
		>
	  </div>

	  <div
	  class="col-5"
	  style="
		box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
		  rgba(17, 17, 26, 0.05) 0px 8px 32px;
		border: 1px solid white;
		border-bottom-right-radius: 20px;
		position: relative;
		padding: 20px;
	  "
	>
	  <h1
		style="
		  background-color: black;
		  color: rgb(171,184,206);
		  padding-left: 10px;
		  margin-bottom: 0;
		"
	  >
	  70–100 years
	  </h1>
	  <div
		style="
		  background-color: rgb(171,184,206);
		  color: white;
		  margin-top: 0px;
		  padding: 10px;
		  border-bottom-right-radius: 10px;
		"
	  >
		<p style="font-size: 20px; padding-left: 10px">
		  People are getting older. In 1900, the average life expectancy in Europe was forty-three. What is it now?
		</p>
		<p style="font-size: 20px; padding-left: 10px">
		  <span style="margin-right: 10px">a</span> About seventy

		</p>
		<p style="font-size: 20px; padding-left: 10px">
		  <span style="margin-right: 10px">b</span> About eighty
		</p>
		<p style="font-size: 20px; padding-left: 10px">
		  <span style="margin-right: 10px">c</span> About ninety
		</p>
	  </div>

	  <span
		style="
		  position: absolute;
		  top: -15px;
		  right: 40px;
		  padding: 8px 20px;
		  border-radius: 9999999999px;
		  background-color: rgb(171,184,206);
		  color: white;
		  text-align: center;
		  font-size: 25px;
		"
		>8</span
	  >
	</div>

	<div
	class="col-5"
	style="
	  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
		rgba(17, 17, 26, 0.05) 0px 8px 32px;
	  border: 1px solid white;
	  border-bottom-right-radius: 20px;
	  position: relative;
	  padding: 20px;
	"
  >
	<h1
	  style="
		background-color: black;
		color: rgb(139,80,78);
		padding-left: 10px;
		margin-bottom: 0;
	  "
	>
	50–59 years
	</h1>
	<div
	  style="
		background-color: rgb(139,80,78);
		color: white;
		margin-top: 0px;
		padding: 10px;
		border-bottom-right-radius: 10px;
	  "
	>
	  <p style="font-size: 20px; padding-left: 10px">
		People often become grandparents at this age. What is the record number of
		grandchildren to one grandparent in the world?
	  </p>
	  <span style="font-size: 20px; padding-left: 10px;margin-right: 10px">
		<span style="margin-right: 10px">a</span> 99

	  </span>
	  <span style="font-size: 20px; padding-left: 10px;margin-right: 10px">
		<span style="margin-right: 10px">b</span> 140
	  </span>
	  <span style="font-size: 20px; padding-left: 10px;margin-right: 10px">
		<span style="margin-right: 10px">c</span> 247
	  </span>
	</div>

	<span
	  style="
		position: absolute;
		top: -15px;
		right: 40px;
		padding: 8px 20px;
		border-radius: 9999999999px;
		background-color: rgb(139,80,78);
		color: white;
		text-align: center;
		font-size: 25px;
	  "
	  >6</span
	>
  </div>


  <div
  class="col-5"
  style="
	box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
	  rgba(17, 17, 26, 0.05) 0px 8px 32px;
	border: 1px solid white;
	border-bottom-right-radius: 20px;
	position: relative;
	padding: 20px;
  "
>
  <h1
	style="
	  background-color: black;
	  color: rgb(225,33,114);
	  padding-left: 10px;
	  margin-bottom: 0;
	"
  >
  60–79 years
  </h1>
  <div
	style="
	  background-color: rgb(225,33,114);
	  color: white;
	  margin-top: 0px;
	  padding: 10px;
	  border-bottom-right-radius: 10px;
	"
  >
	<p style="font-size: 20px; padding-left: 10px">
	  When people get a pension, they often lead a quiet life, but not always! In the Senior
	  Olympics event in the USA, for example, older people compete in every Olympic sport.
	  What's the world record for the women's 100 metres for women over seventy?
	</p>
	<p style="font-size: 20px; padding-left: 10px">
	  <span style="margin-right: 10px">a</span> 10.6 seconds

	</p>
	<p style="font-size: 20px; padding-left: 10px">
	  <span style="margin-right: 10px">b</span> 14.6 seconds
	</p>
	<p style="font-size: 20px; padding-left: 10px">
	  <span style="margin-right: 10px">c</span> 20.6 seconds
	</p>
  </div>

  <span
	style="
	  position: absolute;
	  top: -15px;
	  right: 40px;
	  padding: 8px 20px;
	  border-radius: 9999999999px;
	  background-color: rgb(225,33,114);
	  color: white;
	  text-align: center;
	  font-size: 25px;
	"
	>7</span
  >
</div>


      </div>

	  	<h2 style="margin-top: 30px;">ANSWERS</h2>
	  	<div style="display: flex; flex-wrap: wrap;">
      		<p style="font-size: 20px;margin-right:30px;"><span style="font-weight: bold;font-size: 20px;">1</span>#</p>
      		<p style="font-size: 20px;margin-right:30px;"><span style="font-weight: bold;font-size: 20px;">2</span>#</p>
      		<p style="font-size: 20px;margin-right:30px;"><span style="font-weight: bold;font-size: 20px;">3</span>#</p>
      		<p style="font-size: 20px;margin-right:30px;"><span style="font-weight: bold;font-size: 20px;">4</span>#</p>
      		<p style="font-size: 20px;margin-right:30px;"><span style="font-weight: bold;font-size: 20px;">5</span>#</p>
      		<p style="font-size: 20px;margin-right:30px;"><span style="font-weight: bold;font-size: 20px;">6</span>#</p>
      		<p style="font-size: 20px;margin-right:30px;"><span style="font-weight: bold;font-size: 20px;">7</span>#</p>
      		<p style="font-size: 20px;"><span style="font-weight: bold;font-size: 20px;">8</span>#</p>
    	</div>

    </div>


		
			
		`,
        answer: ["a", "c", "a", "b", "c", "b", "b", "a"],
      },
    ],
  },
};

export default json;
