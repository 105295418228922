import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  8: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E8",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E8/Key/answerKey.png",
    component: T6v2,
    inputSize: 110,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "24px",
      background: "transparent",
      marginLeft: "-13px",
    },
    titleQuestion: [
      {
        num: "8",
        title: `
					Complete the words.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>Tone-d# makes it difficult to play a musical instrument well.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>We can’t t# the objects in the museum.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>Beethoven lost his h# when he was thirty, and was deaf for the rest of his life.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>You need to have near-perfect s# to be an airline pilot.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>We lost s# in our toes when we were walking in the snow.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>6</span>
							<div>Can you t# the sugar in your tea?</div>
						</div>
					</div>
        `,
        answer: [`eafness`, `ouch`, `earing`, `ight`, `ensation`, `aste`],
      },
    ],
  },
  9: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E9",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page28/E9/Key/answerKey.png",
    titleQuestion: [
      {
        num: "9",
        title: `
					Choose the correct words.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `flavour / fragrance`,
          `texture / scent`,
          `flavour / odour`,
          `texture / scent`,
          `scent / perfume`,
          `odour / fragrance`,
        ],
        answers: ["0-0", "1-0", "2-4", "3-4", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
				<div style='display: flex; gap: 20px; flex-direction: column;'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>1</span>
						<div>'What’s your favourite chewing gum <input id='0' type='Circle' />?' 'I love mint and strawberry.'</div>
						
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>2</span>
						<div>This cake has a creamy <input id='1' type='Circle' />.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>3</span>
						<div>When we walked into the gym, we noticed a strong <input id='2' type='Circle' /> of sweat.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>4</span>
						<div>Mia opened the window and the <input id='3' type='Circle' /> of garden flowers filled the room.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>5</span>
						<div>I got my first <input id='4' type='Circle' /> as a birthday present from my parents.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>6</span>
						<div>I love the <input id='5' type='Circle' /> of flowers in nature.</div>
					</div>
				</div>
			  `,
    },
  },
  10: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E10",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E10/Key/answerKey.png",
    component: T6v2,
    inputSize: 180,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "10",
        title: `
				Complete the sentences using the correct 
				present perfect form of the verbs and <i>for</i> or 
				<i>since</i>.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>They # (not eat) ice cream # last summer.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div style='display: flex; flex-direction: column; gap: 10px;'>
								<div>‘# (she / have) problems with her hearing # long?’</div>
								<div>‘Yes, she #.’</div>
							</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>He # (be) colour-blind # he was born.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>I # (take) this bus every day # six months.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>We # (not see) our cousins # ages.</div>
						</div>
					</div>
        `,
        answer: [
          `haven't eaten | have not eaten`,
          `since`,
          `Has she had`,
          `for`,
          `has`,
          `'s been | has been`,
          `since`,
          `'ve taken | have taken`,
          `for`,
          `haven't seen | have not seen`,
          `for`,
        ],
      },
    ],
  },
  11: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E11",
    audio: "Audios/1.22.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E11/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "11",
        title: `
					<headphone name='1.22'></headphone > 
					Listen to a radio programme about 
					people’s experiences at birthday parties. 
					Match Grace’s and James’s experiences 1-10 
					to adjectives a-j.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    question: {
      DrawLines: {
        boxMatch: [
          // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "45px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "93px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "144px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "191px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "342px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "392px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "443px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },

          // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "494px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "546px",
              left: "265px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "45px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "93px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "144px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "191px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "295px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 16
          {
            boxMatchStyle: {
              position: "absolute",
              top: "342px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 17
          {
            boxMatchStyle: {
              position: "absolute",
              top: "392px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 18
          {
            boxMatchStyle: {
              position: "absolute",
              top: "443px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 19
          {
            boxMatchStyle: {
              position: "absolute",
              top: "494px",
              left: "418px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
        ],
        answers: [
          "0-14",
          "1-16",
          "12-2",
          "19-3",
          "10-4",
          "15-5",
          "13-6",
          "17-7",
          "18-8",
          "11-9",
        ],
        initialValue: [],
      },
      Layout: `
				<div style='font-size: 20px;'>
					<div>Grace:</div>
					<div style='display: flex; gap: 200px;'>
						<div style='display: flex; gap: 20px; flex-direction: column;'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>the birthday party ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>the place ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>the girls looked ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>the food ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>the cupcakes ..........</div>
							</div>

							<div>James:</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>6</span>
								<div>the birthday party ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>7</span>
								<div>waiting in a queue ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>8</span>
								<div>the rides weren’t ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>9</span>
								<div>the cinema tickets ..........</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>10</span>
								<div>the popcorn ..........</div>
							</div>
						</div>

						<div style='display: flex; gap: 20px; flex-direction: column;'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>a</span>
								<div>a delicious</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>b</span>
								<div>disgusting</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>c</span>
								<div>wonderful</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>d</span>
								<div>exhausted</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>e</span>
								<div>amazing</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>f</span>
								<div>awful</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>g</span>
								<div>nice</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>h</span>
								<div>terrifying</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>i</span>
								<div>furious</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>j</span>
								<div>miserable</div>
							</div>
						</div>
					</div>
				</div>

				<input id='0' type= 'boxMatch' />
				<input id='1' type= 'boxMatch' />
				<input id='2' type= 'boxMatch' />
				<input id='3' type= 'boxMatch' />
				<input id='4' type= 'boxMatch' />
				<input id='5' type= 'boxMatch' />
				<input id='6' type= 'boxMatch' />
				<input id='7' type= 'boxMatch' />
				<input id='8' type= 'boxMatch' />
				<input id='9' type= 'boxMatch' />
				<input id='10' type= 'boxMatch' />
				<input id='11' type= 'boxMatch' />
				<input id='12' type= 'boxMatch' />
				<input id='13' type= 'boxMatch' />
				<input id='14' type= 'boxMatch' />
				<input id='15' type= 'boxMatch' />
				<input id='16' type= 'boxMatch' />
				<input id='17' type= 'boxMatch' />
				<input id='18' type= 'boxMatch' />
				<input id='19' type= 'boxMatch' />
      `,
    },
  },
};

export default json;
