import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P31-E3",
    audio: "Audios/1.24.mp3",
    video: 'https://cdn.sachso.edu.vn/videos/8sb/Unit 3.mp4',
    exerciseKey: "img/FriendsPlus/Page31/E3/Key/answerKey.png",
    component: T6v2,
    inputSize: 240,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					<headphone name='1.24'></headphone >
					Watch or listen to Max, Alicia and Elizabeth talking about places and activities. What place would each person like to visit?
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='font-weight: bold; font-size: 26px; text-decoration: underline;'>Answers:</div>
					<div style='font-weight: bold;'>Speaker 1 (Max): #</div>
					<div style='font-weight: bold;'>Speaker 2 (Alicia): #</div>
					<div style='font-weight: bold;'>Speaker 3 (Elizabeth): #</div>
				`,
        answer: [`The sea - beach | The sea / beach | The sea | beach `, `The Sahara Desert`, `A forest`],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P31-E4",
    // audio: "Audios/1.24.mp3",
    // exerciseKey: 'img/FriendsPlus/Page31/E3/Key/answerKey.png',
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 230,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
          Work in pairs. Ask and answer questions 1-4 using the key phrases.
				`,
        color: "rgb(0, 104, 180)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='margin-top: 20px; display: flex; gap: 20px;'>
            <div style="width: 550px;"><img src='img/FriendsPlus/Page31/E4/1.jpg' width='100%' /></div>

            <div style="width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgb(214, 226, 243);">
              <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(110, 159, 212); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
                Key Phrases
              </div>
              <div style="margin-top: 10px; padding: 0 20px">
                <div style="color: rgb(70, 141, 203); font-weight: bold; font-size: 1.6rem">
                  Talking about preferences
                </div>
                <div style='font-size: 20px;'>
                  <div>
                    Would you rather ... ?
                  </div>
                  <div>
                    Which would you prefer: ... or ... ?
                  </div>
                  <div>
                    I’d love to ...........
                  </div>
                  <div>
                    I’d much prefer to ...........
                  </div>
                  <div>
                    It’s my dream to ...........
                  </div>
                </div>
              </div>
            </div>  
          </div>
				`,
        answer: [`The sea | beach`, `The Sahara Desert`, `A forest`],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P31-E5",
    // exerciseKey: 'img/FriendsPlus/Page31/E3/Key/answerKey.png',
    component: T6,
    finished: {
      text: `
      Think about two places of interest in Việt Nam. Use the key phrases to talk about which one you'd prefer to see and why.
      `,
    },
    hideBtnFooter: true,
    inputSize: 230,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <finished></finished>

          <div style="margin-top: 20px; width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgb(214, 226, 243);">
            <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(110, 159, 212); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
              Key Phrases
            </div>
            <div style="margin-top: 10px; padding: 0 20px">
              <div style="color: rgb(70, 141, 203); font-weight: bold; font-size: 1.6rem">
                Talking about preferences
              </div>
              <div style='font-size: 20px;'>
                <div>
                  Would you rather ... ?
                </div>
                <div>
                  Which would you prefer: ... or ... ?
                </div>
                <div>
                  I’d love to ...........
                </div>
                <div>
                  I’d much prefer to ...........
                </div>
                <div>
                  It’s my dream to ...........
                </div>
              </div>
            </div>
          </div>  
				`,
        answer: [],
      },
    ],
  },
};

export default json;
