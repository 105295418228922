import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P96-E1",
    audio: "",
    video: "",
    component: T6v2,
    // maxLength: 11,
    inputSize: 120,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page96/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
				Read the article about the Hideaway Youth
project. Why did the project win an award?

				
									`,
        color: "#1dc1d8",
      },
    ],
    textareaStyle: { width: 800 },
    // stylesTextInput: {
    //   fontSize: 22,
    //   height: 24,
    //   background: "none",
    //   borderBottom: "none",
    //   color: "white",
    // },
    hintBox: [
      {
        src: [
          "alter",
          "crops",
          "diseases",
          "gene",
          "genetic engineering",
          "herbicides",
          "nutrients",
          "weeds",
        ],
        width: 500,
        borderColor: "#1dc1d8",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<img src="img/FriendsPlus/Page96/E1/1.jpg" />
				<div>
					<u style='font-weight:bold;font-style:italic'> Answers:</u>
					<div >
						<textarea id='0' rows='2'></textarea>
					</div>
				
			
									`,
        answer: [
          "The Hideaway Youth Project won the Youth Work Award for their success in helping young people to be the best that they can be.",
        ],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P96-E2",
    audio: "Audios/3.08.mp3",
    video: "",
    component: T6v2,
    // maxLength: 13,
    inputSize: 640,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page96/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",

        title: `
				<headphone name="3.08"></headphone >
				Read and listen to the article again
and answer the questions. 
									`,
        color: "#1dc1d8",
      },
    ],
    textareaStyle: { width: 700 },
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    questions: [
      {
        title: `
						
							<div>
								<b>1</b>&ensp;How old are the young people who are
								supported by the Hideaway Youth Project?
								 <br>#
							</div>
							<div>
								<b>2</b>&ensp;What are the aims of Hideaway? <br><textarea id='3' rows='3'></textarea>
							</div>
							<div>
								<b>3</b>&ensp;What happened in 1965? <br>#
							</div>
							<div>
								<b>4</b>&ensp;What programmes should young people
								join if they want to become healthier?
								 <br><textarea id='4' rows='2'></textarea>
							</div>
							<div>
								<b>5</b>&ensp;What can people learn at the Young Men’s
								Project and the Young Women’s Project?
								 <br><textarea id='5' rows='3'></textarea>
							</div>
							<div>
								<b>6</b>&ensp;Why was 2014 an important year for
								Hideaway? <br>#
							</div>
							<img src='img/FriendsPlus/Page96/E1/1.jpg'>

            
			
									`,
        answer: [
          "The young people are aged eleven to twenty-five.",
          "The project opened in 1965.",
          "Hideaway won the Youth Work Award in 2014.",
          "Hideaway aims to give young people in Moss Side, Manchester a way of expressing their opinions and making positive contributions to society.",
          "Powerhouse and Healthy Living programmes help young people to become healthier.",
          "Young men and women are encouraged to talk about sensitive topics openly and honestly. The sessions promote acceptance, tolerance and self-confidence.",
        ],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P96-E3",
    audio: "",
    video: "",
    // recorder: true,
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Work in pairs and
				answer the questions about your country.`,
        color: "#1dc1d8",
        prefix: { icons: ["far fa-comment"], text: "YOUR CULTURE" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page96/E3/1.jpg",
        },
      ],
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P96-E4",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Research a youth project in
				your country and write a fact file about it.
				Find out:`,
        color: "#1dc1d8",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page96/E4/1.jpg",
        },
      ],
    ],
  },
  5: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P96-E5",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 740 },
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Create a poster
        to show what happens in the water cycle.<br>
        Include information from this page.`,
        color: "#1dc1d8",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
          
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
