import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P36-E1",
    exerciseKey: "img/FriendsPlus/Page36/E1/Key/answerKey.png",
    audio: "Audios/1.29.mp3",
    // video: 'https://cdn.sachso.edu.vn/videos/8sb/Unit 3.mp4',
    component: T6v2,

    hintBox: [
      {
        src: [
          `adventure holiday`,
          `away`,
          `every day`,
          `hundreds`,
          `kayaking`,
          `photo`,
        ],
        borderColor: "rgb(220, 30, 116)",
        width: 800,

      },
    ],
    inputSize: 232,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
					<headphone name='1.29'></headphone > 
					Complete the dialogue with the 
					phrases in the box. Then watch or listen and 
					check. Where has Daisy just been? What has 
					Neil got all week?
				`,
        color: "rgb(220, 30, 116)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintBox id='0'></hintBox>

					<div style='margin-top: 20px; width: 880px; display: flex; flex-direction: column; gap: 10px; background: rgb(212, 228, 233); padding: 20px;'>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Daisy</div>
							<div>
								Oh, hey, Neil!
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Neil</div>
							<div>
								Oh, hi, Daisy. I haven’t seen you for ages.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Daisy</div>
							<div>
								I know. I’ve been <sup>1</sup> #.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Neil</div>
							<div>
								Really? What have you been up to?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Daisy</div>
							<div>
								I’ve just got back from an <sup>2</sup> # in Zambia.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Neil</div>
							<div>
								Wow! Was it good?	
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Daisy</div>
							<div>
								It was amazing! I’ve done a lot over the last few weeks. 
								I went <sup>3</sup> # on the Zambezi River and I saw the Victoria Falls.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Neil</div>
							<div>
								That sounds great. Did you take many <sup>4</sup> #?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Daisy</div>
							<div>
									Yes, I’ve already posted <sup>5</sup> #! 
									What about you? Have you been doing anything special?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Neil</div>
							<div>
								Not really. I’ve been studying <sup>6</sup> #. We’ve got exams all week.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Daisy</div>
							<div>
								Oh. Good luck with that.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 60px;'>Neil</div>
							<div>
								Thanks. Bye, Daisy. It was good to see you again.
							</div>	
						</div>
					</div>

					<div style='margin-top: 20px;'>
						<div><strong>Where has Daisy just been?</strong><br /><input id='6' width='700px' text-align='start' /></div>
						<div><strong>What has Neil got all week?</strong><br /><input id='7' width='700px' text-align='start' /></div>
						<div></div>
					</div>
        `,
        answer: [
          `away`,
          `adventure holiday`,
          `kayaking`,
          `photos`,
          `hundreds`,
          `every day`,
          `Daisy has just been on an adventure holiday in Zambia.`,
          `Neil has got exams all week.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P36-E2",
    exerciseKey: "img/FriendsPlus/Page36/E2/Key/answerKey.png",
    audio: "Audios/1.29.mp3",
    video: 'https://cdn.sachso.edu.vn/videos/8sb/Unit 3.mp4',
    component: T6v2,
    inputSize: 50,
    maxLength: 1,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.29'></headphone > 
					Study the Key Phrases. Cover the 
					dialogue and try to remember who says the 
					phrases, Daisy (D) or Neil (N). Watch or listen 
					again and check.
				`,
        color: "rgb(220, 30, 116)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='position: relative;'>
						<div><img src='img/FriendsPlus/Page36/E2/1.jpg' width='50%' /></div>
						<div style='position: absolute; top: 80px; left: 432px;'>#</div>
						<div style='position: absolute; top: 108px; left: 432px;'>#</div>
						<div style='position: absolute; top: 136px; left: 432px;'>#</div>
						<div style='position: absolute; top: 164px; left: 432px;'>#</div>
						<div style='position: absolute; top: 192px; left: 432px;'>#</div>
						<div style='position: absolute; top: 220px; left: 432px;'>#</div>
						<div></div>
					</div>
        `,
        answer: [`N`, `N`, `D`, `N`, `D`, `N`],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P36-E3",
    // exerciseKey: 'img/FriendsPlus/Page36/E1/Key/answerKey.png',
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 232,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					Work in pairs. Practise the dialogue.
				`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page36/E3/1.jpg' width='100%' /></div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P36-E4",
    exerciseKey: "img/FriendsPlus/Page36/E4/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "4",
        title: `
					Work in pairs. Match the phrases. Then 
					ask and answer the two questions with your 
					partner.
				`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    question: {
      DrawLines: {
        boxMatch: [
          // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "14px",
              left: "250px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "250px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "250px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "250px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "172px",
              left: "250px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },

          // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "14px",
              left: "400px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "400px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "400px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "172px",
              left: "400px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
        ],
        answers: ["0-6", "1-8", "2-9", "3-5", "4-7"],
        initialValue: [],
      },
      Layout: `
			<div style='display: flex; gap: 230px;'>
				<div style='display: flex; gap: 10px; flex-direction: column; font-size: 20px;'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>1</span>
						<div>What have you</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>2</span>
						<div>I haven’t seen you</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>3</span>
						<div>I’ve been studying</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>4</span>
						<div>Have you been doing</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>5</span>
						<div>It was good</div>
					</div>
				</div>
	
				<div style='display: flex; gap: 10px; flex-direction: column; font-size: 20px;'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>a</span>
						<div>anything special?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>b</span>
						<div>been up to?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>c</span>
						<div>to see you again.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>d</span>
						<div>for ages.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>e</span>
						<div>all week.</div>
					</div>
				</div>
			</div>
	
	
				<div></div>
	
				<input id='0' type= 'boxMatch' />
				<input id='1' type= 'boxMatch' />
				<input id='2' type= 'boxMatch' />
				<input id='3' type= 'boxMatch' />
				<input id='4' type= 'boxMatch' />
				<input id='5' type= 'boxMatch' />
				<input id='6' type= 'boxMatch' />
				<input id='7' type= 'boxMatch' />
				<input id='8' type= 'boxMatch' />
				<input id='9' type= 'boxMatch' />
		`,
    },
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P36-E5",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					Work in pairs. Read the situation. 
					Prepare and practise a new dialogue. Use the 
					key phrases and the dialogue in exercise 1 to 
					help you.
				`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; align-items: start;'>
						<div style='order: 2; flex: 1;'><img src='img/FriendsPlus/Page36/E5/1.jpg' width='100%' /></div>

						<div style='order: 1; flex: 1; display: flex; gap: 10px; flex-direction: column;'>
							<div style='width: 520px; background-color: rgba(246, 211, 219); padding: 15px; border-radius: 20px; display: flex; gap: 20px; flex-direction: column;'>
								<div style='border: 2px solid rgb(220, 30, 116); padding: 15px;'>
									<strong>Student A:</strong> 
									You want to go camping in the 
									countryside at the weekend. Listen to what your 
									friend suggests instead and respond.
								</div>
								<div style='border: 2px solid rgb(220, 30, 116); padding: 15px;'>
									<strong>Student B:</strong> 
									You don’t want to go camping in 
									the countryside at the weekend. You fancy 
									watching a film then having dinner. Suggest 
									what type of film and food you want.
								</div>
							</div>

							<div><img src='img/FriendsPlus/Page36/E2/1.jpg' width='100%' /></div>
						</div>
					</div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
