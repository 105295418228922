import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/E1/Key/answerKey.png",
    component: T6v2,
    hintBox: [
      {
        src: ["ever", "have", `haven't`, "has", `hasn't`],
        borderColor: "rgb(28, 192, 217)",
        width: 470,
      },
    ],
    inputSize: 100,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center	",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete 1-5 with the words in the box. How do you say <i>ever</i> in Vietnamese?
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintBox id='0'></hintBox>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>Have you # ridden a horse?</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>No, I # .</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div># you guys been to a theme park?</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div># your brother been on a rollercoaster?</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>No, he # .</div>
						</div>
					</div>
        `,
        answer: ["ever", `haven't`, "Have", "Has", `hasn't`],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page23/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
					Read the sentences in exercise 1 again. Then choose the correct words to complete the rules.
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [`a <br> b`],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: `
				<div><img src='img/FriendsPlus/Page23/E2/1.jpg' width='45%' /></div>

			  <div style="margin-top: 20px; width: 600px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(92, 201, 222);
			    font-size: 20px;">
			    <div>
			      <div style="color: rgb(255, 255, 255); font-weight: bold; text-transform: uppercase; margin-right: 10px;
			        background-color: rgb(92, 201, 222); padding: 5px 15px; border-radius: 15px;">
			        <span>RULES</span>
			      </div>
			    </div>
			    
					<div>We use questions in the present perfect to ask ...</div>
					<div style='display: flex; gap: 15px;'>
						<div><input id='0' type='Circle' /></div>
						<div>
							<div>if a person experienced something at some time.</div>
							<div>an exact time when a person experienced something.</div>
						</div>
					</div>
			  </div>
			  `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/E3/Key/answerKey.png",
    component: T6v2,
    inputSize: 600,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Order the words to make questions. Then ask and answer the questions with a partner.
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>a frightening film / you / have / lately / seen / ?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>this year / has / improved / your English / ?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>cooked a meal / ever / for you / your friends / have / ?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>what / you / today / eaten / have / ?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>in the last five years / countries / have / visited / you / what / ?</div>
							</div>
							<div>#</div>
						</div>
					</div>
        `,
        answer: [
          `Have you seen a frightening film lately?`,
          `Has your English improved this year?`,
          `Have your friends ever cooked a meal for you?`,
          `What have you eaten today?`,
          `What countries have you visited in the last five years?`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/E4/Key/answerKey.png",
    component: T6v2,
    inputSize: 380,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					Write questions for the answers using the present perfect and the words in brackets.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>Yes, I've cooked spaghetti. (ever)</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>I've bought a tablet. (What)</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>They've moved to Los Angeles. (Where)</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>He's invited six people. (How many)</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>Yes, it finished at two o'clock. (meeting)</div>
							</div>
							<div>#</div>
						</div>
					</div>
        `,
        answer: [
          `Have you ever cooked spaghetti?`,
          `What have you bought?`,
          `Where have they moved to?`,
          `How many people has he invited?`,
          `Has the meeting finished?`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/E5/Key/answerKey.png",
    component: T6v2,
    inputSize: 140,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				Study examples a-d. Then answer questions 1-3.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>a</span>
							<div>I’ve eaten a few interesting things since we came to Asia.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>b</span>
							<div>ate some last year at a market.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>c</span>
							<div>I’ve ridden an alpaca.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>d</span>
							<div>I rode the alpaca when I was six years old.</div>
						</div>
					</div>

					<div style='margin-top: 20px; display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>Which verbs are in the present perfect and which are in the past simple?</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Present perfect:</span> #, #, #</div>
							<div><span style='font-weight: bold; font-style: italic;'>Past simple:</span> #, #, #</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>Which sentences describe a finished action at an exact time in the past?</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>Which sentences describe an action at some time in the past?</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #</div>
						</div>
					</div>
        `,
        answer: [
          `I've eaten`,
          `we've lived,`,
          `I've ridden`,
          `I ate`,
          `I rode`,
          `I was`,
          `b and d`,
          `a and c`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E6",
    audio: "Audios/1.18.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page23/E6/Key/answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: `
					<headphone name='1.18'></headphone > 
					Read the dialogue and choose the correct words. Then listen and check.
				`,
        color: "rgb(27, 191, 215)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          borderRadius: "50%",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `Have_you_been_/ Did_you_go`,
          `have_been / went`,
          `Did_you_enjoy / Have_you_enjoyed`,
          `Did_you_try / Have_you_tried`,
          `I've_never_been / I_didn't_go`,
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `
			<div style='display: flex; flex-direction: column; gap: 10px;'>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Zoe</div>
					<div>
						<sup>1</sup> <input id='0' type='Circle' /> anywhere exciting recently?
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Sam</div>
					<div>
						No, not recently. We <sup>2</sup> <input id='1' type='Circle' /> to a water park last month though.
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Zoe</div>
					<div>
						Really? <sup>3</sup> <input id='2' type='Circle' /> it?
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Sam</div>
					<div>
					Yes, it was awesome. What’s the most amazing ride that you’ve ever tried?
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Zoe</div>
					<div>
						It was the roller coaster at Sun World Bà Nà Hill. <sup>4</sup> <input id='3' type='Circle' /> it?
					
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Sam</div>
					<div>
						No, <sup>5</sup> <input id='4' type='Circle' /> go there.
					</div>	
				</div>
			</div>	
			`,
    },
  },
  7: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E7",
    audio: "",
    video: "",
    component: T6v2,
    hintBox: [
      {
        src: ["Have you been anywhere interesting recently?"],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 1,
        width: 550,
      },
      {
        src: ["Yes, I went to Cần Thơ at the weekend."],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 2,
        width: 490,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
					Make a dialogue with a partner on either idea A or B. Use the present perfect and past simple forms.
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>A</span>
							<div>be / anywhere interesting</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>B</span>
							<div>see / any good films</div>
						</div>
					</div>

					<div style='margin-top: 20px;'><hintbox id='0'></hintbox></div>
					<div style='position: relative;'>

          <div style=" position: absolute; top: -60px; left: 557px; ">
              <hintbox id='1'></hintbox>
          </div>
          
</div> 
				`,
        answer: [],
      },
    ],
  },
};

export default json;
