import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  8: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P68-E8",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/key/E8answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: 'center',
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "8",

        title: `
						
        Complete the words.
												`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">1</span>
          
				<span>You must <span style="font-weight: bold;">o</span>#the rules at school!</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">2</span>
				<span>Do you have to wear a school <span style="font-weight: bold;">u#</span>?</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">3</span>
         
				<span>She got good <span style="font-weight: bold;">m#</span>in her exams</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">4</span>
         
				<span>I left <span style="font-weight: bold;">p#</span>school when I was
        eleven.</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">5</span>
        
				<span>You need to study or you’ll <span style="font-weight: bold;">f#</span>the exam tomorrow!</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">6</span>
				<span>I want to <span style="font-weight: bold;">g#</span>into fashion college.</span>
			</p>	

			
					
							
												`,
        answer: ["bey", "niform", "arks", "rimary", "ail", "et"],
      },
    ],
  },
  9: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P68-E9",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page68/key/E9answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "9",
        title: `
        Choose the best words to complete the sentences.
					`,
        color: "rgb(121,106,175)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          "a_unusual  b_challenging  c_creative",
          "a_historic b_ordinary c_creative",
          "a_ordinary b_historic c_unusual",
          "a_unusual b_challenging c_ordinary",
          "a_creative b_ordinary c_historic",
        ],
        answers: ["0-8", "1-0", "2-4", "3-2", "4-2"],
        initialValue: {},
      },
      Layout: `
      <div style="display: flex; flex-wrap: wrap;flex-direction: column;">

        <div style="flex:1">
          <p style="font-size: 25px">
            <span style="font-weight: bold; margin-right: 10px">1</span>
            <span>My sister is very.............. . She makes amazing jewellery.</span>
          </p>
          <div style="display: flex; flex-wrap: wrap;justify-content:space-around;margin-bottom:20px">
            <input id='0' type='Circle' style="letter-spacing: 10px;"/>
          </div>
        </div>

        <div style="flex:1">
          <p style="font-size: 25px">
            <span style="font-weight: bold; margin-right: 10px">2</span>
            <span>Yesterday we visited a / an..............
            town in Turkey. I love looking at old
            buildings.</span>
          </p>
          <div style="display: flex; flex-wrap: wrap;justify-content:space-around;margin-bottom:20px">
          <input id='1' type='Circle' style="letter-spacing: 10px;"/>
          </div>
        </div>

        <div style="flex:1">
          <p style="font-size: 25px">
            <span style="font-weight: bold; margin-right: 10px">3</span>
            <span>This classroom is rather.............. .
            There are no desks or chairs in it. </span>
          </p>
          <div style="display: flex; flex-wrap: wrap;justify-content:space-around;margin-bottom:20px">
            <input id='2' type='Circle' style="letter-spacing: 10px;"/>
          </div>
        </div>

        <div style="flex:1">
          <p style="font-size: 25px">
            <span style="font-weight: bold; margin-right: 10px">4</span>
            <span>Travelling to a foreign country can be..............
            when you don’t know the
           language</span>
          </p>
          <div style="display: flex; flex-wrap: wrap;justify-content:space-around;margin-bottom:20px">
            <input id='3' type='Circle' style="letter-spacing: 10px;"/>
          </div>
        </div>

        <div style="flex:1">
          <p style="font-size: 25px">
            <span style="font-weight: bold; margin-right: 10px">5</span>
            <span>This is just another.............. day.
            Nothing interesting has happened. </span>
          </p>
          <div style="display: flex; flex-wrap: wrap;justify-content:space-around;margin-bottom:20px">
            <input id='4' type='Circle' style="letter-spacing: 10px;"/>
          </div>
        </div>
        
    </div>


				`,
    },
  },
  10: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P68-E10",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/key/E10answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 550,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: 'center',
      fontSize: "25px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "10",

        title: `
						
        Order the words to make sentences.
												`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
        
        <b>1</b>&ensp;your / can / speak / German / father / ?<br>#<br>
        <b>2</b>&ensp;be able / go / by train / they / to / will / ?<br>#<br>
        <b>3</b>&ensp;get / David / for / couldn’t / the concert / tickets<br>#<br>
        <b>4</b>&ensp;exam / I / with / your / you / help / can’t <br>#<br>
        <b>5</b>&ensp;swim / you / you / when / were / could / four / ?<br>#<br>
        <b>6</b>&ensp;won't / come / tomorrow / be / Vania / able to	<br>#<br>
      </div>
												`,
        answer: [
          "Can your father speak German?",
          "Will they be able to go by train?",
          "David couldn't get tickets for the concert.",
          "I can't help you with your exam.",
          "Could you swim when you were four?",
          "Vania won't be able to come tomorrow.",
        ],
      },
    ],
  },
  11: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P68-E11",
    audio: "Audios/2.19.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/key/E11answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 110,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "11",

        title: `
        <headphone name="2.19"></headphone >
        Listen to Joshua talking to a careers
        advisor. Write <i>true</i> or <i>false</i>.
												`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">1</span>
				<span>Joshua’s dad works in a construction company #</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">2</span>
				<span>Joshua’s sister studied nursing #</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">3</span>
				<span>Joshua wants to apply for a job in a law
        company #</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">4</span>
				<span>Joshua doesn’t want a career in
        entertainment #</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">5</span>
				<span>The advisor tells Joshua he could try
        journalism #</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">6</span>
				<span>Joshua is interested in writing. #</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">7</span>
				<span>Joshua’s uncle works in a school. #</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">8</span>
				<span>Joshua has applied for an IT course #</span>
			</p>	

			
	
												`,
        answer: [
          "true",
          "false",
          "false",
          "true",
          "true",
          "false",
          "true",
          "false",
        ],
      },
    ],
  },
};

export default json;
