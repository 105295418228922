import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  3: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P59-E3",
    audio: "Audios/2.11.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		<headphone name="2.11"></headphone >
		Watch or listen to three people talking about school and answer the questions.

								`,
        color: "rgb(7,114,186)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
        <p style="font-size: 25px; margin-top: 30px; ">
          <span style="font-weight: bold; margin-right: 10px;">1</span>
          <span>What type of school does Emma prefer?</span>
          <p>#</p>
        </p>

        <p style="font-size: 25px; margin-top: 30px; ">
          <span style="font-weight: bold; margin-right: 10px;">2</span>
          <span>What does Max say is more important than getting good results?</span>
          <p>#</p>
        </p>

        <p style="font-size: 25px; margin-top: 30px; ">
          <span style="font-weight: bold; margin-right: 10px;">3</span>
          <span>What is Elizabeth’s opinion of homeschooling? </span>
          <p>#</p>
        </p>
        
			
								`,
        answer: [
          "single-sex schools",
          "Helping students become good people.",
          "She thinks it's good because you don't get distracted by noisy students.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U6-P59-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in pairs. Give your opinions on these statements using the key phrases.
        `,
        color: "rgb(7,114,186)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		      <div style="margin-top:40px;margin-left:200px"><img src="img/FriendsPlus/Page59/7.jpg"/></div>
		      <div style="margin-top:10px; margin-left:200px"><img src="img/FriendsPlus/Page59/8.jpg"/></div>

        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U6-P59-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: "Use the key phrases to tell a partner about your ideal school.",
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
