import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P58-E1",
    audio: "Audios/2.09.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
    <headphone name="2.09" src='Audios/2.09.mp3'></headphone >
    Use the word school and the words in the
    box to make compound nouns. Listen and check.
    Which types of school can you see in the photos?
                `,
        color: "rgb(7,114,186)",
      },
    ],
    inputSize: 220,
    component: T6v2,
    // recorder: true,
    textAlign: "center",
    hintBox: [
      {
        src: [
          "boarding",
          "holidays",
          "home",
          "leavers",
          "mixed",
          "primary",
          "rules",
          "secondary",
          "single-sex",
          "uniform",
        ],
        borderColor: "#1dbfd7",
        width: 500,
      },
    ],
    questions: [
      {
        title: `
      
      <div style='position: relative;margin:100px 40px'>
        <img src="img/FriendsPlus/Page58/E1/1.jpg" />
        <span style='position: absolute; top: -100px; left: 0px'><hintbox id='0'></hintbox></span>
        <div>
          <u style='font-style:italic; font-weight:bold'>Answers:</u> 
          #,#,# <br>#,#
        </div>
      </div>
      
        


			
						
												`,
        answer: [
          "mixed school",
          "single-sex school",
          "primary school",
          "boarding school",
          "homeschooling",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P58-E2",
    audio: "Audios/2.10-audio.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E2answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 180,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        <headphone name="2.10" src='Audios/2.10-tieude.mp3'></headphone >
		Check the meaning of the phrases in the box and complete the questionnaire. Listen and
		check. Then ask and answer the questions with a partner.
								`,
        color: "rgb(7,114,186)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
		<div style="border: 2px solid rgb(7,114,186); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">do homework</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">enrol in</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">fail exams</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get a qualification</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get good marks</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">get into college</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">go to lessons</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">obey rules</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">pass exams</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">take classes</p>
	  </div>
	    <h1 style="margin-top: 30px;">Answers</h1>

		<div style="display: flex; flex-wrap: wrap; gap: 10px">

			<div>
				<span style="font-size: 25px;">1</span>
				<span style="font-size: 25px">#</span>
			</div>

			<div>
				<span style="font-size: 25px;">2</span>
				<span style="font-size: 25px">#</span>
			</div>

			<div>
				<span style="font-size: 25px;">3</span>
				<span style="font-size: 25px">#</span>
			</div>
			<div>
				<span style="font-size: 25px;">4</span>
				<span style="font-size: 25px">#</span>
			</div>
			<div>
				<span style="font-size: 25px;">5</span>
				<span style="font-size: 25px">#</span>
			</div>
			<div>
				<span style="font-size: 25px;">6</span>
				<span style="font-size: 25px">#</span>
			</div>

			<div>
				<span style="font-size: 25px;">7</span>
				<span style="font-size: 25px">#</span>
			</div>

			<div>
				<span style="font-size: 25px;">8</span>
				<span style="font-size: 25px">#</span>
			</div>

			<div>
				<span style="font-size: 25px;">9</span>
				<span style="font-size: 25px">#</span>
			</div>

			<div>
				<span style="font-size: 25px;">10</span>
				<span style="font-size: 25px">#</span>
			</div>


		</div>
	 
		
	
			
								`,
        answer: [
          "enrol",
          "classes",
          "lessons",
          "homework",
          "obey",
          "pass",
          "a qualification",
          "fail",
          "good marks",
          "into college",
        ],
      },
    ],
  },
};

export default json;
