import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P52-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/key/E1answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
			Read the article on the future of human beings. Match paragraphs 1-5 with pictures A-E.
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						
		<div><img src="img/FriendsPlus/Page52/6.jpg" width="1000px" /></div>
		<h2 style="margin-top: 40px">ANSWERS</h2>

    	<div style="display: flex;flex-wrap: wrap;gap:20px">
		<p style="font-size: 25px;">1#</p>
		<p style="font-size: 25px;">2#</p>
		<p style="font-size: 25px;">3#</p>
		<p style="font-size: 25px;">4#</p>
		<p style="font-size: 25px;">5#</p>
		</div>

			
				
									`,
        answer: ["E", "C", "B", "A", "D"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P52-E2",
    audio: "Audios/2.04.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 1050,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		<headphone name="2.04"></headphone >
		Read and listen to the text again and answer the questions.
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						
		<div><img src="img/FriendsPlus/Page52/6.jpg" width="1000px" style ="margin-bottom:40px;margin-top:20px" /></div>

		<div>
			<div style="display: flex; flex-wrap: wrap; gap: 10px">
				<span style="font-size: 25px; font-weight: bold">1</span>
				<span style="font-size: 25px">Who might live to be 150 years old?</span>
			</div>
			<p style="font-size: 25px">#</p>
		</div>

		<div>
			<div style="display: flex; flex-wrap: wrap; gap: 10px">
				<span style="font-size: 25px; font-weight: bold">2</span>
				<span style="font-size: 25px">What will probably cause our brains to get
				bigger?</span>
			</div>
			<p style="font-size: 25px">#</p>
		</div>

		<div>
			<div style="display: flex; flex-wrap: wrap; gap: 10px">
				<span style="font-size: 25px; font-weight: bold">3</span>
				<span style="font-size: 25px">What will people possibly do as the result
				of an epidemic or war?</span>
			</div>
			<p style="font-size: 25px">#</p>
		</div>

		<div>
			<div style="display: flex; flex-wrap: wrap; gap: 10px">
				<span style="font-size: 25px; font-weight: bold">4</span>
				<span style="font-size: 25px">What are the possible ways the children of
				the future will be different from now?</span>
			</div>
			<p style="font-size: 25px">#</p>
		</div>

		<div>
			<div style="display: flex; flex-wrap: wrap; gap: 10px">
				<span style="font-size: 25px; font-weight: bold">5</span>
				<span style="font-size: 25px">What might happen if the Earth gets
				overcrowded?</span>
			</div>
			<p style="font-size: 25px">#</p>
		</div>
    	

			
				
									`,
        answer: [
          "Children born now might live to be 150 years old.",
          "Using computers and screens more and more will probably cause our brains to get bigger.",
          "People will possibly leave cities and live in caves as the result of an epidemic or war.",
          "Children of the future might be better looking, healthier and more intelligent.",
          "If the Earth gets overcrowded, humans will move to other planets.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P52-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 1050,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		<span style="color: rgb(220,44,55);">VOCABULARY PLUS</span>
		Use a dictionary to check the meaning of the words in <span style="color: rgb(126,214,246);">blue</span>
		in the text.
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						
		<div><img src="img/FriendsPlus/Page52/6.jpg" width="1000px" style ="margin-bottom:40px;margin-top:20px" /></div>
									`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U5-P52-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in pairs. Which of the ideas in the article do you think will happen
		in the future? Compare your ideas.
        `,
        color: "rgb(220,44,55)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page52/6.jpg" style="margin-bottom: 40px;" width="1000px" /></div>
        <div><img src="img/FriendsPlus/Page52/7.jpg" style="margin-left: 200px;margin-bottom: 40px;" /></div>

        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
