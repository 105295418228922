import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P66-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 130,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
						
			Complete the sentences.
												`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">1</span>
				<span>Dominic is getting # . He’ll be sixty on his next birthday </span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">2</span>
				<span>I first got a bank # when I was
				twelve years old.
				</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">3</span>
				<span>You won’t get a driving # unless
				you pass your test</span>
				
				
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">4</span>
				<span>My grandma doesn’t work any more. She
				gets a # now.
				</span>
			</p>	

			<p style="font-size: 25px; ">
				<span style="font-weight: bold; margin-right: 10px;">5</span>
				<span>Mike studied at university for five years to
				get a # in medicine.
				</span>
			</p>	
					
							
												`,
        answer: ["old", "account", "licence", "pension", "degree"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P66-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
					
		Match the words with the definitions.
											`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
				<div class="container">
			
					<div style="border: 2px solid rgb(121,106,175); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">adapt</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">destroy</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">manipulate</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">process</p>
					</div>

					<p style="font-size: 25px;margin-top:30px ">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<span> damage something so that it can’t be used # </span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<span>control something so that it behaves as we
						want it to #</span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<span>use and analyse information with the help
						of a computer #</span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<span>slowly change something so that it works
						better in a new situation #</span>
					</p>
					
				</div>
						
											`,
        answer: ["destroy", "manipulate", "process", "adapt"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P66-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
		Complete the sentences with if, unless or the correct form of might or will.
											`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
				<div class="container">

					<p style="font-size: 25px;margin-top:30px ">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<span>We # spend some time in
						Portugal next summer, but we aren’t sure.</span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<span>You’ll be exhausted # you relax
						this weekend.</span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<span>I # see you later, but I hope I do.</span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<span>He # get rich because he works
						extremely hard.</span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">5</span>
						<span>We # win the match. The other
						team is better.</span>
					</p>

					<p style="font-size: 25px; ">
						<span style="font-weight: bold; margin-right: 10px;">6</span>
						<span>Will you go to the party # Leo
						invites you?</span>
					</p>
					
				</div>
						
											`,
        answer: ["might", "unless", "might not", "'ll", "won't", "if"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U5-P66-E4",
    audio: "Audios/2.18.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page66/key/E4answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
		<headphone name="2.18"></headphone >
         Listen to Jasmine and Liam talking about their summer holiday. Choose the
		correct words.

					`,
        color: "rgb(121,106,175)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `Liam / Jasmine`, // 0
          `abroad / in_England`, // 1
          `months / weeks`, // 2
          `July / August`, // 3
          `will / won't `, // 4
          `take_up_a_sport / watch_TV`, // 5
        ],
        answers: ["0-4", "1-0", "2-4", "3-4", "4-0", "5-0"],
        initialValue: {},
      },
      Layout: `
      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;">1 </span>
		
        <input id='0' type='Circle' style="letter-spacing: 10px;"/> is calling his / her friend
		  </div>


		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">2 </span>
			Jasmine is going to spend the summer
			<input id='1' type='Circle' style="letter-spacing: 10px;"/>.
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">3 </span>
			Jasmine will be travelling around South
			America for a few <input id='2' type='Circle' style="letter-spacing: 10px;"/>. 
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">4 </span>
			Liam’s dad wants to take some time out in
			<input id='3' type='Circle' style="letter-spacing: 10px;"/>.  
      
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">5 </span>
			Liam’s dad <input id='4' type='Circle' style="letter-spacing: 10px;"/>earn a lot of money soon.
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">6 </span>
			Jasmine says Liam should 
      		<input id='5' type='Circle' style="letter-spacing: 10px;"/>.
			
		</div>

		

				`,
    },
  },
};

export default json;
