import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P7-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E1/Key/answerKey.png",
    component: T6v2,
    checkDuplicated: true,
    inputSize: 50,
    maxLength: 1,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center	",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Study sentences 1-5 from the interviews on page 6. Then answer questions a-d.`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display: flex; gap: 10px; flex-direction: column;'>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>1</span>
              <div>What are you doing in town this morning?</div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>2</span>
              <div>I’m looking for a present for my mum.</div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>3</span>
              <div>I start school at nine.</div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>4</span>
              <div>I have some cereal or toast.</div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>5</span>
              <div>Do you always go shopping on Saturday mornings?</div>
            </div>
          </div>

					<div style='margin-top: 20px; display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>a</span>
								<div>Which sentence refers to a scheduled action?</div>
							</div>
							<div><span style='font-style: italic; font-weight: bold;'>Sentence(s)</span>: #</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>b</span>
								<div>Which sentences refer to actions happening now?</div>
							</div>
							<div><span style='font-style: italic; font-weight: bold;'>Sentence(s)</span>: #, #</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>c</span>
								<div>Which sentence refers to a repeated action?</div>
							</div>
							<div><span style='font-style: italic; font-weight: bold;'>Sentence(s)</span>: #</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>d</span>
								<div>Which sentence contains a verb that we can’t usually use in the present continuous?</div>
							</div>
							<div><span style='font-style: italic; font-weight: bold;'>Sentence(s)</span>: <input id="4" width="130px"/></div>
						</div>
					</div>
        `,
        answer: ["3", "1", "2", "5", '4 - "have"'],
      },
    ],
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P7-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E2/Key/answerKey.png",
    component: T6,
    checkDuplicated: true,
    inputSize: 600,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center	",
    },
    titleQuestion: [
      {
        num: "2",
        title: `Write the negative forms of sentences 1-5 in exercise 1.`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display: flex; gap: 10px; flex-direction: column;'>
            <div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>What are you doing in town this morning?</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #</div>
						</div>
            <div>
								<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>I’m looking for a present for my mum.</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #</div>
						</div>
            <div>
								<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>I start school at nine.</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #</div>
						</div>
            <div>
								<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>I have some cereal or toast.</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #</div>
						</div>
            <div>
								<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>Do you always go shopping on Saturday mornings?</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #</div>
						</div>
          </div>
        `,
        answer: [
          `What aren't you doing in town this morning? / What are you not doing in town this morning?`,
          `I'm not looking for a present for my mum.`,
          `I don't start school at nine.`,
          `I don't have any cereal or toast.`,
          `Don't you always go shopping on Saturday mornings?`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P7-E7",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E3/Key/answerKey.png",
    component: T6,
    inputSize: 300,
    stylesTextInput: {
      fontSize: "20px",
      textAlign:"center"
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					Read the interviews on page 6 again. Find examples of:
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative;margin-left:-100px;font-size:20px'>
          <img src='img/FriendsPlus/Page6/E2/1.jpg' style='height: 22cm' />

          <div style=" position: absolute; top: 60px; left: 362px;width:85mm;border-right:5px solid gray">
            <b>1</b>&ensp;negative forms of the present simple and present continuous.<br>#<br>#
          </div>

          <div style=" position: absolute; top: 60px; left: 700px;width:10cm ">
            <b>2</b>&ensp;question forms of the present simple and present continuous.<br>
            #<br>#<br>
            #<br>#<br>
            #<br>#<br>
            #<br>#<br>
            #<br>#<br>
            #<br>#<br>#<br>
          </div>
          <div style='position: absolute;top: 614px; left: 362px;width:20cm '>
            <b>3</b>&ensp;third person singular forms of the present simple.
          </div>
            <div style='position: absolute;top:645px; left: 345px;width:20cm'>
              #,#<br>#,#<br>#,#
            </div>
        </div> 
					
        `,
        answer: [
          `I'm not playing / I am not playing / I don't tidy / I don't (often) tidy / I do not tidy / I do not often tidy`,//
          `I'm not playing / I am not playing / I don't tidy / I don't (often) tidy / I do not tidy / I do not often tidy`,//
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `Have you got / What are you doing / Do you go shopping / What time do you get up / Do you have breakfast / What time do you start school / Do you have a minute / Do you go home / How do you relax / Do you listen to music / Do you help / Does someone tidy / What time do you go to bed`,
          `It depends / My mum usually wakes me / My mum wakes me / she goes to work / Does someone tidy your room / my mum does it / my dad always cooks / my dad cooks`,
          `It depends / My mum usually wakes me / My mum wakes me / she goes to work / Does someone tidy your room / my mum does it / my dad always cooks / my dad cooks`,
          `It depends / My mum usually wakes me / My mum wakes me / she goes to work / Does someone tidy your room / my mum does it / my dad always cooks / my dad cooks`,
          `It depends / My mum usually wakes me / My mum wakes me / she goes to work / Does someone tidy your room / my mum does it / my dad always cooks / my dad cooks`,
          `It depends / My mum usually wakes me / My mum wakes me / she goes to work / Does someone tidy your room / my mum does it / my dad always cooks / my dad cooks`,
          `It depends / My mum usually wakes me / My mum wakes me / she goes to work / Does someone tidy your room / my mum does it / my dad always cooks / my dad cooks`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P7-E4",
    audio: "Audios/1.03.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
					<headphone name="1.03" src='Audios/1.03.mp3'></headphone> 
					<span style="color: rgb(28, 192, 217)">PRONUNCIATION: Consonant clusters</span> 
				`,
        color: "rgb(28, 192, 217)",
        prefix:{ icons:["far fa-comment"] , text:"" }  
      },
    ],
    component: Circle_Write,

   
    question: {
      Write: {
        inputStyle: { width: 150 },
        answers: [],
        initialValue: [],
      },
      Circle: {
         initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: "solid 2px",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
       "br|ea|k",//1
   	   "e|xtr|a",//2
    	 "gi|ft",//3
	     "co|ld",//4
    	 "sch|ol|ar",//5
    	 "hi|st|or|y",//6
     	 "te|xt",//7
    	 "di|sc|ov|er",//8
    	 "pl|an|et",//9
        ],
        answers: ['0-0', '1-2', '2-2', '3-2', '4-0', '5-2', '6-2', '7-2', '8-0'],
        initialValue: [],
      },
      Layout: `
       <div style='width:17cm; margin-bottom:40px'>
          A consonant cluster is a group of consonants 
          without any vowels between them. A 
          consonant cluster can be at the beginning 
          (school /skuːl/), in the middle (describe 
          /dɪsˈkraib/), or at the end of a word (fact /fækt/).<br>
          <b>
            Underline consonant clusters in the words 
            below and pronounce them. Listen and check
          </b>
        </div>
            <input id='0'  type='Circle' />&emsp;&emsp;<input id='1'  type='Circle' />&emsp;&emsp;<input id='2'  type='Circle' />&emsp;&emsp;<input id='3'  type='Circle' />&emsp;&emsp;<input id='4'  type='Circle' /><br>
            <input id='5'  type='Circle' />&emsp;&emsp;<input id='6'  type='Circle' />&emsp;&emsp;<input id='7'  type='Circle' />&emsp;&emsp;<input id='8'  type='Circle' />

      `,
    },
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P7-E5",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E5/Key/answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "transparent",
      borderBottom: "1px dotted #ccc",
      fontSize: "24px",
      textAlign: "center",
    },
    inputSize: 230,
    titleQuestion: [
      {
        num: "5",
        title: `
				Complete the phone conversation on the right using the correct present simple and present continuous forms of the verbs.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Erica</div>
							<div>
								Hi, Claire. Where are you?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Claire</div>
							<div>
								<sup>1</sup> # (I / sit) on the bus. I’m nearly in town. <br />
								<sup>2</sup> # (you / want) to meet at the shopping centre?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Erica</div>
							<div>
								I can’t. <sup>3</sup> # (I / do) my history homework at the moment.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Claire</div>
							<div>
								Why <sup>4</sup> # (you / do) your homework now? It’s Saturday morning!
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Erica</div>
							<div>
								I know! <sup>5</sup> # (I / not / usually / do) homework on Saturdays, but I’ve got a netball tournament tomorrow. <br />
								<sup>6</sup> # (They / always / finish) late.
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Claire</div>
							<div>
								But <sup>7</sup> <input id=6 width='420px'; /> (Miss Macken / not / usually / check) our homework.  
								
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Erica</div>
							<div>
								That’s not true. <sup>8</sup> # (She / always / check) it!
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Claire</div>
							<div>
								Oh come on, Erica! Take the day off!
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Erica</div>
							<div>
								No, <sup>9</sup> # (I / not / come) to town, Claire. Speak to you later, OK?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Claire</div>
							<div>
								OK … looks like I’m shopping on my own, then!
							</div>	
						</div>
					</div>
        `,
        answer: [
          `I'm sitting | I am sitting`,
          `Do you want`,
          `I'm doing | I am doing`,
          `are you doing`,
          `I don't usually do | I do not usually do`,
          `They always finish`,
          `Miss Macken doesn't usually check | Miss Macken does not usually check`,
          `She always checks`,
          `I'm not coming | I am not coming`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P7-E6",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E6/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "6",
        title: `
					Work in pairs. Match 1-8 with a-h. Then ask and answer the questions with your partner.
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    question: {
      DrawLines: {
        boxMatch: [
          // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "14px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "172px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "214px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "255px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "295px",
              left: "180px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },

          // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "14px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "172px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "214px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "255px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
          // 15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "295px",
              left: "330px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: "2px solid black",
            },
          },
        ],
        answers: ["0-12", "1-14", "2-8", "13-3", "11-4", "15-5", "10-6", "7-9"],
        initialValue: [],
      },
      Layout: `
			<div style='display: flex; gap: 230px;'>
				<div style='display: flex; gap: 10px; flex-direction: column; font-size: 20px;'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>1</span>
						<div><span style='color: rgb(0, 173, 238);'>What</span> are</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>2</span>
						<div><span style='color: rgb(0, 173, 238);'>Where</span> does</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>3</span>
						<div><span style='color: rgb(0, 173, 238);'>Who</span> are</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>4</span>
						<div><span style='color: rgb(0, 173, 238);'>Why</span> are</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>5</span>
						<div><span style='color: rgb(0, 173, 238);'>How</span> are</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>6</span>
						<div><span style='color: rgb(0, 173, 238);'>How often</span> do</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>7</span>
						<div><span style='color: rgb(0, 173, 238);'>What time </span> do</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>8</span>
						<div><span style='color: rgb(0, 173, 238);'>When</span> does</div>
					</div>
				</div>

				<div style='display: flex; gap: 10px; flex-direction: column; font-size: 20px;'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>a</span>
						<div>you sitting next to?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>b</span>
						<div>the lesson finish?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>c</span>
						<div>you usually get up?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>d</span>
						<div>you feeling today?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>e</span>
						<div>you doing now?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>f</span>
						<div>you studying English?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>g</span>
						<div>your best friend live?</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>h</span>
						<div>you play sport?</div>
					</div>
				</div>
			</div>


				<div></div>

				<input id='0' type= 'boxMatch' />
				<input id='1' type= 'boxMatch' />
				<input id='2' type= 'boxMatch' />
				<input id='3' type= 'boxMatch' />
				<input id='4' type= 'boxMatch' />
				<input id='5' type= 'boxMatch' />
				<input id='6' type= 'boxMatch' />
				<input id='7' type= 'boxMatch' />
				<input id='8' type= 'boxMatch' />
				<input id='9' type= 'boxMatch' />
				<input id='10' type= 'boxMatch' />
				<input id='11' type= 'boxMatch' />
				<input id='12' type= 'boxMatch' />
				<input id='13' type= 'boxMatch' />
				<input id='14' type= 'boxMatch' />
				<input id='15' type= 'boxMatch' />
      `,
    },
  },
  7: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P7-E7",
    audio: "",
    video: "",
    component: T6v2,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
				Work in pairs. Write another six questions using the question words in <span style='color: rgb(0, 173, 238);'>blue</span> in exercise 6. 
				Then ask and answer the questions with your partner. 
				What do you both have in common? What are the differences?
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='margin-top: 30px;'><img src='img/FriendsPlus/Page7/E7/1.jpg' width='70%' /></div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
