import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E1",
    audio: "Audios/1.16.mp3",
    exerciseKey: "img/FriendsPlus/Page22/E1/Key/answerKey.png",
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				<headphone name="1.16"></headphone > 
				Match adjectives 1-6 with the extreme adjectives in <span style='color: rgb(0, 173, 238);'>blue</span> in the questionnaire. Then listen and check.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div><img src='img/FriendsPlus/Page22/E1/1.jpg' width='70%' /></div>

				<div style='margin-top: 20px; display: flex; gap: 100px;'>
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>fantastic <span style='font-style: italic; color: rgb(88, 88, 90);'>wonderful</span></div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>interesting #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>tasty #</div>
						</div>
					</div>
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>scary #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>horrible #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>6</span>
							<div>tired #</div>
						</div>
					</div>
				</div>
        `,
        answer: [
          "fascinating",
          "delicious",
          "terrifying",
          "disgusting",
          "exhausted",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E2",
    audio: "Audios/1.17.mp3",
    exerciseKey: "img/FriendsPlus/Page22/E2/Key/answerKey.png",
    component: T6v2,
    inputSize: 950,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				<headphone name="1.17"></headphone > 
				Listen and order photos A–C. Which questions from the questionnaire are the people answering?
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='position: relative;'>
						<div><img src='img/FriendsPlus/Page22/E2/1.jpg' width='35%' /></div>
						<div style='position: absolute; top: 10px; left: 328px;'>Order photos: <input id='0' width='40px' />, <input id='1' width='40px' />, <input id='2' width='40px' /></div>
					</div>
     <div style="display:flex; flex-direction:row">
     </div>
					<div style='margin: 10px 0;'><img src='img/FriendsPlus/Page22/E1/1.jpg' width='65%' /></div>
					<div>Which questions from the questionnaire are the people answering?</div>
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>#</div>
						</div>
					</div>
        `,
        answer: [
          "B",
          "A",
          "C",
          `4 | Have you ever touched a weird or fascinating animal? What was it like?`,
          `3 | Have you ever felt totally exhausted? Why?`,
          `1 | Name the two most delicious and the two most disgusting things that you've ever eaten.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E3",
    exerciseKey: "img/FriendsPlus/Page22/E3/Key/answerKey.png",
    component: T6v2,
    inputSize: 120,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Read sentences 1-5 in exercise 4. Decide what type of answer you should be listening 
				for: <i>age, noun, distance, reason</i> or <i>adjective</i>.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='display: flex; gap: 10px; flex-direction: column;'>
					<div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>Nina rode an alpaca when she was ...</div>
						</div>
						<div><span style='font-weight: bold; font-style: italic;'>Type of answer:</span> age</div>
					</div>
					<div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>Her friend thinks that alpacas probably smell ...</div>
						</div>
						<div><span style='font-weight: bold; font-style: italic;'>Type of answer:</span> #</div>
					</div>
					<div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>The furthest that Jo has ever cycled is ...</div>
						</div>
						<div><span style='font-weight: bold; font-style: italic;'>Type of answer:</span> #</div>
					</div>
					<div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>A durian is a type of ...</div>
						</div>
						<div><span style='font-weight: bold; font-style: italic;'>Type of answer:</span> #</div>
					</div>
					<div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>People can’t take durians on buses because ...</div>
						</div>
						<div><span style='font-weight: bold; font-style: italic;'>Type of answer:</span> #</div>
					</div>
				</div>
        `,
        answer: ["adjective", "distance", "noun", "reason"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E4",
    audio: "Audios/1.17.mp3",
    exerciseKey: "img/FriendsPlus/Page22/E4/Key/answerKey.png",
    component: T6v2,
    inputSize: 210,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					<headphone name='1.17'></headphone > 
					Listen again and complete the sentences. Were your answers in exercise 3 correct?
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>Nina rode an alpaca when she was #.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>Her friend thinks that alpacas probably smell #.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>The furthest that Jo has ever cycled is #.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>A durian is a type of #.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>People can’t take durians on buses because #.</div>
						</div>
					</div>
        `,
        answer: [
          "six years old",
          "disgusting",
          "100 kilometres",
          "fruit",
          "they smell so bad",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E5",
    component: T6v2,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				Work in pairs. Read the 
				<i>Feelings questionnaire</i> again and answer the 
				questions for you. Then ask and answer the 
				questions with a partner.
				`,
        color: "rgb(245, 128, 37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				`,
        answer: [],
      },
    ],
  },
};

export default json;
