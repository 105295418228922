import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P16-E1",
    audio: "Audios/1.11.mp3",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page16/E1/Key/answerKey.png",
    component: T6,
    inputSize: 200,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: `<videoimage></videoimage> <headphone name='1.11'></headphone > Complete the dialogue with the phrases in the box. Then watch or listen and check. What does Hugo think of Sammy's trousers? Why is Hugo buying a shirt?`,
        color: "#dc1e74",
        // prefix: { icons: ['far fa-comment'], text: '' },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='display: flex; justify-content:center; margin: 10px'>
         <span style='border:2px solid rgb(220, 30, 116);border-radius: 15px; padding: 0 10px 0 10px;'>
         decide &emsp; expensive &emsp; few weeks &emsp; interesting shirt
         &emsp; look &emsp; smart &emsp; wedding
         </span>
      </div>

              <div style='display: flex;'>
                <div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Hi, Sammy</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Oh, hi, Hugo.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Those are cool trousers.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Really? I think they're a bit baggy.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>They're supposed to be like that. I bought a pair like that a <b><sup>1</sup></b># ago and they're so comfortable. They look really good.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Maybe I'll buy them, then. That's an <b><sup>2</sup></b># you're wearing.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>It's not really my style, but I'm going to a <b><sup>3</sup></b># next week and Mum wants me to wear something <b><sup>4</sup></b># . What do you think of this one?</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>That's better. It's <b><sup>5</sup></b># , though.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>You're right, it is.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>But your mum does want you to <b><sup>6</sup></b># smart.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Mmm, true. Why don't I try it on and then <b><sup>7</sup></b># ?</div>
                  </div>
                </div>
                <div><img src='img/FriendsPlus/Page16/E1/1.png' width=110% /></div>
              </div>
        `,
        answer: [
          `few weeks`,
          `interesting shirt`,
          `wedding`,
          `smart`,
          `expensive`,
          `look`,
          `decide`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P16-E2",
    audio: "Audios/1.11.mp3",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page16/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `<videoimage></videoimage> <headphone name='1.11'></headphone > Cover the dialogue and circle the words in <i>italics</i> in the Key Phrases <br/>that Hugo and Sammy use. Watch or listen again and check.`,
        color: "#dc1e74",
        // prefix: { icons: ['far fa-comment'], text: '' },
      },
    ],
    component: Circle_Write,
    inputSize: 35,
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          // fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `good / well`,
          `baggy / tight`,
          `about / of`,
          `interest / style`,
          `expensive / comfortable`,
          `on / out`,
        ],
        answers: ["2-4", "3-4", "4-0", "0-0", "1-0", "5-0"],
        initialValue: [],
      },
      Layout: `
        <div style='width: 100vh; display: flex; justify-content:center'>
          <div style='width:600px; height: 350px;
                      background-repeat: no-repeat;
                      background-size: 600px 350px;
                      background-image: url("img/FriendsPlus/Page16/E2/1.png");
          '>
            <div style=' padding: 110px 5px 0 20px'>
              <div>They look really <b><sup>1</sup></b><i><input id='0' type='Circle' /></i>.</div>
              <div>They're a bit <b><sup>2</sup></b><i><input id='1' type='Circle' /></i>.</div>
              <div>What do you think <b><sup>3</sup></b><i><input id='2' type='Circle' /></i> this one?</div>
              <div>It's (not) really my <b><sup>4</sup></b><i><input id='3' type='Circle' /></i>.</div>
              <div>It's <b><sup>5</sup></b><i><input id='4' type='Circle' /></i>, though.</div>
              <div>Why don't you try it <b><sup>6</sup></b><i><input id='5' type='Circle' /></i>.</div>
            </div>
          </div>
        </div>

        `,
    },
  },
  3: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P16-E3",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Work in pairs. Practise the dialogue.`,
        color: "#dc1e74",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='display: flex; justify-content:center; margin: 10px'>
         <span style='border:2px solid rgb(220, 30, 116);border-radius: 15px; padding: 0 10px 0 10px;'>
         decide &emsp; expensive &emsp; few weeks &emsp; interesting shirt
         &emsp; look &emsp; smart &emsp; wedding
         </span>
      </div>

              <div style='display: flex;'>
                <div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Hi, Sammy</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Oh, hi, Hugo.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Those are cool trousers.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Really? I think they're a bit baggy.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>They're supposed to be like that. I bought a pair like that a <b><sup>1</sup></b>... ago and they're so comfortable. They look really good.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Maybe I'll buy them, then. That's an <b><sup>2</sup></b>... you're wearing.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>It's not really my style, but I'm going to a <b><sup>3</sup></b>... next week and Mum wants me to wear something <b><sup>4</sup></b>... . What do you think of this one?</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>That's better. It's <b><sup>5</sup></b>... , though.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>You're right, it is.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>But your mum does want you to <b><sup>6</sup></b>... smart.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Mmm, true. Why don't I try it on and then <b><sup>7</sup></b>... ?</div>
                  </div>
                </div>
                <div><img src='img/FriendsPlus/Page16/E1/1.png' width=110% /></div>
              </div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P16-E4",
    audio: "Audios/1.12.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page16/E4/Key/answerKey.png",
    inputSize: 95,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.12'></headphone > <span style='color: rgb(220, 30, 116);'>
         PRONUNCIATION: /uː/ and /ʊ/
         </span>
         <br/>
         Listen and repeat. Which sound, /uː/ or /ʊ/, has each word got?`,
        color: "#dc1e74",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display: flex;justify-content: space-around; width: 700px;'>
            <div>
             <div><b>1. </b>blue</div>
             <div><b>2. </b>look</div>
            </div>
            <div>
             <div><b>3. </b>cool</div>
             <div><b>4. </b>shoes</div>
            </div>
            <div>
             <div><b>5. </b>good</div>
             <div><b>6. </b>suit</div>
            </div>
          </div>

          <div><b>ANSWER: </b></div>
             <div><b>/uː/ :</b>#,#,#,#.</div>
             <div><b>/ʊ/ :</b>#,#.</div>

        `,
        answer: [`blue`, `cool`, `shoes`, `suit`, `good`, `look`],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P16-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page16/E5/Key/answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "5",
        title: `
         Work in pairs. Ask and answer questions about the items below. Use the key phrases.`,
        color: "#dc1e74",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
              <div style='width: 100vh; display: flex; justify-content:center'>
              <img src='img/FriendsPlus/Page16/E5/1.jpg' width=60% /></div>

        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P16-E6",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page16/E6/Key/answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "6",
        title: `
         Work in pairs. Read the situation. Then prepare and practise a new dialogue using the key phrases and the dialogue in exercise 1 to help you.
        `,
        color: "#dc1e74",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
              <div style='width: 100vh; display: flex; justify-content:center'>
              <img src='img/FriendsPlus/Page16/E6/1.jpg' width=50% /></div>

      <div style='display: flex; justify-content:center; margin: 10px'>
         <span style='border:3px solid rgb(220, 30, 116);border-radius: 15px; padding: 0 10px 0 10px;'>
         decide &emsp; expensive &emsp; few weeks &emsp; interesting shirt
         &emsp; look &emsp; smart &emsp; wedding
         </span>
      </div>

              <div style='display: flex;'>
                <div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Hi, Sammy</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Oh, hi, Hugo.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Those are cool trousers.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Really? I think they're a bit baggy.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>They're supposed to be like that. I bought a pair like that a <b><sup>1</sup></b>... ago and they're so comfortable. They look really good.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>Maybe I'll buy them, then. That's an <b><sup>2</sup></b>... you're wearing.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>It's not really my style, but I'm going to a <b><sup>3</sup></b>... next week and Mum wants me to wear something <b><sup>4</sup></b>... . What do you think of this one?</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>That's better. It's <b><sup>5</sup></b>... , though.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>You're right, it is.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Sammy</b></div>
                    <div style='width: 700px'>But your mum does want you to <b><sup>6</sup></b>... smart.</div>
                  </div>
                  <div style='display: flex;'>
                    <div style='width: 100px'><b>Hugo</b></div>
                    <div style='width: 700px'>Mmm, true. Why don't I try it on and then <b><sup>7</sup></b>... ?</div>
                  </div>
                </div>
                <div><img src='img/FriendsPlus/Page16/E1/1.png' width=110% /></div>
              </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
