import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
	8: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P48-E8",
		audio: "",
		video: "",
		exerciseKey: "img/FriendsPlus/Page48/key/E8answerKey.png",
		component: Circle_Write,
		titleQuestion: [
			{
				num: "8",
				title: `
		Cross out the incorrect word.
						`,
				color: "rgb(121,106,175)",
			},
		],
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					width: 100,
					textAlign: "center",
					fontSize: 20,
					color: "black",
					maxLength: 5,
				},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: "-40px",
					color: "",
					borderRadius: "50%",
					fontWeight: "700",
				},
				selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
				limitSelect: 1,
				listWords: [
					"rubbish / time / money!",
					"car / problem / laptop.",
					"environment / time / money.",
					"time / plastic / rubbish.",
					"environment /  money / health.",
					"food / water / recycling",
				],
				answers: ["0-0", "1-4", "2-0", "3-0", "4-6", "5-8"],
				initialValue: {},
			},
			Layout: `
			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">1 </span>
				Don't waste your  <input id='0' type='Circle' style="letter-spacing: 10px;"/>
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">2 </span>
				I can't afford a  <input id='1' type='Circle' style="letter-spacing: 10px;"/>
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">3 </span>
				They're going to run out of <input id='2' type='Circle' style="letter-spacing: 10px;"/>
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">4 </span>
				It's important that we recycle all our <input id='3' type='Circle' style="letter-spacing: 10px;"/>
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">5 </span>
				Pollution can damage our <input id='4' type='Circle' style="letter-spacing: 10px;"/>
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="font-weight: bold;">6 </span>
				They provided <input id='5' type='Circle' style="letter-spacing: 10px;"/>
			</div>
	
			
				
					`,
		},
	},
	9: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P48-E9",
		audio: "",

		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page48/key/E9answerKey.png",
		component: T6v2,
		// maxLength: 6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			textAlign: "center",
			fontSize: "24px",
			fontWeight: "bold",
		},
		titleQuestion: [
			{
				num: "9",

				title: `
		Complete the words.
							`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<div style="display: flex; flex-wrap: wrap;margin-top: 40px;">
				<div>
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					1
					</span>
					<span style="font-size: 25px">
						<span style="margin-right: 10px; font-size: 25px"
						>This is a perfect</span
						>
						<span style="font-weight: bold; font-size: 25px">s</span>
						<span>#</span>
						<span>#</span>
						<span>#</span>
						<span>#</span>
						<span>#</span>
						<span>#</span>
						<span>#</span>
						<span style="font-size: 25px">to my problems.</span>
					</span>
				</div>
				
				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					2
					</span>
					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px">Before exams you must</span>
					<span style="font-weight: bold; font-size: 25px">d</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					
					<span style="font-size: 25px">with a lot of stress. </span>
					</span>
				</div>

				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					3
					</span>
					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px"> What's your</span>
					<span style="font-weight: bold; font-size: 25px">v</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					
					<span style="font-size: 25px">on eating insects? Do
					you agree we will eat food made with
					insects as much as possible in the future? </span>
					</span>
				</div>

				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					4
					</span>
					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px"> Sophie</span>
					<span style="font-weight: bold; font-size: 25px">c</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					
					<span style="font-size: 25px"> me of the
					advantages of healthy eating. </span>
					</span>
				</div>

				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					5
					</span>
					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px">It took me two days to</span>
					<span style="font-weight: bold; font-size: 25px">p</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>

					<span style="font-size: 25px"> Alan to go to the part </span>
					</span>
				</div>

				



			</div>
		</div>
							`,
				answer: [
					"o",
					"l",
					"u",
					"t",
					"i",
					"o",
					"n",
					"e",
					"a",
					"l",
					"i",
					"e",
					"w",
					"o",
					"n",
					"v",
					"i",
					"n",
					"c",
					"e",
					"d",
					"e",
					"r",
					"s",
					"u",
					"a",
					"d",
					"e",
				],
			},
		],
	},
	10: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P48-E10",
		audio: "",

		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page48/key/E10answerKey.png",
		component: T6v2,
		// maxLength: 6,
		inputSize: 150,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			// textAlign: 'center',
			fontSize: "24px",
		},
		titleQuestion: [
			{
				num: "10",

				title: `
        Complete the dialogue with the words.
							`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<div style="border: 2px solid rgb(121,106,175); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">a little </p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">enough</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">few</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">lot of</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">many</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">not enough</p>
			</div>

			<div style="display: flex; margin-top:40px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Jason </p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px;">How much sugar have we got? I
					want to make a cake today.</p>
				</div>
			</div>

			<div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Adele</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px;"> Only <sup>1</sup> 
					#
					<span style="font-size: 25px;">I'm afraid. Maybe
					50 g.</span>
					</p>
					
				</div>
			</div>

			<div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Jason</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px;"> That's <sup>2</sup> 
					#
					<span style="font-size: 25px;"> I need 250 g.
					How about eggs? <br/></span>
					<span style="font-size: 25px;"> How <sup>3</sup># are there? </span>
					</p>
					
				</div>
			</div>

			<div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Adele</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px;"> We've got a <sup>4</sup> 
					#
					<span style="font-size: 25px;"> those. I
					think there are six.</span>
					</p>
					
				</div>
			</div>

			<div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Jason</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px;"> Great, that's <sup>5</sup> 
					#
					<span style="font-size: 25px;"> . And have we got any apples? I want to put a <sup>6</sup> # of them into the cake.</span>
					</p>
					
				</div>
			</div>
			<div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Adele</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px;"> Yes. There are three in the blue bowl.</p>
				</div>
			</div>

    
		</div>
    

		
							`,
				answer: ["a little", "not enough", "many", "lot of", "enough", "few"],
			},
		],
	},
	11: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P48-E11",
		audio: "Audios/1.38.mp3",

		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page48/key/E11answerKey.png",
		component: T6v2,
		// maxLength: 6,
		inputSize: 80,
		maxLength: 5,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			//   textAlign: 'center',
			fontSize: "24px",
			fontWeight: "bold",
		},
		titleQuestion: [
			{
				num: "11",

				title: `
		<headphone name="1.38"></headphone >
		Listen to the radio programme. Read the sentences about the products and write
		true or false. 
		
							`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<div style="display: flex; flex-wrap: wrap;margin-top: 40px;">
				<div>
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					1
					</span>
					<span style="font-size: 25px">Felix's project helps you to shop for glasses. #</span>
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					2
					</span>
					<span style="font-size: 25px">The Smart Present app helps you prepare
					home-made gifts from recycled things. #</span>
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					3
					</span>
					<span style="font-size: 25px">You can buy a Smart Present app in a
					second-hand shop. #</span>
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					4
					</span>
					<span style="font-size: 25px"> Jimmy has created a waterproof phone
					charger which uses energy from the rain. #</span>
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					5
					</span>
					<span style="font-size: 25px"> Jimmy's product is ecological and good for
					the environment. #</span>
				</div>
				
			</div>
		</div>
							`,
				answer: ["false", "true", "false", "false", "true"],
			},
		],
	},
};

export default json;
