import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P10-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page10/E1/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 35,
    titleQuestion: [
      {
        num: "1",
        title: "Work with a partner and do the quiz.",
        color: "rgb(3, 103, 181)",
        prefix:{icons:[""], text:""}
      },
    ],
    hintBox:[{
      src:["app","craze","follower","gadget","post (n, v)","social media"],
      width:800,
      borderColor:"rgb(3, 103, 181)"
    }],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
            <hintbox id='0'></hintbox>
              <div><img src='img/FriendsPlus/Page10/E1/a.jpg'  /></div>
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P10-E2",
    audio: "Audios/1.06.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page10/E2/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 40,
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name='1.06'></headphone > Listen and check your answers to the quiz. Which answer surprised you the most?`,
        color: "rgb(3, 103, 181)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; justify-content: center; align-items: center;'>
            <div style='display: flex; gap: 20px; flex-wrap: wrap;'>
              <div style='display: flex; gap: 10px;'><span>1</span>#</div>
              <div style='display: flex; gap: 10px;'><span>2</span>#</div>
              <div style='display: flex; gap: 10px;'><span>3</span>#</div>
              <div style='display: flex; gap: 10px;'><span>4</span>#</div>
              <div style='display: flex; gap: 10px;'><span>5</span>#</div>
              <div style='display: flex; gap: 10px;'><span>6</span>#</div>
            </div>
						</div>
            <div><img src='img/FriendsPlus/Page10/E1/a.jpg'  /></div>
        `,
        answer: ["b", "b", "a", "a", "a", "a"],
      },
    ],
  },
};

export default json;
