import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P56-E1",
    audio: "Audios/2.08.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page56/key/E1answerKey.png",
    textareaStyle: { width: "730px", fontSize: 20 },
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
		<headphone name="2.08"></headphone >
		Choose the correct words in the dialogue. Then watch or listen and check.<br> What three things is Pamela doing today?
						`,
        color: "rgb(220,30,116)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 1300,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          //   maxLength: 5,
        },
        answers: [
          "Pamela is going to auditions for the new show, meeting the TV executives about plans for the TV show, and meeting the TV director for lunch.",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { padding: 5, border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `boring / crazy`, // 0
          `important / fun`, // 1
          `earlier / later`, // 2
          `late / on_time`, // 3
          `fun / new`, // 4,
        ],
        answers: ["0-4", "1-0", "2-0", "3-4", "4-4"],
        initialValue: {},
      },
      Layout: `
	  <div style='display:flex;font-size:20px'>
	           <b >
               aldo<br>
               Pamela<br>
               aldo<br><br><br>
               Pamela<br>
               aldo<br><br>
               Pamela<br>
               aldo<br>
               Pamela<br>
               aldo<br>
               Pamela<br>
               aldo<br>
             </b>

            <div style='width:22cm;margin-left:1cm'>
              So, Pamela, you’ve got a busy day ahead.<br>
              I know, it’s <sup>1</sup><input id='0'  type='Circle' />.<br>
              That’s what happens when you’re 
              <sup>2</sup><input id='1'  type='Circle' />! Don’t forget you’ve 
              got a meeting this afternoon with the 
              television executives. They are going to ask 
              about your new plans for the TV show.<br>
              Yes, what time does that start?<br> The meeting starts at 12.30 in the city 
              centre, so you have to be there a little 
              <sup>3</sup><input id='2'  type='Circle' />. I’ll try to book your taxi 
              for 11.45.<br> But I have auditions for the new show 
              today, remember? The auditions start 
              at ten.<br>Yes, I know. We’re going to make sure 
              you’ll be <sup>4</sup><input id='3'  type='Circle' />.<br>I hope so. We’ll see ... Oh, by the way, 
              I’m meeting the director for lunch today 
              at 2.00 p.m.<br>What? Why didn’t you tell me?<br>I’m telling you now! Can you remind me 
              to tell him about the <sup>5</sup><input id='4'  type='Circle' />, 
              too?<br>OK, whatever you say!

            </div>
      </div>
      <div style='font-size:20px'>
        <b>What three things is Pamela doing today?</b>
        <textarea id="0"  rows='2'></textarea>
      </div>


	
					`,
    },
  },
  2: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P56-E2",
    audio: "Audios/2.08.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		<headphone name="2.08"></headphone >
		Cover the dialogue and complete the Key Phrases. Watch or listen again and check.
											`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px"><img src="img/FriendsPlus/Page56/11.jpg"></div>
		<h1 style="margin: 20px 0">ANSWERS</h1>
		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<div>
				<span style="font-size: 25px;margin-right: 10px; font-weight: bold;">1</span>
				<span style="font-size: 25px;">#</span>
			</div>
			<div>
				<span style="font-size: 25px;margin-right: 10px; font-weight: bold;">2</span>
				<span style="font-size: 25px;">#</span>
			</div>
			<div>
				<span style="font-size: 25px;margin-right: 10px; font-weight: bold;">3</span>
				<span style="font-size: 25px;">#</span>
			</div>
			<div>
				<span style="font-size: 25px;margin-right: 10px; font-weight: bold;">4</span>
				<span style="font-size: 25px;">#</span>
			</div>





		</div>
					
											`,
        answer: [
          "busy day",
          "meeting",
          "auditions for the new show",
          "about the new plans",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P56-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Work in pairs. Practise the dialogue.
        `,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:40px"><img src="img/FriendsPlus/Page56/12.jpg" width="1200px" /></div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P56-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in pairs. Take turns to remind each other to do these things this evening. Use the
        key phrases.
        `,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		      <div style="margin-top:40px;margin-left:200px"><img src="img/FriendsPlus/Page56/11.jpg"/></div>
          <div style="margin-top:40px">
            <span style="font-size: 25px;margin-right: 10px; font-weight: bold;">1</span>
            <span style="font-size: 25px;">history homework</span>
          </div>

          <div>
            <span style="font-size: 25px;margin-right: 10px; font-weight: bold;">2</span>
            <span style="font-size: 25px;">guitar lesson
            </span>
          </div>

          <div>
            <span style="font-size: 25px;margin-right: 10px; font-weight: bold;">3</span>
            <span style="font-size: 25px;">help with dinner
            </span>
          </div>

          <div>
            <span style="font-size: 25px;margin-right: 10px; font-weight: bold;">4</span>
            <span style="font-size: 25px;">go to bed on time
            </span>
          </div>

          <div style="margin-top:40px;margin-left:200px"><img src="img/FriendsPlus/Page56/13.jpg"/></div>

        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P56-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Read the situation, prepare and practise a new dialogue. Use the
        key phrases and the dialogue in exercise 1 to help you
        
.
        `,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
          <img style='height:65mm' src="img/FriendsPlus/Page56/11.jpg"/>
          <img style='height:50mm' img src="img/FriendsPlus/Page56/14.jpg"/>
  
         </div>
        <div style='display:flex;font-size:20px'>
        <b >
          aldo<br>
          Pamela<br>
          aldo<br><br><br>
          Pamela<br>
          aldo<br><br>
          Pamela<br>
          aldo<br>
          Pamela<br>
          aldo<br>
          Pamela<br>
          aldo<br>
        </b>

       <div style='width:22cm;margin-left:1cm'>
         So, Pamela, you’ve got a busy day ahead.<br>
         I know, it’s <sup>1</sup>boring / crazy.<br>
         That’s what happens when you’re 
         <sup>2</sup>important / fun! Don’t forget you’ve 
         got a meeting this afternoon with the 
         television executives. They are going to ask 
         about your new plans for the TV show.<br>
         Yes, what time does that start?<br> The meeting starts at 12.30 in the city 
         centre, so you have to be there a little 
         <sup>3</sup>earlier / later. I’ll try to book your taxi 
         for 11.45.<br> But I have auditions for the new show 
         today, remember? The auditions start 
         at ten.<br>Yes, I know. We’re going to make sure 
         you’ll be <sup>4</sup>late / on time.<br>I hope so. We’ll see ... Oh, by the way, 
         I’m meeting the director for lunch today 
         at 2.00 p.m.<br>What? Why didn’t you tell me?<br>I’m telling you now! Can you remind me 
         to tell him about the <sup>5</sup>fun / new, 
         too?<br>OK, whatever you say!

       </div>
 </div>
  

        `,
        answer: [],
      },
    ],
  },
};

export default json;
