import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P55-E1',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page55/key/E1answerKey.png',
    component: T6v2,
    textareaStyle: { width: '730px' },
    // maxLength: 1,
    inputSize: 120,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '1',

        title: `
			Complete the sentences from the online forum on page 54. Which sentence refers to
			a general prediction and which refers to a definite plan or intention?
											`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<p style="font-size: 25px;margin-top:20px">
        	<span style="font-weight: bold;">a</span> 
			I’m # start up my own business
    	</p>

		<p style="font-size: 25px;margin-top:10px">
        	<span style="font-weight: bold;">b</span> 
			#retire young.
    	</p>

		<h1 style="margin: 20px 0">Which sentence refers to
      a general prediction and which refers to a
      definite plan or intention?</h1>

	  <div style="display: flex; flex-wrap: wrap; gap: 10px; font-size:25px">
        	<span style="font-weight: bold">1</span> 
			<textarea id="2" rows="2"></textarea>
    	</div>

						
											`,
        answer: [
          'going to',
          "I'll",
          'Sentence b refers to a general prediction, and sentence a refers to a definite plan or intention.',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P55-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page55/key/E2answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '2',
        title: `
        Choose the correct options. Then ask and answer the questions with a partner.
					`,
        color: 'rgb(28,190,215)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 20,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
        },
        selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: [
          `will / are_going_to`, // 0
          `will / are_going_to`, // 1
          `you'll / you're_going_to`, // 2
          `will / is_going_to`, // 3
          `Will_you / Are_you_going_to`, // 4,
        ],
        answers: ['0-0', '1-0', '2-0', '3-4', '4-4'],
        initialValue: {},
      },
      Layout: `
      <div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">1 </span>
			Do you think you <input id='0' type='Circle' style="letter-spacing: 10px;"/> travel
			into space?
		</div>


		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">2 </span>
			How many people <input id='1' type='Circle' style="letter-spacing: 10px;"/> live
			on Earth in 2100?
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">3 </span>
			Do you think that one day 
			<input id='2' type='Circle' style="letter-spacing: 10px;"/> be famous?
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">4 </span>
			Who  
			<input id='3' type='Circle' style="letter-spacing: 10px;"/> take up a new sport
			this summer?
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">5 </span>
			<input id='4' type='Circle' style="letter-spacing: 10px;"/> watch TV
			tonight?
		</div>

				`,
    },
  },
  3: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P55-E3',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page55/key/E3answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 390,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '3',

        title: `
        Complete the dialogue with the correct form of will and be going to and the verbs in brackets.
							`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
		<div class="container">
			

			<div style="display: flex; margin-top:30px">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 22px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-10">
					<p style="font-size: 22px">
					I’ve decided that <sup>1</sup> #  (I / teach) myself the guitar</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 22px; font-weight: bold;">Sam</p>
				</div>
				<div class="col-10">
					<p style="font-size: 22px">
					Great! That <sup>2</sup> # (be) fun.<br/> When <sup>3</sup> # (you / start) learning?
					</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 22px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-10">
					<p style="font-size: 22px">
					I’m not sure. I don’t think that <sup>4</sup> #  (my mother / buy)
					me a guitar, so first of all <sup>5</sup> # (I / buy) myself a cheap guitar. Then
					<sup>6</sup> # (I / visit) the outdoor music festival next month to practise with other people.
					</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 22px; font-weight: bold;">Sam</p>
				</div>
				<div class="col-10">
					<p style="font-size: 22px">
					Oh, that’s a good idea. I’m sure <sup>7</sup> #  (you / enjoy)
					yourself there. <sup>8</sup> # (the weather / be) good, do you think?
					</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 22px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-10">
					<p style="font-size: 22px">
					I’ve no idea, but <sup>9</sup> # (I / not change) my plans.
					</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 22px; font-weight: bold;">Sam</p>
				</div>
				<div class="col-10">
					<p style="font-size: 22px">
					I’m sure <sup>10</sup> # (you / learn) quickly.
					</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 22px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-10">
					<p style="font-size: 22px">
					I hope so.
					</p>
				</div>
			</div>

			
			

			

			



		</div>
    
		
							`,
        answer: [
          "I'm going to teach",
          "will be / 'll be",
          'will you / are you going to start',
          'my mother will buy',
          "I'm going to buy",
          "I'm going to visit",
          "you'll enjoy",
          'Will the weather be',
          "I'm not going to change",
          "you'll learn",
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P55-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page55/key/E4answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
		Study the sentence from the online forum on page 54. Then choose the
		correct options in the rules.
					`,
        color: 'rgb(28,190,215)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 22,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
        },
        selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: [
          `will_be_finished / will_be_in_progress`, // 0
          `use / don't_use`, // 1
        ],
        answers: ['0-4', '1-4'],
        initialValue: {},
      },
      Layout: `
	  <div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px">
      <div
        style="
        background-color: rgb(28, 190, 215);
        border-radius: 40px;
        margin: 10px 10px;
        "
      >
        <h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
      </div>

        <div style="margin: 10px 10px;font-size:22px">
          <span style="font-weight: bold;">1 </span>
          The future continuous predicts or describes an action which we think 
          <input id='0' type='Circle' style="letter-spacing: 10px;"/> at a specific point in the future.
        </div>

        <div style="margin: 10px 10px;font-size:22px">
          <span style="font-weight: bold;">2 </span>
          We
          <input id='1' type='Circle' style="letter-spacing: 10px;"/> continuous tenses with
          state verbs such as be, seem... 
        </div>

    </div>
			
				`,
    },
  },
  5: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P55-E5',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page55/key/E5answerKey.png',
    component: T6v2,
    // maxLength: 5,
    inputSize: 650,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '5',

        title: `
		Order the words to make sentences. There is
one extra word in each sentence.
										`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">1</span> 
			watching TV / you / be / will / been / this time tomorrow / ? <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">2</span> 
			the match / won’t / don’t / we / be / watching / on Sunday <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">3</span> 
			will / staying / you / are / in a hotel / be / this time next week / ? <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">4</span> 
			 studying here / I / be / won’t / to / when I’m older <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">5</span> 
			humans / living / live / be / will / in the future / on Mars / ? <br/>
			#
        </p>

		

										`,
        answer: [
          'Will you be watching TV this time tomorrow? (been)',
          "We won't be watching the match on Sunday. (don't)",
          'Will you be staying in a hotel this time next week? (are)',
          "I won't be studying here when I'm older. (to)",
          'Will humans be living on Mars in the future? (live)',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P55-E6',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '6',
        title: `
        Work in pairs using the future continuous and words from the boxes. Ask and answer the questions about your future
        `,
        color: 'rgb(28,190,215)',
        prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left:200px;margin-top:40px"><img src="img/FriendsPlus/Page55/a.jpg"  /></div>
        `,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P51-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: "Write what you’ll be doing in ten years' time.",
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  }
};

export default json;
