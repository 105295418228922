export const dataBook = {
	1: [[{ imgUrl: 'img/FriendsPlus/ListPage/1.jpg', excerciseNum: 0 }]],
	2: [[{ imgUrl: 'img/FriendsPlus/Page2/1.jpg', excerciseNum: 0 }]],
	3: [[{ imgUrl: 'img/FriendsPlus/Page3/1.jpg', excerciseNum: 0 }]],
	4: [[{ imgUrl: 'img/FriendsPlus/Page4/1.jpg', excerciseNum: 0 }]],
	5: [[{ imgUrl: 'img/FriendsPlus/Page5/1.jpg', excerciseNum: 0 }]],
	6: [
		[{ imgUrl: 'img/FriendsPlus/Page6/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page6/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page6/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page6/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page6/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page6/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page6/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page6/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page6/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page6/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page6/11.jpg', excerciseNum: 5 },
		],

		[{ imgUrl: 'img/FriendsPlus/Page6/12.jpg', excerciseNum: 0 }],
	],
	7: [
		[{ imgUrl: 'img/FriendsPlus/Page7/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page7/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page7/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page7/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page7/5.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page7/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page7/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page7/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page7/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page7/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page7/11.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page7/12.jpg', excerciseNum: 5 }],
	],
	8: [
		[{ imgUrl: 'img/FriendsPlus/Page8/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page8/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/11.jpg', excerciseNum: 0 },
		],
	],
	9: [
		[{ imgUrl: 'img/FriendsPlus/Page9/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page9/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page9/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page9/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page9/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page9/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page9/13.jpg', excerciseNum: 7 },
		],
	],
	10: [
		[{ imgUrl: 'img/FriendsPlus/Page10/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page10/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page10/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page10/6.jpg', excerciseNum: 0 }],
	],
	11: [
		[{ imgUrl: 'img/FriendsPlus/Page11/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/7.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/11.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/13.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/14.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/15.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/16.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/17.jpg', excerciseNum: 0 },
		],
	],
	12: [
		[{ imgUrl: 'img/FriendsPlus/Page12/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page12/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page12/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page12/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page12/8.jpg', excerciseNum: 0 }],
	],
	13: [
		[{ imgUrl: 'img/FriendsPlus/Page13/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page13/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page13/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page13/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page13/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page13/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page13/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page13/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page13/9.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page13/10.jpg', excerciseNum: 0 }],
	],
	14: [
		[{ imgUrl: 'img/FriendsPlus/Page14/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page14/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page14/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page14/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page14/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page14/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page14/12.jpg', excerciseNum: 0 }],
	],
	15: [
		[{ imgUrl: 'img/FriendsPlus/Page15/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page15/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page15/4.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page15/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page15/7.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page15/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page15/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page15/11.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page15/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page15/13.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/14.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page15/15.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page15/16.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/17.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page15/18.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page15/19.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/20.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page15/21.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page15/22.jpg', excerciseNum: 0 },
		],
	],
	16: [
		[{ imgUrl: 'img/FriendsPlus/Page16/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/4.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/5.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/7.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page16/8.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/9.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/10.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/11.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page16/12.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/13.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/14.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/15.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page16/16.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/17.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/18.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page16/19.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page16/20.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/21.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/22.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page16/23.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page16/24.jpg', excerciseNum: 0 },
		],
	],
	17: [
		[{ imgUrl: 'img/FriendsPlus/Page17/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page17/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page17/4.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page17/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page17/7.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page17/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page17/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page17/11.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page17/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page17/13.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page17/14.jpg', excerciseNum: 0 }],
	],
	18: [
		[{ imgUrl: 'img/FriendsPlus/Page18/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page18/2.jpg', excerciseNum: 1 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page18/3.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page18/4.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page18/5.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page18/6.jpg', excerciseNum: 1 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page18/7.jpg', excerciseNum: 3 }],
	],
	19: [
		[{ imgUrl: 'img/FriendsPlus/Page19/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page19/2.jpg', excerciseNum: 4 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/3.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page19/4.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/5.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page19/6.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/7.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page19/8.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/9.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page19/10.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page19/11.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page19/13.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page19/14.jpg', excerciseNum: 0 }],
	],
	20: [
		[{ imgUrl: 'img/FriendsPlus/Page20/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page20/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page20/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page20/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page20/9.jpg', excerciseNum: 0 },
		],
	],
	21: [
		[{ imgUrl: 'img/FriendsPlus/Page21/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page21/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page21/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page21/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page21/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page21/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page21/13.jpg', excerciseNum: 8 },
		],
	],
	22: [
		[{ imgUrl: 'img/FriendsPlus/Page22/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page22/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page22/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page22/7.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page22/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page22/11.jpg', excerciseNum: 5 },
		],
	],
	23: [
		[{ imgUrl: 'img/FriendsPlus/Page23/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page23/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page23/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page23/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page23/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page23/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page23/13.jpg', excerciseNum: 7 },
		],
	],
	24: [
		[{ imgUrl: 'img/FriendsPlus/Page24/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page24/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page24/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page24/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page24/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page24/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page24/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page24/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page24/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page24/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page24/11.jpg', excerciseNum: 0 },
		],
	],
	25: [
		[{ imgUrl: 'img/FriendsPlus/Page25/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page25/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page25/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page25/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page25/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page25/11.jpg', excerciseNum: 5 },
		],
	],
	26: [
		[{ imgUrl: 'img/FriendsPlus/Page26/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page26/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page26/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page26/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page26/8.jpg', excerciseNum: 0 }],
	],
	27: [
		[{ imgUrl: 'img/FriendsPlus/Page27/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page27/3.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/4.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page27/5.jpg', excerciseNum: 7 },
		],
	],
	28: [
		[{ imgUrl: 'img/FriendsPlus/Page28/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/2.jpg', excerciseNum: 8 },
			{ imgUrl: 'img/FriendsPlus/Page28/3.jpg', excerciseNum: 10 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/4.jpg', excerciseNum: 9 },
			{ imgUrl: 'img/FriendsPlus/Page28/5.jpg', excerciseNum: 11 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page28/7.jpg', excerciseNum: 11 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page28/8.jpg', excerciseNum: 0 }],
	],
	29: [
		[{ imgUrl: 'img/FriendsPlus/Page29/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/2.jpg', excerciseNum: 12 },
			{ imgUrl: 'img/FriendsPlus/Page29/3.jpg', excerciseNum: 14 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/4.jpg', excerciseNum: 13 },
			{ imgUrl: 'img/FriendsPlus/Page29/5.jpg', excerciseNum: 14 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/6.jpg', excerciseNum: 13 },
			{ imgUrl: 'img/FriendsPlus/Page29/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page29/8.jpg', excerciseNum: 0 }],
	],
	30: [
		[{ imgUrl: 'img/FriendsPlus/Page30/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page30/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page30/3.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page30/4.jpg', excerciseNum: 2 }],
	],
	31: [
		[{ imgUrl: 'img/FriendsPlus/Page31/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page31/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page31/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page31/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page31/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page31/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page31/7.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page31/8.jpg', excerciseNum: 0 }],
	],
	32: [
		[{ imgUrl: 'img/FriendsPlus/Page32/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page32/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page32/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page32/7.jpg', excerciseNum: 0 },
		],
	],
	33: [
		[{ imgUrl: 'img/FriendsPlus/Page33/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page33/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page33/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page33/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page33/9.jpg', excerciseNum: 0 },
		],
	],
	34: [
		[{ imgUrl: 'img/FriendsPlus/Page34/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page34/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page34/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page34/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page34/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page34/11.jpg', excerciseNum: 6 },
		],
	],
	35: [
		[{ imgUrl: 'img/FriendsPlus/Page35/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page35/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page35/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page35/7.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page35/9.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page35/11.jpg', excerciseNum: 9 },
		],
	],
	36: [
		[{ imgUrl: 'img/FriendsPlus/Page36/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page36/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page36/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page36/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page36/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page36/11.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page36/13.jpg', excerciseNum: 0 },
		],
	],
	37: [
		[{ imgUrl: 'img/FriendsPlus/Page37/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page37/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page37/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page37/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page37/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page37/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page37/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page37/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page37/9.jpg', excerciseNum: 5 },
		],
	],
	38: [
		[{ imgUrl: 'img/FriendsPlus/Page38/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page38/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page38/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page38/6.jpg', excerciseNum: 0 }],
	],
	39: [
		[{ imgUrl: 'img/FriendsPlus/Page39/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page39/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page39/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page39/7.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page39/8.jpg', excerciseNum: 0 }],
	],
	40: [
		[{ imgUrl: 'img/FriendsPlus/Page40/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page40/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page40/5.jpg', excerciseNum: 4 },
		],
	],
	41: [
		[{ imgUrl: 'img/FriendsPlus/Page41/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page41/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page41/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page41/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page41/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page41/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page41/12.jpg', excerciseNum: 3 }],
	],
	42: [
		[{ imgUrl: 'img/FriendsPlus/Page42/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page42/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page42/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page42/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page42/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page42/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page42/13.jpg', excerciseNum: 6 },
		],
	],
	43: [
		[{ imgUrl: 'img/FriendsPlus/Page43/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page43/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page43/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page43/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page43/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page43/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page43/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page43/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page43/9.jpg', excerciseNum: 7 },
		],
	],
	44: [
		[{ imgUrl: 'img/FriendsPlus/Page44/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page44/2.jpg', excerciseNum: 1 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page44/4.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/5.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page44/6.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/7.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page44/8.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/9.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page44/10.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/11.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page44/12.jpg', excerciseNum: 6 },
		],
	],
	45: [
		[{ imgUrl: 'img/FriendsPlus/Page45/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page45/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page45/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page45/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page45/9.jpg', excerciseNum: 5 },
		],
	],
	46: [
		[{ imgUrl: 'img/FriendsPlus/Page46/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page46/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page46/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page46/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page46/5.jpg', excerciseNum: 4 },
		],
	],
	47: [
		[{ imgUrl: 'img/FriendsPlus/Page47/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page47/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page47/3.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page47/4.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page47/5.jpg', excerciseNum: 7 },
		],
	],
	48: [
		[{ imgUrl: 'img/FriendsPlus/Page48/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page48/2.jpg', excerciseNum: 8 },
			{ imgUrl: 'img/FriendsPlus/Page48/3.jpg', excerciseNum: 10 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page48/4.jpg', excerciseNum: 9 },
			{ imgUrl: 'img/FriendsPlus/Page48/5.jpg', excerciseNum: 10 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page48/6.jpg', excerciseNum: 9 },
			{ imgUrl: 'img/FriendsPlus/Page48/7.jpg', excerciseNum: 11 },
		],
	],
	49: [
		[{ imgUrl: 'img/FriendsPlus/Page49/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page49/2.jpg', excerciseNum: 12 },
			{ imgUrl: 'img/FriendsPlus/Page49/3.jpg', excerciseNum: 15 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page49/4.jpg', excerciseNum: 13 },
			{ imgUrl: 'img/FriendsPlus/Page49/5.jpg', excerciseNum: 15 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page49/6.jpg', excerciseNum: 14 },
			{ imgUrl: 'img/FriendsPlus/Page49/7.jpg', excerciseNum: 15 },
		],
	],
	50: [
		[{ imgUrl: 'img/FriendsPlus/Page50/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page50/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page50/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page50/6.jpg', excerciseNum: 0 }],
	],
	51: [
		[{ imgUrl: 'img/FriendsPlus/Page51/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page51/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page51/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page51/7.jpg', excerciseNum: 5 },
		],
	],
	52: [
		[{ imgUrl: 'img/FriendsPlus/Page52/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page52/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page52/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page52/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page52/5.jpg', excerciseNum: 4 },
		],
	],
	53: [
		[{ imgUrl: 'img/FriendsPlus/Page53/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page53/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page53/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page53/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page53/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page53/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page53/13.jpg', excerciseNum: 7 },
		],
	],
	54: [
		[{ imgUrl: 'img/FriendsPlus/Page54/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page54/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page54/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page54/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page54/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page54/11.jpg', excerciseNum: 6 },
		],
	],
	55: [
		[{ imgUrl: 'img/FriendsPlus/Page55/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page55/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page55/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page55/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page55/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page55/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page55/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/14.jpg', excerciseNum: 0 },
		],
	],
	56: [
		[{ imgUrl: 'img/FriendsPlus/Page56/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page56/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page56/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page56/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page56/9.jpg', excerciseNum: 5 },
		],
	],
	57: [
		[{ imgUrl: 'img/FriendsPlus/Page57/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page57/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page57/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page57/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page57/9.jpg', excerciseNum: 5 },
		],
	],
	58: [
		[{ imgUrl: 'img/FriendsPlus/Page58/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page58/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page58/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page58/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page58/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page58/6.jpg', excerciseNum: 0 }],
	],
	59: [
		[{ imgUrl: 'img/FriendsPlus/Page59/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page59/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page59/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page59/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page59/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page59/a.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page59/b.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page59/c.jpg', excerciseNum: 0 }],
	],
	60: [
		[{ imgUrl: 'img/FriendsPlus/Page60/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page60/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page60/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page60/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page60/5.jpg', excerciseNum: 4 },
		],
	],
	61: [
		[{ imgUrl: 'img/FriendsPlus/Page61/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page61/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page61/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page61/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page61/9.jpg', excerciseNum: 5 },
		],
	],
	62: [
		[{ imgUrl: 'img/FriendsPlus/Page62/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page62/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page62/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page62/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page62/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page62/11.jpg', excerciseNum: 6 },
		],
	],
	63: [
		[{ imgUrl: 'img/FriendsPlus/Page63/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page63/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page63/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page63/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page63/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page63/11.jpg', excerciseNum: 6 },
		],
	],
	64: [
		[{ imgUrl: 'img/FriendsPlus/Page64/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page64/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page64/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page64/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page64/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page64/11.jpg', excerciseNum: 6 },
		],
	],
	65: [
		[{ imgUrl: 'img/FriendsPlus/Page65/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page65/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page65/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page65/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page65/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page65/11.jpg', excerciseNum: 6 },
		],
	],
	66: [
		[{ imgUrl: 'img/FriendsPlus/Page66/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page66/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page66/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page66/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page66/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page66/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page66/7.jpg', excerciseNum: 4 },
		],
	],
	67: [
		[{ imgUrl: 'img/FriendsPlus/Page67/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page67/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page67/3.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page67/4.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page67/5.jpg', excerciseNum: 7 },
		],
	],
	68: [
		[{ imgUrl: 'img/FriendsPlus/Page68/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/2.jpg', excerciseNum: 8 },
			{ imgUrl: 'img/FriendsPlus/Page68/3.jpg', excerciseNum: 10 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/4.jpg', excerciseNum: 9 },
			{ imgUrl: 'img/FriendsPlus/Page68/5.jpg', excerciseNum: 10 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/6.jpg', excerciseNum: 9 },
			{ imgUrl: 'img/FriendsPlus/Page68/7.jpg', excerciseNum: 11 },
		],
	],
	69: [
		[{ imgUrl: 'img/FriendsPlus/Page69/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/2.jpg', excerciseNum: 12 },
			{ imgUrl: 'img/FriendsPlus/Page69/3.jpg', excerciseNum: 14 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/4.jpg', excerciseNum: 13 },
			{ imgUrl: 'img/FriendsPlus/Page69/5.jpg', excerciseNum: 14 },
		],
	],
	// Unit 7 (Page 70-77)
	70: [
		[{ imgUrl: 'img/FriendsPlus/Page70/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page70/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page70/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page70/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page70/8.jpg', excerciseNum: 0 }],
	],
	71: [
		[{ imgUrl: 'img/FriendsPlus/Page71/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page71/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page71/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page71/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page71/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page71/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page71/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page71/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page71/9.jpg', excerciseNum: 7 },
		],
	],
	72: [
		[{ imgUrl: 'img/FriendsPlus/Page72/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page72/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page72/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page72/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page72/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page72/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page72/7.jpg', excerciseNum: 5 },
		],
	],
	73: [
		[{ imgUrl: 'img/FriendsPlus/Page73/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page73/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page73/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page73/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page73/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page73/11.jpg', excerciseNum: 0 },
		],
	],
	74: [
		[{ imgUrl: 'img/FriendsPlus/Page74/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page74/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page74/5.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page74/7.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page74/9.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page74/11.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page74/12.jpg', excerciseNum: 5 }],
		[{ imgUrl: 'img/FriendsPlus/Page74/13.jpg', excerciseNum: 0 }],
	],
	75: [
		[{ imgUrl: 'img/FriendsPlus/Page75/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page75/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page75/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page75/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page75/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page75/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/12.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page75/13.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/14.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page75/15.jpg', excerciseNum: 7 },
		],
	],
	76: [
		[{ imgUrl: 'img/FriendsPlus/Page76/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page76/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page76/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page76/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/8.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page76/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page76/11.jpg', excerciseNum: 5 },
		],
	],
	77: [
		[{ imgUrl: 'img/FriendsPlus/Page77/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page77/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page77/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page77/7.jpg', excerciseNum: 4 },
		],
	],
	// Unit 8 (Page 78-89)
	78: [
		[{ imgUrl: 'img/FriendsPlus/Page78/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page78/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page78/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page78/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page78/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page78/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page78/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page78/8.jpg', excerciseNum: 0 }],
	],
	79: [
		[{ imgUrl: 'img/FriendsPlus/Page79/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page79/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page79/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page79/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page79/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page79/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page79/7.jpg', excerciseNum: 6 },
		],
	],
	80: [
		[{ imgUrl: 'img/FriendsPlus/Page80/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page80/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page80/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page80/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page80/9.jpg', excerciseNum: 5 },
		],
	],
	81: [
		[{ imgUrl: 'img/FriendsPlus/Page81/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page81/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page81/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page81/7.jpg', excerciseNum: 5 },
		],
	],
	82: [
		[{ imgUrl: 'img/FriendsPlus/Page82/1.jpg', excerciseNum: 3 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page82/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page82/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page82/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page82/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page82/11.jpg', excerciseNum: 6 },
		],
	],
	83: [
		[{ imgUrl: 'img/FriendsPlus/Page83/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page83/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page83/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page83/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page83/9.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page83/11.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page83/13.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/14.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page83/15.jpg', excerciseNum: 0 },
		],
	],
	84: [
		[{ imgUrl: 'img/FriendsPlus/Page84/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page84/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page84/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page84/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page84/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page84/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page84/12.jpg', excerciseNum: 4 }],
	],
	85: [
		[{ imgUrl: 'img/FriendsPlus/Page85/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page85/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page85/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page85/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page85/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page85/11.jpg', excerciseNum: 5 },
		],
	],
	86: [
		[{ imgUrl: 'img/FriendsPlus/Page86/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page86/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page86/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page86/7.jpg', excerciseNum: 4 },
		],
	],
	87: [
		[{ imgUrl: 'img/FriendsPlus/Page87/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page87/3.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/4.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page87/5.jpg', excerciseNum: 7 },
		],
	],
	88: [
		[{ imgUrl: 'img/FriendsPlus/Page88/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/2.jpg', excerciseNum: 8 },
			{ imgUrl: 'img/FriendsPlus/Page88/3.jpg', excerciseNum: 10 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/4.jpg', excerciseNum: 8 },
			{ imgUrl: 'img/FriendsPlus/Page88/5.jpg', excerciseNum: 11 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/6.jpg', excerciseNum: 9 },
			{ imgUrl: 'img/FriendsPlus/Page88/7.jpg', excerciseNum: 11 },
		],
	],
	89: [
		[{ imgUrl: 'img/FriendsPlus/Page89/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/2.jpg', excerciseNum: 12 },
			{ imgUrl: 'img/FriendsPlus/Page89/3.jpg', excerciseNum: 14 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/4.jpg', excerciseNum: 13 },
			{ imgUrl: 'img/FriendsPlus/Page89/5.jpg', excerciseNum: 14 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/6.jpg', excerciseNum: 13 },
			{ imgUrl: 'img/FriendsPlus/Page89/7.jpg', excerciseNum: 0 },
		],
	],
	90: [
		[{ imgUrl: 'img/FriendsPlus/Page90/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page90/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page90/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page90/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page90/9.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page90/10.jpg', excerciseNum: 0 }],
	],
	91: [
		[{ imgUrl: 'img/FriendsPlus/Page91/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page91/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page91/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page91/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page91/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page91/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page91/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page91/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page91/9.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page91/10.jpg', excerciseNum: 0 }],
	],
	92: [
		[{ imgUrl: 'img/FriendsPlus/Page92/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page92/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page92/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page92/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page92/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page92/11.jpg', excerciseNum: 5 },
		],
	],
	93: [
		[{ imgUrl: 'img/FriendsPlus/Page93/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page93/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page93/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page93/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page93/9.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page93/10.jpg', excerciseNum: 0 }],
	],
	94: [
		[{ imgUrl: 'img/FriendsPlus/Page94/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page94/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page94/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page94/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page94/9.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page94/10.jpg', excerciseNum: 0 }],
	],
	95: [
		[{ imgUrl: 'img/FriendsPlus/Page95/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page95/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page95/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page95/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page95/9.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page95/10.jpg', excerciseNum: 0 }],
	],
	96: [
		[{ imgUrl: 'img/FriendsPlus/Page96/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page96/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page96/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page96/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page96/9.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page96/10.jpg', excerciseNum: 0 }],
	],
	97: [
		[{ imgUrl: 'img/FriendsPlus/Page97/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page97/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page97/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page97/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page97/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page97/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page97/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page97/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page97/9.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page97/10.jpg', excerciseNum: 0 }],
	],
	98: [[{ imgUrl: 'img/FriendsPlus/ListPage/98.jpg', excerciseNum: 0 }]],
	99: [[{ imgUrl: 'img/FriendsPlus/ListPage/99.jpg', excerciseNum: 0 }]],
	100: [[{ imgUrl: 'img/FriendsPlus/ListPage/100.jpg', excerciseNum: 0 }]],
	101: [[{ imgUrl: 'img/FriendsPlus/ListPage/101.jpg', excerciseNum: 0 }]],
	102: [[{ imgUrl: 'img/FriendsPlus/ListPage/102.jpg', excerciseNum: 0 }]],
	103: [[{ imgUrl: 'img/FriendsPlus/ListPage/103.jpg', excerciseNum: 0 }]],
	104: [[{ imgUrl: 'img/FriendsPlus/ListPage/104.jpg', excerciseNum: 0 }]],
	105: [[{ imgUrl: 'img/FriendsPlus/ListPage/105.jpg', excerciseNum: 0 }]],
	106: [[{ imgUrl: 'img/FriendsPlus/ListPage/106.jpg', excerciseNum: 0 }]],
	107: [[{ imgUrl: 'img/FriendsPlus/ListPage/107.jpg', excerciseNum: 0 }]],
	108: [[{ imgUrl: 'img/FriendsPlus/ListPage/108.jpg', excerciseNum: 0 }]],
	109: [[{ imgUrl: 'img/FriendsPlus/ListPage/109.jpg', excerciseNum: 0 }]],
	110: [[{ imgUrl: 'img/FriendsPlus/ListPage/110.jpg', excerciseNum: 0 }]],
	111: [[{ imgUrl: 'img/FriendsPlus/ListPage/111.jpg', excerciseNum: 0 }]],
	112: [[{ imgUrl: 'img/FriendsPlus/ListPage/112.jpg', excerciseNum: 0 }]],
	113: [[{ imgUrl: 'img/FriendsPlus/ListPage/113.jpg', excerciseNum: 0 }]],
	114: [[{ imgUrl: 'img/FriendsPlus/ListPage/113.jpg', excerciseNum: 0 }]],
	115: [[{ imgUrl: 'img/FriendsPlus/ListPage/115.jpg', excerciseNum: 0 }]],
	116: [[{ imgUrl: 'img/FriendsPlus/ListPage/116.jpg', excerciseNum: 0 }]],
	117: [[{ imgUrl: 'img/FriendsPlus/ListPage/117.jpg', excerciseNum: 0 }]],
}
