import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P19-E4",
    audio: "Audios/1.14.mp3",
    video: "https://cdn.sachso.edu.vn/videos/8sb/Unit 2.mp4",
    exerciseKey: "img/FriendsPlus/Page19/E4/Key/answerKey.png",
    component: T6v2,
    inputSize: 50,
    maxLength: 1,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					<videoimage></videoimage><headphone name='1.14'></headphone >
					Watch or listen. What is each person talking about? Match speakers: Alicia, Emma, Will, Paul and Zara with Speakers A–E.
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='margin-top: 20px; display: flex;'>
						<div style='flex: 1;'><img src='img/FriendsPlus/Page19/E4/1.jpg' width='80%' /></div>
						<div style='flex: 1;'>
							<div style='display: flex; gap: 20px; flex-direction: column;
								border: 2px solid rgb(0, 104, 180); width: fit-content; padding: 20px; border-radius: 10px;'>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Speaker 1(Alicia)</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Speaker 2(Emma)</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Speaker 3(Will)</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Speaker 4(Paul)</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Speaker 5(Zara)</span>
									<div>#</div>
								</div>
							</div>
						</div>
					</div>
				`,
        answer: ["B", "C", "D", "E", "A"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P19-E5",
    audio: "",
    video: "",
    component: T6v2,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					Work in groups. Ask and answer the questions. Use some of the key phrases.
				`,
        color: "rgb(0, 104, 180)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px;'>
						<div><img src='img/FriendsPlus/Page19/E5/1.jpg' width='100%' /></div>
						<div><img src='img/FriendsPlus/Page19/E5/2.jpg' width='100%' /></div>
					</div>
				`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P19-E6",
    audio: "",
    video: "",
    component: T6,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: `
    // 			Work in groups. Ask and answer the questions. Use some of the key phrases.
    // 		`,
    //     color: "rgb(0, 104, 180)",
    //     prefix: { icons: ["far fa-comment"], text: "USE IT!" },
    //   },
    // ],
    finished: { text: "Think of your typical day. What do you see, smell, taste and feel after you wake up and efore you go to school?" },
    questionImage: [],
    textareaStyle: {
      width: "600px"
    },
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       
        <div >
        <finished></finished>
      </div>
       

          <div> <textarea id="0" rows="5"></textarea></div>
          
       
				`,
        answer: [],
      },
    ],
  },
};

export default json;
