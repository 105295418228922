import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P65-E1',
    audio: '',
    hideBtnFooter: true,
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    textareaStyle: { width: '730px' },
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      background: 'transparent',
      border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '1',

        title: `
					
			Study these ways of learning. Which ones
			help you to learn? Why?
											`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
				<div class="container">
			
					<div style="border: 2px solid rgb(78,187,122); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">doing things I enjoy</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">drawing pictures</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">listening to teachers</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">making notes</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">moving around</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">reading on my own</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">talking to my classmates</p>
					</div>
					
				</div>
						
											`,
        answer: [
          'matter',
          'upset',
          'difficult',
          'anyone',
          'worse',
          'late',
          'right',
          "Neil thinks he isn't going to pass his engineering course",
          'Daisy says he should talk to someone about it',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P65-E2',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page65/key/E2answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 900,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '2',

        title: `
					
		Read the essay. What is the writer’s
		conclusion about school?
											`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top: 30px"><img src="img/FriendsPlus/Page65/12.jpg" width="1200px" /></div>		
		<div style="display: flex;flex-wrap: wrap;gap:10px;align-items:center">
			<h1 style="margin-top: 30px">Answers</h1>
			<span style="margin-top: 20px">#</span>
		</div>		
						
											`,
        answer: [
          'Students learn many things and in many ways at school, and it is the best place to learn.',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P65-E3',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page65/key/E3answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '3',

        title: `
					
		Which key phrases can you find in the essay?
											`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 100px"><img src="img/FriendsPlus/Page65/13.jpg"/></div>	
		<div style="display: flex;flex-wrap: wrap;gap:10px;align-items:center">
			<h1 style="margin-top: 30px">Answers:</h1>
			<span style="margin-right: 10px;margin-top:20px">#,</span>
			<span style="margin-right: 10px;margin-top:20px">#,</span>
			<span style="margin-right: 10px;margin-top:20px">#</span>
		
		</div>
	
						
											`,
        answer: ['All in all', 'In my opinion', 'To conclude'],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P65-E4',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page65/key/E4answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 290,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '4',

        title: `
					
		Study the words in <span style="color:rgb(28,190,215) ;">blue</span> in the text. Which word can <i>lastly</i> replace?

											`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div><img src="img/FriendsPlus/Page65/12.jpg" width="1200px" /></div>
		<div style="display: flex;flex-wrap: wrap;gap:10px">
			<h1 style="margin-top: 30px">Answer:</h1>
			<span style="margin-right: 10px;margin-top:30px">#</span>
		</div>

	
						
											`,
        answer: ["'Lastly' can replace 'Finally'"],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P65-E5',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    hideBtnFooter: true,
    // maxLength: 6,
    inputSize: 290,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    textareaStyle: { width: 700 },
    titleQuestion: [
      {
        num: '5',

        title: `
					
		Write three sentences on school that express a different opinion from
		those in the model essay. Use the ordering words.
											`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
     <div style='display:flex;justify-content:space-between'>
     
		<p style="font-size: 25px;">
			Firstly, …
      	</p>

		<p style="font-size: 25px;">
			Secondly, …
      	</p>

		<p style="font-size: 25px;">
			Lastly, / Finally, …
      	</p>
    </div>      
        <textarea id='0' rows='4'></textarea>
	
						
											`,
        answer: ["'Lastly' can replace 'Finally'"],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P65-E6',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '6',
        title: `
        Follow the steps in the Writing Guide.

        `,
        color: 'rgb(78,187,122)',
        prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="margin-left: 100px"><img src="img/FriendsPlus/Page65/14.jpg" /></div>
        

        `,
        answer: [],
      },
    ],
  },
};

export default json;
