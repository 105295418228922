import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P72-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page72/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 450,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
			Read the Study Strategy below. Then scan the online article above to find out what jobs
			Daniel Black, Hoàng Tuấn Anh and Glen James had.
	
			`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style="margin-left: 250px; margin-top:30px"><img src="img/FriendsPlus/Page72/8.jpg"></div>
			<div style="margin-top:30px"><img src="img/FriendsPlus/Page72/9.jpg" width="1200px"></div>
			<h1 style="margin-top: 30px">Answers</h1>

			<div style="display: flex; flex-wrap: wrap;margin-top:30px; font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">Daniel Black:</span>
				<span>#</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;margin-top:30px; font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">Hoàng Tuấn Anh:</span>
				<span>#</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;margin-top:30px; font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">Glen James:</span>
				<span>#</span>
			</div>
	
			`,
        answer: [
          "Daniel Black was a musician.",
          "Hoàng Tuấn Anh was an entrepreneur.",
          "Glen James was an office worker.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P72-E2",
    audio: "Audios/2.22.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page72/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 900,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		<headphone name="2.22"></headphone >
		Read and listen to the online article again. Why are these numbers mentioned?
	
			`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style="display: flex; flex-wrap: wrap;margin-top:30px;gap:60px;margin-left:350px; font-size: 25px;">
				<div>
					<span style="font-weight: bold;margin-right: 10px;">1</span>
					<span>£22,000</span>
			  	</div>

				<div>
					<span style="font-weight: bold;margin-right: 10px;">2</span>
					<span>$42,000</span>
			  	</div>

				<div>
					<span style="font-weight: bold;margin-right: 10px;">3</span>
					<span>1.5 kg</span>
			  	</div>
			</div>
			<div style="margin-top:30px"><img src="img/FriendsPlus/Page72/9.jpg" width="1200px"></div>
			<h1 style="margin-top: 30px">Answers</h1>

			<div style="display: flex; flex-wrap: wrap; gap: 30px;font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">1</span>
				<p style="font-size: 25px;">#</p>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 30px;font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">2</span>
				<p style="font-size: 25px;">#</p>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 30px;font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">3</span>
				<p style="font-size: 25px;">#</p>
			</div>

			
	
			`,
        answer: [
          "£22,000 - the amount of money saved by Daniel Black",
          "$42,000 - the amount of money Glen James found",
          "1.5 kg - the amount of rice distributed each time from the rice dispensing machine",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P72-E3",
    audio: "Audios/2.22.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page72/key/E3answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px", fontWeight: "bold" },
    // maxLength: 1,
    inputSize: 600,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		<headphone name="2.22"></headphone >
		Read and listen to the article again. Write <i style="font-weight: bold">true</i> or <i style="font-weight: bold">false</i>. Correct the false sentences

	
			`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
			<div style="margin-top:30px"><img src="img/FriendsPlus/Page72/9.jpg" width="1200px"></div>
			<h1 style="margin-top: 30px">Answers</h1>

			<div>
				<span style="font-weight: bold;margin-right: 10px;">1</span>
				<span>The writer doesn’t believe that there are advantages to being kind.</span>
				<div><textarea id="0" rows="3"></textarea></div>
			</div>

			<div>
				<span style="font-weight: bold;margin-right: 10px;">2</span>
				<span>Daniel Black and the boy both got the medical treatment they needed.</span>
				<div><textarea id="1" rows="3"></textarea></div>
			</div>

			<div>
				<span style="font-weight: bold;margin-right: 10px;">3</span>
				<span>Receiving money changed Glen James’s life.</span>
				<div><textarea id="2" rows="3"></textarea></div>
			</div>
			<div>
				<span style="font-weight: bold;margin-right: 10px;">4</span>
				<span>The rice ATMs distributed an unlimited amount of rice each time.</span>
				<div><textarea id="3" rows="3"></textarea></div>
			</div>
			
	
			`,
        answer: [
          "false (Sometimes a spontaneous act of kindness can change another person's life.)",
          "false (Daniel gave all the money he had saved for his own surgery to the boy so that the boy could afford to have surgery.)",
          "true",
          "false (The rice ATM distributed a 1.5 kg bagful of rice each time.)",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P72-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    component: T6v2,
    hideBtnFooter: true,
    // maxLength: 1,
    inputSize: 600,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		<span style="color: rgb(220,44,55)">VOCABULARY PLUS</span> Use a dictionary to check the meaning of the nouns in <span style="color: rgb(28, 190, 215)">blue</span> in the text. What is the verb form of each one?
	
			`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
			<div style="margin-top:30px"><img src="img/FriendsPlus/Page72/9.jpg" width="1200px"></div>
			`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P72-E5",
    audio: "",
    video: "",
    // recorder: true,
    hideBtnFooter: true,
    // exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 600,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Work in pairs. Which story did you like best? Why?
			`,
        color: "rgb(220,44,55)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
			<div style="margin-top:30px"><img src="img/FriendsPlus/Page72/9.jpg" width="1200px"></div>
			`,
        answer: [],
      },
    ],
  },
};

export default json;
