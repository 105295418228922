import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P43-E1",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page43/key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
			<span>Underline the question tags in sentences 1-5 and the verbs they refer to.</span> 
					`,
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          //   borderRadius: '50%',
        },
        selectWordStyle: { borderBottom: "2px solid rgb(0, 173, 238)" },
        limitSelect: 2,
        listWords: [
          `That sounds a bit hi-tech, doesn'_it ?`, // 0
          `So, children can use them for several years, can't_they ?`, // 1
          `One shoe won't_fit everyone, will_it ?`, // 2
          `That'|s really useful, isn't_it ?`, // 3
          `People are wearing them in … , aren't_they?`, // 4,
        ],
        answers: [
          "0-2",
          "0-10",
          "1-4",
          "1-16",
          "2-4",
          "2-8",
          "3-2",
          "3-8",
          "4-2",
          "4-14",
        ],
        initialValue: {},
      },
      Layout: `
			<div class="container">
			  	<div style='width: 1000px; margin-right:30px'>
					<span style="font-weight: bold;">1 </span>
					<input id='0' type='Circle' style="letter-spacing: 10px;"/>
				</div>

				<div style='width: 1000px; margin-right:30px'>
					<span style="font-weight: bold;">2 </span>
					<input id='1' type='Circle' style="letter-spacing: 10px;"/>
				</div>

				<div style='width: 1000px; margin-right:30px'>
					<span style="font-weight: bold;">3 </span>
					<input id='2' type='Circle' style="letter-spacing: 10px;"/>
				</div>

				<div style='width: 1000px; margin-right:30px'>
					<span style="font-weight: bold;">4 </span>
					<input id='3' type='Circle' style="letter-spacing: 10px;"/>
				</div>

				<div style='width: 1000px; margin-right:30px'>
					<span style="font-weight: bold;">5 </span>
					<input id='4' type='Circle' style="letter-spacing: 10px;"/>
				</div>
			  
		
			</div>
			
				`,
    },
  },
  2: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P43-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page43/key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
		Read the sentences in exercise 1. Then choose the correct options to complete rules 1-5.
					`,
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `positive / negative`, // 0
          `positive / negative`, // 1
          `same / different`, // 2
          `do / will`, // 3
          `is / isn't`, // 4,
        ],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-0"],
        initialValue: {},
      },
      Layout: `
	  <div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px">
		<div
			style="
			background-color: rgb(28, 190, 215);
			border-radius: 40px;
			margin: 10px 10px;
			"
		>
			<h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
		</div>


		<div style="margin-top: 10px; margin: 10px 10px">
			<p style="font-size: 25px">
			We use question tags to check information. In the question tag, we
			use:
			</p>
		</div>

	  	<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">1 </span>
			a <input id='0' type='Circle' style="letter-spacing: 10px;"/> verb for negative sentences.
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">2 </span>
			a <input id='1' type='Circle' style="letter-spacing: 10px;"/> verb for affirmative  sentences
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">3 </span>
			the <input id='2' type='Circle' style="letter-spacing: 10px;"/> auxiliary verb as in the sentence.
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">4 </span>
			a form of <input id='3' type='Circle' style="letter-spacing: 10px;"/> when there is no auxiliary verb in the sentence
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">5 </span>
			modal verbs when there <input id='4' type='Circle' style="letter-spacing: 10px;"/> a modal in  the statement.
		</div>
      
    </div>
			
				`,
    },
  },
  3: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P43-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page43/key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
		Choose the correct options.
					`,
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          "do / don't",
          "can / can't",
          "doesn't / isn't",
          "will / won't",
          "am / aren't",
          "can't / don't",
        ],
        answers: ["0-4", "1-4", "2-4", "3-0", "4-4", "5-0"],
        initialValue: {},
      },
      Layout: `
	  	<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">1 </span>
			The bottles go in the recycling bin, <input id='0' type='Circle' style="letter-spacing: 10px;"/> they?
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">2 </span>
			You can help us, <input id='1' type='Circle' style="letter-spacing: 10px;"/> you? 
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">3 </span>
			Pollution is a huge problem, <input id='2' type='Circle' style="letter-spacing: 10px;"/> it? 
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">4 </span>
			They won't pollute the air, <input id='3' type='Circle' style="letter-spacing: 10px;"/> they?  
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">5 </span>
			I'm right, <input id='4' type='Circle' style="letter-spacing: 10px;"/> I?  
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">6 </span>
			We can reduce waste, <input id='5' type='Circle' style="letter-spacing: 10px;"/> we?  
		</div>
			
				`,
    },
  },
  4: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P43-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page43/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Complete the sentences with the phrases in the box.
				`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div class="container">
			<div style="border: 2px solid rgb(28,190,215); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 10px; border-radius: 40px;">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">aren't they</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">can't you</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">does it</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">shouldn't we</p>
				<p style="font-size: 25px; margin-right: 20px; font-weight:bold">won't it</p>
			</div>
			<p style="font-size: 25px;margin-top:20px"><span style="font-weight: bold; ">1</span> We should exchange books with each other,# ?</p>

			<p style="font-size: 25px;margin-top:20px"><span style="font-weight: bold; ">2</span> They're using too much water,#?</p>

			<p style="font-size: 25px;margin-top:20px"><span style="font-weight: bold; ">3</span> The company doesn't waste water,#?</p>

			<p style="font-size: 25px;margin-top:20px"><span style="font-weight: bold; ">4</span> You can recycle this phone,#?</p>

			<p style="font-size: 25px;margin-top:20px"><span style="font-weight: bold; ">5</span> The population of the city will increase in the next ten years,#?</p>
			
			
		</div>
				`,
        answer: [
          "shouldn't we",
          "aren't they",
          "does it",
          "can't you",
          "won't it",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P43-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page43/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Complete the dialogue with the correct question tag.
				`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div class="container">
			<div style="display: flex;">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Megan</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px; ">That's not another new bag, <sup>1</sup> # ?</p>
				</div>
			</div>

			<div style="display: flex">
				<div class="col-2" style=" margin-right:-60px">
				<p style="font-size: 25px; font-weight: bold">Alex</p>
				</div>
				<div class="col-8">
				<p style="font-size: 25px; ">It looks great, <sup>2</sup> # ?</p>
				</div>
			</div>

			<div style="display: flex">
				<div class="col-2" style=" margin-right:-60px">
				<p style="font-size: 25px; font-weight: bold">Megan</p>
				</div>
				<div class="col-8">
				<p style="font-size: 25px; ">Yes, but those aren't sweet packets, <sup>3</sup> # ?</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=" margin-right:-60px">
				<p style="font-size: 25px; font-weight: bold">Alex</p>
				</div>
				<div class="col-8">
							<p style="font-size: 25px; ">They are! The whole bag is made
					with recycled packets. It's really clever, <sup>4</sup> # ?</p>
					
				</div>
			</div>

			<div style="display: flex">
				<div class="col-2" style=" margin-right:-60px">
				<p style="font-size: 25px; font-weight: bold">Megan</p>
				</div>
				<div class="col-8">
				<p style="font-size: 25px"> Yeah. I want one, too!</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=" margin-right:-60px">
				<p style="font-size: 25px; font-weight: bold">Alex</p>
				</div>
				<div class="col-8">
				<p style="font-size: 25px"> I'll get you one for your birthday.</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=" margin-right:-60px">
				<p style="font-size: 25px; font-weight: bold">Megan</p>
				</div>
				<div class="col-8">
				<p style="font-size: 25px"> Really? You won't forget, <sup>5</sup>#? </p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=" margin-right:-60px">
				<p style="font-size: 25px; font-weight: bold">Alex</p>
				</div>
				<div class="col-8">
				<p style="font-size: 25px"> Don't worry. I won't.</p>
				</div>
			</div>

		</div>
				`,
        answer: ["is it", "doesn't it", "are they", "isn't it", "will you"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P43-E6",
    audio: "Audios/1.35.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page43/key/E6answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",

        title: `
		<headphone name="1.35"></headphone >
        <span style="color:rgb(28,190,215);">PRONUNCIATION: Intonation</span> Listen to these sentences. Does the speaker's voice
		go up or down in the question tag? Practise saying them.
				`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<p style="font-size: 25px; margin-top:20px"><span style="font-weight: bold;">1</span> The bag wasn't expensive, was it?#</p>
		<p style="font-size: 25px;"><span style="font-weight: bold;">2</span> They can buy them online, can't they?#</p>
		<p style="font-size: 25px;"><span style="font-weight: bold;">3</span> You'll call me, won't you?#</p>

		
				`,
        answer: ["down", "down", "up"],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P43-E7",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
        Work in pairs. Check personal information using question tags and the ideas in the box.
		`,
        color: "rgb(28,190,215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<div class="container">
			<div style="border: 2px solid rgb(28,190,215); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 10px; border-radius: 40px;">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">abilities</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">birthday</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">family</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">last weekend</p>
				<p style="font-size: 25px; margin-right: 20px; font-weight:bold">likes / dislikes</p>
				<p style="font-size: 25px; margin-right: 20px; font-weight:bold">next holiday</p>
			</div>
			
		</div>


        <div style="margin-top: 20px;"><img src="img/FriendsPlus/Page43/10.jpg"/></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
