import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P78-E1",
    audio: "Audios/2.28.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page78/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 180,
    isRequired: false,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      //   fontWeight: 'bold',
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				<headphone name="2.28"></headphone >
				Study the words in the box. They describe books, films, or both. Complete the table with the
				words. Listen and check

								`,
        color: "rgb(71,140,202)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `

			<div style="border: 2px solid rgb(71,140,202); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;width:1200px">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">action</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">adventure</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">animation</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">comedy</p>
	
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">crime</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">drama</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">fantasy</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">graphic novel</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">horror</p>

				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">manga</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">musical</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">mystery</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">romance</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">science fiction</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">thriller</p>
			</div>
			
			<table style="border: 1px solid rgb(71,140,202);margin-top:30px;margin-left:250px">
				<thead style="text-align:center">
					<th style="border: 1px solid rgb(71,140,202);">Films</th>
					<th style="border: 1px solid rgb(71,140,202);">Books</th>
					<th style="border: 1px solid rgb(71,140,202);padding:0 20px">Books and films</th>
				</thead>
				<tbody>
					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					<td style="border: 1px solid rgb(71,140,202);">#</td>
					</tr>
					
				</tbody>
			</table>
	
			
			
								`,
        answer: [
          "action",
          "graphic novel",
          "action",
          "animation",
          "manga",
          "adventure",
          "musical",
          "mystery",
          "comedy",
          "",
          "",
          "crime",
          "",
          "",
          "drama",
          "",
          "",
          "fantasy",
          "",
          "",
          "horror",
          "",
          "",
          "romance",
          "",
          "",
          "science fiction",
          "",
          "",
          "thriller",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P78-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page78/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 220,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		Look at film photos A–F. Choose the correct words in exercise 1  to describe each film.

								`,
        color: "rgb(71,140,202)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex; flex-wrap: wrap; gap: 20px;margin-top:30px">

		<div>
			<img src="img/FriendsPlus/Page78/9.jpg" width="500px"/>
		</div>

		<div>
			<img src="img/FriendsPlus/Page78/10.jpg" width="500px"/>
		</div>

		<div>
			<img src="img/FriendsPlus/Page78/11.jpg" width="500px"/>
		</div>

		<div>
			<img src="img/FriendsPlus/Page78/12.jpg" width="500px"/>
		</div>

		<div>
			<img src="img/FriendsPlus/Page78/13.jpg" width="500px"/>
		</div>

		<div>
			<img src="img/FriendsPlus/Page78/14.jpg" width="500px"/>
		</div>

	  </div>
	  <h1 style="margin-top: 30px">Answers</h1>
	  	<div style="display: flex; flex-wrap: wrap;gap:20px">
		  	<div style="margin-top:30px;">
				<span style="font-weight: bold; margin-right: 10px;">A</span>
				<span>#</span>
			</div>

			<div style="margin-top:30px;">
				<span style="font-weight: bold; margin-right: 10px;">B</span>
				<span>#</span>
			</div>

			<div style="margin-top:30px;">
				<span style="font-weight: bold; margin-right: 10px;">C</span>
				<span>#</span>
			</div>

			<div style="margin-top:30px;">
				<span style="font-weight: bold; margin-right: 10px;">D</span>
				<span>#</span>
			</div>

			<div style="margin-top:30px;">
				<span style="font-weight: bold; margin-right: 10px;">E</span>
				<span>#</span>
			</div>

			<div style="margin-top:30px;">
				<span style="font-weight: bold; margin-right: 10px;">F</span>
				<span>#</span>
			</div>

    	</div>
		
			
	
			
			
								`,
        answer: [
          "fantasy, adventure",
          "horror",
          "romance",
          "comedy",
          "musical",
          "action",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P78-E3",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 220,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		Do the quiz. Then compare your answers with a partner's. Do you agree with the result?
								`,
        color: "rgb(71,140,202)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
								`,
        answer: [],
      },
    ],
  },
};

export default json;
