import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P54-E1",
    audio: "Audios/2.06.mp3",

    video: "",
    // recorder: true,
    // exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 210,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "1",

        title: `
			<headphone name="2.06"></headphone >
			Read and listen to the online forum. Check the meaning of the phrases in <span style="color: rgb(28,190,215);">blue</span>. Which do you think are good ideas for a healthy life?
										`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div><img src="img/FriendsPlus/Page54/12.jpg" width="1200px" /></div>
					
										`,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P54-E2",
    audio: "",
    hideBtnFooter: true,
    video: "",
    // recorder: true,
    // exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 210,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		Read the Study Strategy. Complete the sentences with your own ideas. Compare
		your ideas with a partner's and try to remember the best sentences.
										`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px;"><img src="img/FriendsPlus/Page54/13.jpg" /></div>


		<p style="font-size: 25px;margin-top:10px">
        <span style="font-weight: bold;">1</span> 
			  I enjoy myself when I...............................
    </p>

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">2</span> 
			When I’m older, I’m going to travel around...............................
			and other countries.
        </p>

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">3</span> 
			I want to take up a sport like...............................
        </p>

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">4</span> 
			Don’t waste time...............................
        </p>

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">5</span> 
			To look after yourself, you should...............................
        </p>
					
										`,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P54-E3",
    audio: "Audios/2.07.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 390,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		<headphone name="2.07"></headphone >
		Listen to the four people from exercise 1 talking about their future plans and lifestyle
		choices. Which order do they speak in?

										`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<h1 style="margin: 20px 0">Answers:</h1>
			<p style="font-size: 25px;margin-top:20px">#</p>
		</div>
										`,
        answer: ["DeeLee33, Marcus, Earlybird, Decklen"],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P54-E4",
    audio: "Audios/2.07.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 5,
    inputSize: 850,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "18px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
		<headphone name="2.07"></headphone >
		Listen again and write true or false. Correct the false sentences.

										`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<p style="font-size: 20px;margin-top:10px">
            <span style="font-weight: bold;">1</span> 
			Speaker 1 thinks that having money is the most important thing.<br/>#
        </p>

		<p style="font-size: 20px;margin-top:10px">
            <span style="font-weight: bold;">2</span> 
			Speaker 2 has been to university.<br/> #
        </p>

		<p style="font-size: 20px;margin-top:10px">
            <span style="font-weight: bold;">3</span> 
			Speaker 3 is ambitious.<br/>#
        </p>

		<p style="font-size: 20px;margin-top:10px">
            <span style="font-weight: bold;">4</span> 
			Speaker 4 thinks education is important.<br/>#
        </p>
										`,
        answer: [
          "false (Speaker 1 thinks that life is about enjoying yourself.)",
          "false (Speaker 2 hasn't been to university, but he is going to take some time out before he goes.)",
          "true",
          "true",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P54-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 5,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
		Find five sentences with reflexive pronouns in the online forum. Then complete
		sentences 1–3 with the reflexive pronouns in the box. There is one extra pronoun.
										`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div><img src="img/FriendsPlus/Page54/12.jpg" width="1200px" /></div>

		<div style="border: 2px solid rgb(248,129,37); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px; width:800px; margin-left:200px">
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">himself</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">myself</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">ourselves</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">yourself</p>
        </div>

		<p style="font-size: 25px;margin-top:50px">
            <span style="font-weight: bold;">1</span> 
			I saw# in the mirror.
        </p>

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">2</span> 
			He sent# an email.
        </p>

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">3</span> 
			We always enjoy# on holiday.
        </p>


										`,
        answer: ["myself", "himself", "ourselves"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U5-P54-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in pairs. Tell each other about your plans and ideas for the future.
		Use the phrases in <span style="color:rgb(28,190,215);">blue</span> in the online forum to help you.
        `,
        color: "rgb(248,129,37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div><img src="img/FriendsPlus/Page54/12.jpg" width="1200px" /></div>
		<div style="margin-top: 40px"><img src="img/FriendsPlus/Page54/11.jpg" /></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
