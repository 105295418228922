import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  1: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P53-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page53/key/E1answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Match 1–3 with a–c using the article on page 52. ",
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "50px",
              left: "420px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "50px",
              left: "680px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "140px",
              left: "420px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "140px",
              left: "680px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "420px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "680px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
        ],
        answers: ["0-5", "1-2", "3-4"],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 25px;min-witdth:1000px">
					

          <div style='display: flex;padding-top: 5mm'>
            <div ><b>1</b> Unless a catastrophe destroys the Earth, </div>
			<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
			<div style='margin-left: 380px;'><b>a.</b> will people pay to have healthier children?</div>
          </div>

		  <div style='display: flex;padding-top: 5mm'>
            <div ><b>2</b> If scientists manipulate human genes</div>
			<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
			<div style='margin-left: 380px;'><b>b.</b> the human race won’t look the same</div>
          </div>

		  <div style='display: flex;padding-top: 5mm'>
            <div><b>3</b> If humans adapt to conditions on <br/> another planet,</div>
			<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
			<div style='margin-left: 380px;'><b>c.</b> we will probably survive.</div>
          </div>

			
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P53-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page53/key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
		Choose the correct options to complete the rules.
					`,
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `future / past`, // 0
          `present / past`, // 1
          `will_and_won't / do_and_doesn't`, // 2
          `if_..._not / if_..._when`, // 3
          `first / second`, // 4,
          `first / second`, // 5,
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-0", "5-4"],
        initialValue: {},
      },
      Layout: `
	  <div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px">
		<div
			style="
			background-color: rgb(28, 190, 215);
			border-radius: 40px;
			margin: 10px 10px;
			"
		>
			<h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
		</div>

	  	<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">1 </span>
			We use the first conditional to talk about a possible event and its result in the
	 		<input id='0' type='Circle' style="letter-spacing: 10px;"/>.
		</div>


		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">2 </span>
			We introduce the situation with if or unless +  
			<input id='1' type='Circle' style="letter-spacing: 10px;"/> simple.
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">3 </span>
			We describe the result with  
			<input id='2' type='Circle' style="letter-spacing: 10px;"/> + infinitive without to 
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">4 </span>
			We use unless to say
			<input id='3' type='Circle' style="letter-spacing: 10px;"/> .
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">5 </span>
			When the if clause is 
			<input id='4' type='Circle' style="letter-spacing: 10px;"/>
			, we use a comma. When the if clause comes 
			<input id='5' type='Circle' style="letter-spacing: 10px;"/> , we don’t need a comma.

			
		</div>
      
    </div>
			
				`,
    },
  },
  3: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P53-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/key/E3answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 1,
    inputSize: 210,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		Complete the sentences with the first conditional form of the verbs in brackets.
		Then rewrite sentences 4–5 using if.
									`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top: 10px;">
			<span style="font-size: 20px; font-weight: bold; ">1</span>
			<span style="font-size: 20px;">If people # (live) on other
				planets, they # (miss) the Earth.
			</span>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top: 20px;">
			<span style="font-size: 20px; font-weight: bold; ">2</span>
			<span style="font-size: 20px;">He # (get tired) if he #
			(not rest).
			</span>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top: 20px;">
			<span style="font-size: 20px; font-weight: bold; ">3</span>
			<span style="font-size: 20px;">We # (not look) the same 
				if we # (live) on Mars.
			</span>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top: 20px;">
			<span style="font-size: 20px; font-weight: bold; ">4</span>
			<span style="font-size: 20px;">
			You # (miss) the bus unless you # (get up) now.
			</span>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top: 20px;">
			<span style="font-size: 20px; font-weight: bold; ">5</span>
			<span style="font-size: 20px;">
			There # (be) a catastrophe unless we # (protect) the environment.
			</span>
		</div>

		<h2 style="margin: 20px 0"> Rewrite sentences 4–5</h2>

		<div style="display: flex; flex-wrap: wrap; gap: 10px;">
			<b style="font-size: 20px;">4</b>
			<textarea id="10" rows="2"></textarea>
	  </div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px;">
			<b style="font-size: 20px;">5</b>
			<textarea id="11" rows="2"></textarea>
	  </div>

			
				
									`,
        answer: [
          "live",
          "will miss",
          "'ll / will get tired",
          "doesn't rest",
          "will not / won't look",
          "live",
          "'ll / will miss",
          "get up",
          "'ll / will be",
          "protect",
          "You won't miss the bus if you get up now. / You will miss the bus if you don't get up now ",
          "There won't be a catastrophe if we protect the environment. / There will be a catastrophe if we don't protect the environment.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P53-E4",
    audio: "Audios/2.05.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 210,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        <headphone name="2.05"></headphone >
        <span style="color: rgb(28,190,215);">prONUNCIaTION: Sentence stress</span>
									`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <h1 style="margin: 20px 0">Listen and repeat the sentences.</h1>
        <p style="font-size: 25px;">I won’t get a job unless I study hard.</p>
        <p style="font-size: 25px;">They did not want to go dancing with us</p>
        <p style="font-size: 25px;">How much cheese should we need?</p>
				
									`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P53-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Study the examples and read the rules. Which situation does the writer think is more likely?
									`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <i style="font-weight: bold;font-size:18px">Children born now might live until they’re 150.<br/></i>
        <i style="font-weight: bold;font-size:18px">People will continue to change.</i>
      
      <div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px; margin-top:30px">
        <div
          style="
          background-color: rgb(28, 190, 215);
          border-radius: 40px;
          margin: 10px 10px;
          "
        >
          <h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
        </div>

          
      
        <div style='width: 1000px;margin: 10px 10px;'>
          We use <i style="font-weight: bold;">might</i> to make predictions when we
          aren’t certain.
        </div>

        <div style='width: 1000px;margin: 10px 10px;'>
          We use <i style="font-weight: bold;">will</i> to make predictions when we are certain.
        </div>
      
      </div>
      <div style="display: flex; flex-wrap: wrap; gap: 10px;align-items:center;margin-top:20px">
        <h1 style="margin: 20px 0">Answer:</h1>
        <p style="font-size: 25px;margin-top:15px">#</p>
      </div>
				
									`,
        answer: ["People will continue to change."],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U5-P53-E6",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page53/key/E6answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "6",
        title: `
        Choose the correct options.
					`,
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `won't / might`, // 0
          `I'll / I_might `, // 1
          `will / might`, // 2
          `I_won't / might_not`, // 3
          `will / might`, // 4,
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-4"],
        initialValue: {},
      },
      Layout: `
      <div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">1 </span>
			Lauren <input id='0' type='Circle' style="letter-spacing: 10px;"/> not get a new bank account. She's happy with the one she has  now.
		</div>


		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">2 </span>
			<input id='1' type='Circle' style="letter-spacing: 10px;"/> live to be 100 
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">3 </span>
			One thing’s for sure – people   
			<input id='2' type='Circle' style="letter-spacing: 10px;"/> continue to write about the future.
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">4 </span>
			<input id='3' type='Circle' style="letter-spacing: 10px;"/> buy that. I’m not sure.
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">5 </span>
			 Liverpool <input id='4' type='Circle' style="letter-spacing: 10px;"/> win. You never know!
		</div>
				`,
    },
  },
  7: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U5-P53-E7",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
        Work in pairs. Ask and answer the questions with your own ideas.
        `,
        color: "rgb(28,190,215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">1</span> If I tell you a secret,...............................?
        </p>

        <p style="font-size: 25px;">
            <span style="font-weight: bold;">2</span> What will happen if,...............................?
        </p>

        <p style="font-size: 25px;">
            <span style="font-weight: bold;">3</span> If you’re rich in the future, will you,...............................?
        </p>

        <p style="font-size: 25px;">
            <span style="font-weight: bold;">4</span> Will you be unhappy if...............................?
        </p>

        <p style="font-size: 25px;">
            <span style="font-weight: bold;">5</span> If there are trips to other planets, will..............................?
        </p>

        <div><img src="img/FriendsPlus/Page53/14.png" /></div>
        

        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
