import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P60-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
			Read the reviews about two unusual schools. Which school used to be single-sex? At what
			age can you attend these schools?
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						
			<div style="margin-top:10px;"><img src="img/FriendsPlus/Page60/6.jpg" width:"600px";/></div>
			<h1 style="margin-top: 30px">Answers</h1>
			<div><textarea id="0" rows="2"></textarea></div>
			<div><textarea id="1" rows="2"></textarea></div>
				
									`,
        answer: [
          "The River Plate School used to be single-sex.",
          "You can attend the BRIT school at age fourteen; the River Plate School from five years old until university age.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P60-E2",
    audio: "Audios/2.12.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E2answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		<headphone name="2.12"></headphone >
		Read and listen to the reviews again. Complete the sentences using information
		from the reviews in your own words.

								`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:10px;"><img src="img/FriendsPlus/Page60/6.jpg" width:"600px";/></div>
					
        <p style="font-size: 25px; margin-top: 30px; ">
          <span style="font-weight: bold; margin-right: 10px;">1</span>
          <span>BRIT School students go to lessons in</span>
		  <div><textarea id="0" rows="3"></textarea></div> 
		</p>

        <p style="font-size: 25px; margin-top: 30px; ">
          <span style="font-weight: bold; margin-right: 10px;">2</span>
          <span>As well as doing exams, BRIT School students also</span>
		  <div><textarea id="1" rows="3"></textarea></div>
        </p>

        <p style="font-size: 25px; margin-top: 30px; ">
          <span style="font-weight: bold; margin-right: 10px;">3</span>
          <span>River Plate School is unusual because </span>
          <div><textarea id="2" rows="3"></textarea></div>
        </p>

        <p style="font-size: 25px; margin-top: 30px; ">
          <span style="font-weight: bold; margin-right: 10px;">4</span>
          <span>Girls couldn’t attend River Plate School </span>
          <div><textarea id="3" rows="3"></textarea></div>
        </p>
        
			
								`,
        answer: [
          "technology and performing arts like dance, singing, music and drama, plus English, maths, science and foreign languages",
          "take part in performances and exhibitions",
          "it's the only sports institution in the world that has its own kindergarten - with students as young as five years old - school, college and university",
          "because people believed that girls couldn't play football as well as boys",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P60-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,

    // maxLength: 6,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		<span style="color: rgb(220,44,55);">VOCABULARY PLUs</span>
		Use a dictionary to check the meaning of the words in <span style="color: rgb(28,190,215);">blue</span> in the texts.
								`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:10px;"><img src="img/FriendsPlus/Page60/6.jpg" style='height:20cm'";/></div>
					
        
			
								`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FP8-SB-demo-2023-U6-P60-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in pairs. Make a list of the advantages and disadvantages of each school. Which school would you prefer to go to?
        `,
        color: "rgb(220,44,55)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		      <div style="margin-top:40px;margin-left:100px"><img src="img/FriendsPlus/Page60/7.jpg"/></div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
