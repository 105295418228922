import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import MatchDots from '../../components/ExcerciseTypes/TypeIn/MatchDots';

const json = {
  1: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P57-E1',
    audio: '',

    video: '',
    recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    hideBtnFooter: true,
    inputSize: 280,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '1',

        title: `
			Read the blog. Do you want to live on another planet when the Earth is too crowded?
												`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div><img src="img/FriendsPlus/Page57/10.jpg"  style='height:18cm'/></div>
			
						
												`,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P57-E2',
    audio: '',
    hideBtnFooter: true,
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 410,
    stylesTextInput: {
      background: 'none',
      borderBottom: 'none',
      textAlign: 'center',
      fontSize: '23px',
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '2',

        title: `
		Complete the key phrases with words from the blog.
												`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
    <div style='position: relative;'>

      <img src="img/FriendsPlus/Page57/11.jpg" />
      <div style=" position: absolute; top: 100px; left: 207px; ">#</div>
      <div style=" position: absolute; top: 134px; left: 196px; "><input id='1' width='423px'></input></div>
      <div style=" position: absolute; top: 167px; left: 100px; "><input id='2' width='520px'></input></div>
      <div style=" position: absolute; top: 200px; left: 195px; "><input id='3' width='425px'></input></div>
      
    </div>
      
			
						
												`,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P57-E3',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page57/key/E3answerKey.png',
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '3',

        title: `
		Look at the words in <span style="color: rgb(28,190,215);">blue</span>. What kind of verb does the writer use to express purposes?
 
												`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div><img src="img/FriendsPlus/Page57/10.jpg" /></div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">
			<h1 >Answer</h1>
			<p style="font-size: 25px">#</p>
		</div>
			
						
												`,
        answer: ['verb follows by to-infinitive'],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P57-E4',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page57/key/E4answerKey.png',
    stylesTextInput: { borderBottom: 'dotted' },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: '4',
        title:
          'Study the examples. Then match 1–4 with a–d and write the sentences, using to. ',

        color: 'rgb(78,187,122)',
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 0
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '630px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '630px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '630px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 6
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '630px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 7
        ],
        answers: ['0-5', '2-7', '1-4', '3-6'],
        initialValue: [],
      },
      Layout: `
	  <div class="container">
	  <div style="min-width:1200px" >
	  <div style="margin-top:40px"></div>
   
  
				<div style="display: flex; justify-content:justify-content: space-between; gap: 10px;font-size: 25px;margin-bottom:30px;">
					<div class="col-5" style="margin-right:150px" >
						<div><b>1</b> I called my mom</div>
						<div><input id="0" type="boxMatch" /></div>
						<div><input id="1" type="boxMatch" /></div>
					</div>
					<div class="col-5">
						<b>a.</b> decorate their classroom
					</div>

				</div>

				<div style="display: flex; flex-wrap: wrap;justify-content:justify-content: space-between; gap: 10px;font-size: 25px;margin-bottom:30px">
					<div class="col-5" style="margin-right:150px">
						<div><b>2</b> They will go to a restaurant</div>
						<div><input id="2" type="boxMatch" /></div>
						<div><input id="3" type="boxMatch" /></div>
					</div>
					<div class="col-5">
						<b>b.</b> help people observe the sky
					</div>
				</div>

				<div style="display: flex; justify-content:justify-content: space-between; gap: 10px;font-size: 25px;margin-bottom:30px">
					<div class="col-5" style="margin-right:150px">
						<div><b>3</b> The students need more time</div>
						<div><input id="4" type="boxMatch" /></div>
						<div><input id="5" type="boxMatch" /></div>
					</div>
					<div class="col-5">
						<b>c.</b> tell her the truth.
					</div>
				</div>

				<div style="display: flex; justify-content:justify-content: space-between; gap: 10px;font-size: 25px;margin-bottom:30px">
					<div class="col-5" style="margin-right:150px">
						<div><b>4</b> Telescopes were invented</div>
						<div><input id="6" type="boxMatch" /></div>
						<div><input id="7" type="boxMatch" /></div>
					</div>
					<div class="col-5">
						<b>d.</b> have dinner with their friends.
					</div>
				</div>

				










			</div>	
		</div>
      `,
    },
  },
  5: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P57-E5',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '5',

        title: `
		Follow the steps in the Writing Guide.
		`,
        color: 'rgb(78,187,122)',
        prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div><img src="img/FriendsPlus/Page57/12.jpg" /></div>
			
						
												`,
        answer: [],
      },
    ],
  },
};

export default json;
