import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P77-E1',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page77/key/E1answerKey.png',
    component: T6v2,
    // maxLength: 1,
    inputSize: 930,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '1',
        title: `
			Read the essay and answer the questions.
								`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px">
			<img src="img/FriendsPlus/Page77/8.jpg"/>
		</div>

		<div style="font-size:25px;margin-top:50px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span>What are the arguments for a ban on mobile
			phones?</span>
			<p>#</p>
		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span>What are the arguments against a ban?
			</span>
			<p>#</p>

		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span>In which paragraph does the writer give a
			personal opinion? What is it?
			</span>
			<p>#</p>

		</div>
			
								`,
        answer: [
          'They disturb the class if they ring; cyberbullying.',
          'They are great resources for students; they need them before and after school.',
          'The final paragraph; the writer is against banning mobile phones at school.',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P77-E2',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page77/key/E2answerKey.png',
    component: T6v2,
    // maxLength: 1,
    inputSize: 930,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '2',
        title: `
		Find the key phrases in the essay. Which phrases …
								`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<div style="margin-top: 30px;margin-left:200px">
			<img src="img/FriendsPlus/Page77/9.jpg" />
		</div>
		<div style="margin-left: 180px">
			<img src="img/FriendsPlus/Page77/8.jpg"/>
		</div>

		<div style="font-size:25px;margin-top:30px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span>introduce the writer’s opinion?</span>
			<p>#</p>
		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span>help to list reasons and arguments?
			</span>
			<p>#</p>
		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span>help to contrast ideas?
			</span>
			<p>#</p>
		</div>

		
			
								`,
        answer: [
          'Personally, I am (not) in favour of …',
          'One of the arguments for … is …; In addition, …; For instance / example, …',
          'However, … On the one hand, … On the other hand, … In addition, …',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P77-E3',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page77/key/E3answerKey.png',
    component: T6v2,
    // maxLength: 1,
    inputSize: 270,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
		Find phrases 1–5 in the essay. What do the pronouns in <span style="color: rgb(28,190,215)">blue</span>
		refer to?

								`,
        color: 'rgb(78,187,122)',
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 180px">
			<img src="img/FriendsPlus/Page77/8.jpg"/>
		</div>

		<div style="font-size:25px;margin-top:50px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span><span style="color: rgb(28,190,215)">they</span> are a nuisance</span>
			<span>#</span>
		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span>for and against <span style="color: rgb(28,190,215)">this</span> rule
			</span>
			<span>#</span>
		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span>if <span style="color: rgb(28,190,215)">one</span> rings, it disturbs the whole class
			</span>
			<span>#</span>
		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">4</span>
			<span>they say that <span style="color: rgb(28,190,215)">it</span> is worse if students have access
			to the internet
			</span>
			<span>#</span>
		</div>

		<div style="font-size:25px;margin-top:20px">
			<span style="font-weight: bold; margin-right: 10px">5</span>
			<span>students can use apps on their phones to help
			<span style="color: rgb(28,190,215)">them</span> with schoolwork
			</span>
			<span>#</span>
		</div>

		
			
								`,
        answer: [
          'mobile phones.',
          'to ban mobile phones',
          'a mobile phone',
          'cyberbullying.',
          'students',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P77-E4',
    audio: '',
    video: '',
    // recorder: true,
    // exerciseKey: '',
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 270,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '4',
        title: `
		Follow the steps in the Writing Guide
								`,
        color: 'rgb(78,187,122)',
        prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div>
			<img src="img/FriendsPlus/Page77/10.jpg"/>
		</div>

		
								`,
        answer: [],
      },
    ],
  },
};

export default json;
