import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E1',
		exerciseKey: 'img/FriendsPlus/Page35/E1/Key/answerKey.png',
		component: T6v2,
		inputSize: 700,
		stylesTextInput: {
			background: 'transparent',
			border: 'none',
			fontSize: '24px',
			borderBottom: '2px dotted rgb(204, 204, 204)',
		},
		titleQuestion: [
			{
				num: '1',
				title: `
				Study these sentences from exercise 3 on 
				page 34. Then answer the questions.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div>
						We’ve just seen the videos about them.<br />
						I’ve just finished a sailing trip.
					</div>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>Do we use the present perfect with <i>just</i> to talk about recent events or events a long time ago?<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>What’s the position of <i>just</i> in an affirmative present perfect sentence?<br />#</div>
						</div>
					</div>
        `,
				answer: [
					`We use the present perfect with just to talk about recent events.`,
					`Just goes between have and the past participle.`,
				],
			},
		],
	},
	2: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E2',
		exerciseKey: 'img/FriendsPlus/Page35/E2/Key/answerKey.png',
		component: T6v2,
		inputSize: 200,
		stylesTextInput: {
			background: 'transparent',
			border: 'none',
			fontSize: '24px',
			borderBottom: '2px dotted rgb(204, 204, 204)',
			// textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '2',
				title: `
					Complete the sentences with just and the 
					present perfect simple form of the verbs in 
					brackets. Then write the question form.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div>
						<div><i>I’ve just done</i> my first parachute jump. (do)</div>
						<div style='font-style: italic;'>Have you just done your first parachute jump?</div>
					</div>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>
								I <input id='0' /> something funny. (see) <br />
								<input id='1' width='500px'>
							</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>
								Sam <input id='2' /> into the lake. (dive)<br /> 
								<input id='3' width='500px'>
							</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>
								We <input id='4' /> past my friend’s house. (drive)<br /> 
								<input id='5' width='500px'>
							</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>
								He <input id='6' /> the world record again. (break)<br /> 
								<input id='7' width='500px'>
							</div>
						</div>
					</div>
        `,
				answer: [
					`I've just seen`,
					`Have you just seen something funny?`,
					`has just dived`,
					`Has Sam just dived into the lake?`,
					`have just driven`,
					`Have we just driven past my friend's house?`,
					`has just broken`,
					`Has he just broken the world record again?`,
				],
			},
		],
	},
	3: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E3',
		// exerciseKey: 'img/FriendsPlus/Page35/E2/Key/answerKey.png',
		component: T6v2,
		hideBtnFooter: true,
		inputSize: 600,
		stylesTextInput: {
			background: 'transparent',
			border: 'none',
			fontSize: '24px',
			borderBottom: '2px dotted rgb(204, 204, 204)',
			// textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '3',
				title: `
					Explain the situations with your own words. 
					Use the correct present perfect simple form 
					with <i>because</i> and <i>just</i>.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div>
						<div>My left ski is broken ...</div>
						<div style='font-style: italic;'>My left ski is broken because I’ve just had an accident.</div>
					</div>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>The team are celebrating #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>I haven’t got any money now #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>My room is tidy #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>He looks frightened #</div>
						</div>
					</div>
        `,
				answer: [],
			},
		],
	},
	4: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E4',
		exerciseKey: 'img/FriendsPlus/Page35/E4/Key/answerKey.png',
		component: T6v2,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			background: 'transparent',
			border: 'none',
			fontSize: '24px',
			borderBottom: '2px dotted rgb(204, 204, 204)',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '4',
				title: `
					Study sentences 1-3. Then match them with rules A-C.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='margin-top: 20px; display: flex; gap: 50px; align-items: start;'>
						<div>
							<div style='display: flex; gap: 20px; flex-direction: column; padding: 5px 10px; border-radius: 20px; border: 2px solid orange;'>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>1</span>
									<div>Have you decided yet?</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>2</span>
									<div>I still haven’t decided.</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>3</span>
									<div>He’s already decided.</div>
								</div>
							</div>

							<div style='margin-top: 30px;'>
								<div style='font-weight: 600;'>Answers:</div>
								<div style='display: flex; gap: 20px;'>
									<div style='display: flex; gap: 10px;'>
										<span style='font-weight: bold;'>1</span>
										<div>#</div>
									</div>
									<div style='display: flex; gap: 10px;'>
										<span style='font-weight: bold;'>2</span>
										<div>#</div>
									</div>
									<div style='display: flex; gap: 10px;'>
										<span style='font-weight: bold;'>3</span>
										<div>#</div>
									</div>
								</div>
							</div>
						</div>

						<div style="width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(255, 255, 255); border: 2px solid rgb(28, 192, 217); padding: 10px;">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(28, 192, 217); color: white; padding: 5px 10px; border-radius: 20px; text-transform: uppercase;">
								RULES
							</div>
							<div style='display: flex; gap: 20px; flex-direction: column; font-size: 20px; margin-top: 10px; padding: 0 20px'>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>A</span>
									<div>This describes an action which happened sooner than we expected.</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>B</span>
									<div>This emphasises an expected action which hasn’t happened.</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>C</span>
									<div>This asks if an expected action has happened or not.</div>
								</div>
							</div>
						</div>
					</div>
				`,
				answer: [`C`, `B`, `A`],
			},
		],
	},
	5: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E5',
		exerciseKey: 'img/FriendsPlus/Page35/E5/Key/answerKey.png',
		component: T6v2,
		inputSize: 100,
		// maxLength: 1,
		stylesTextInput: {
			background: 'transparent',
			border: 'none',
			fontSize: '22px',
			borderBottom: '2px dotted rgb(204, 204, 204)',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '5',
				title: `
					Complete the rules with <i>still</i>, <i>yet</i> or already.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
				<div style="margin-top: 20px; width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(255, 255, 255); border: 1px solid rgb(28, 192, 217); padding: 10px;">
				<div style="font-weight: bold; font-size: 22px; background-color: rgb(28, 192, 217); color: white; padding: 5px 10px; border-radius: 20px; text-transform: uppercase;">
					RULES
				</div>
				<div style='display: flex; gap: 20px; flex-direction: column; font-size: 22px; margin-top: 10px; padding: 0 20px'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>1</span>
						<div>We use # with negative forms of the present perfect. It goes before <i>hasn’t</i> or <i>haven’t</i>.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>2</span>
						<div>We use # in affirmative sentences. It goes between <i>has / have</i> and the main verb.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>3</span>
						<div>We use # in questions and negative sentences. It goes at the end of the sentence.</div>
					</div>
				</div>
			</div>
				`,
				answer: [`still`, `already`, `yet`],
			},
		],
	},
	6: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E6',
		exerciseKey: 'img/FriendsPlus/Page35/E6/Key/answerKey.png',
		component: T6v2,
		inputSize: 680,
		// maxLength: 1,
		stylesTextInput: {
			background: 'transparent',
			border: 'none',
			fontSize: '24px',
			borderBottom: '2px dotted rgb(204, 204, 204)',
			// textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '6',
				title: `
					Order the words to make sentences.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>my friends and I / our next adventure / decided on / already / have<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>I've / a surfboard / already / borrowed<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>still / transport / organised / haven't / we<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>we / bought / yet / any food / haven't<br />#</div>
						</div>
					</div>
				`,
				answer: [
					`My friends and I have already decided on our next adventure.`,
					`I've already borrowed a surfboard.`,
					`We still haven't organised transport.`,
					`We haven't bought any food yet.`,
				],
			},
		],
	},
	7: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E7',
		exerciseKey: 'img/FriendsPlus/Page35/E7/Key/answerKey.png',
		component: T6v2,
		inputSize: 490,
		// maxLength: 1,
		stylesTextInput: {
			background: 'transparent',
			border: 'none',
			fontSize: '24px',
			borderBottom: '2px dotted rgb(204, 204, 204)',
			// textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '7',
				title: `
					Read Nam’s wish list. Write sentences about 
					what he has and hasn’t done. Use <i>still, yet</i>  
					and <i>already</i>.
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='margin-top: 20px; display: flex; gap: 50px;'>
						<div style='width: 450px;'><img src='img/FriendsPlus/Page35/E7/1.jpg' width='100%' /></div>

						<div>
							<div style='font-weight: 600;'>Answers:</div>
							<div style='display: flex; gap: 20px; flex-direction: column;'>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>1</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>2</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>3</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>4</span>
									<div>#</div>
								</div>
							</div>
						</div>
					</div>
				`,
				answer: [
					`Nam still hasn't seen Dambri Waterfall. | He hasn't seen Dambri Waterfall yet.`,
					`He has already tried surfing.`,
					`He has already sailed around Hạ Long Bay. | He has already sailed around Ha Long Bay.`,
					`He still hasn't climbed up Fansipan. | He hasn't climbed up Fansipan yet.`,
				],
			},
		],
	},
	8: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E8',
		component: T6v2,
		hideBtnFooter: true,
		hintBox: [
			{
				src: [`I’ve already had two classes today.`],
				borderColor: 'rgb(28, 192, 217)',
				arrow: true,
				position: 1,
				width: 430,
			},
			{
				src: [`I still haven’t been swimming this year.`],
				borderColor: 'rgb(28, 192, 217)',
				arrow: true,
				position: 2,
				width: 470,
			},
			{
				src: [`I haven’t been abroad yet.`],
				borderColor: 'rgb(28, 192, 217)',
				arrow: true,
				position: 1,
				width: 350,
			},
		],
		inputSize: 250,
		stylesTextInput: {
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '8',
				title: `
					Think about what you have and 
					haven’t done today, this year and in your life. 
					Write sentences about each time period with 
					the present perfect and <i>still, yet</i> or <i>already</i>.
				`,
				color: 'rgb(28, 192, 217)',
				prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
				<div style='display:flex'>
					<hintBox id='0'></hintBox>
					<div style='margin-left: 30px;'>
						<hintBox id='1'></hintBox>
					</div>
				</div>
					
					<div style='margin-top: 30px; margin-left: 100px;'></div>
					<div style='margin-top: 50px;'><hintBox id='2'></hintBox></div>
				`,
				answer: [],
			},
		],
	},
	9: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U3-P35-E9',
		component: T6,
		finished: {
			text: `
				Look at the photos on pages 30–35. Write 
				sentences about what the people have just 
				done, or haven’t done yet.
			`,
		},
		textareaStyle: { width: 730 },
		hideBtnFooter: true,
		inputSize: 250,
		stylesTextInput: {
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '',
				title: `
				`,
				color: 'rgb(28, 192, 217)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<finished></finished>
					<div><textarea id="0" rows="10"></textarea></div>
				`,
				answer: [],
			},
		],
	},
}

export default json
