import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P38-E1",
    // exerciseKey: "",
    component: T6v2,
    inputSize: 110,

    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
      background: "rgb(225,226,228)",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
		Check the meaning of the words in blue in the eco-intellect
		fact file.
				`,
        color: "rgb(0,103,180)",
      },
    ],

    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
  <div class="container"> 
    <div style="display: flex; gap: 3; flex-wrap: wrap;">

      <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
        <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >

          The UK <span style="color: rgb(3,174,239);">produces</span> more than 
          1.......... million tonnes of rubbish 2........... On average, each person in the UK
          <span style="color: rgb(3,174,239);">throws away</span>  their own weight in rubbish every seven 3..........
          <p
          style="
            position: absolute;
            top: -10px;
            left: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: green;
            color: white;
            text-align: center;
            font-size: 18px;
      
          "
          >
          A
          </p>
        </div>
      </div>




      <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
        <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >
    
          Plastic bags <span style="color: rgb(3,174,239);">damage</span> the environment and kill marine animals. They <span style="color: rgb(3,174,239);">pollute</span> our towns and cities. We <span style="color: rgb(3,174,239);">use</span> each plastic bag for an average of only twelve 4........... 
          <p
          style="
            position: absolute;
            top: -10px;
            left: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: green;
            color: white;
            text-align: center;
            font-size: 18px;
      
          "
          >
          B
          </p>
        </div>
      </div>


      <div class="col-4" style="margin-top: 10px; margin-bottom: 20px;">
        <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >


          Recycling one aluminium can <span style="color: rgb(3,174,239);">saves</span> enough energy to power a television for three  5........... It takes six weeks to <span style="color: rgb(3,174,239);">recycle</span> your old drink can into parts of a plane, a car or a new can!
          
          <p
          style="
            position: absolute;
            top: -10px;
            left: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: green;
            color: white;
            text-align: center;
            font-size: 18px;
      
          "
          >
          C
          </p>
        </div>
      </div>


      <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
        <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >
          We must <span style="color: rgb(3,174,239);">protect</span> our world. We’re <span style="color: rgb(3,174,239);">running out of</span> natural  resources and <span style="color: rgb(3,174,239);">destroying</span>  rainforests. Twenty-four trees  make one tonne of newspaper. A tonne of recycled newspaper saves 6........... trees.
          <p
          style="
            position: absolute;
            top: -10px;
            left: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: green;
            color: white;
            text-align: center;
            font-size: 18px;
      
          "
          >
          D
          </p>
        </div>
      </div>



      <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
        <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >

          In some parts of the world, people can’t <span style="color: rgb(3,174,239);">afford</span> to buy  food. In other parts, people  buy more than they need.
          A typical UK family <span style="color: rgb(3,174,239);">wastes</span>  approximately 7........... of food every year
         
          <p
          style="
            position: absolute;
            top: -10px;
            left: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: green;
            color: white;
            text-align: center;
            font-size: 18px;
      
          "
          >
          E
          </p>
        </div>
      </div>



      <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
        <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >

          Having a shower instead of a bath <span style="color: rgb(3,174,239);">reduces</span>  the amount of water we waste. A toilet made before
          8........... uses 60% more water than toilets made since then
         
          <p
          style="
            position: absolute;
            top: -10px;
            left: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: green;
            color: white;
            text-align: center;
            font-size: 18px;
      
          "
          >
          F
          </p>
        </div>
      </div>


    </div>
  </div>

        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P38-E2",
    audio: "Audios/1.30.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page38/key/answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    stylesTextInput: {
      background: "rgb(225,226,228)",
      fontSize: "24px",
      // borderButoom: '1px solid',
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        <headphone name='1.30'></headphone >
        Read the fact file and complete the sentences with the words in the box. Then listen and check. Which fact is
        the most surprising to you?
        `,
        color: "rgb(27, 191, 215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
          <div style="display: flex; flex-direction: column;align-items: center; border: 2px solid rgb(0,103,180); border-radius: 50px; padding: 10px 20px;">
            <div style="display: flex; justify-content: center; ">
              <span style="margin-right: 40px; font-weight: bold;">seventeen</span>
              <span style="margin-right: 40px; font-weight: bold;">£700</span>
              <span style="margin-right: 40px; font-weight: bold;">100</span>
              <span style="margin-right: 40px; font-weight: bold;">1993</span>       
            </div>
        
            <div style="display: flex; justify-content: center; margin-top: 10px;">
              <span style="margin-right: 40px; font-weight: bold;">every year</span>
              <span style="margin-right: 40px; font-weight: bold;">hours</span>
              <span style="margin-right: 40px; font-weight: bold;">minutes</span>
              <span style="margin-right: 40px; font-weight: bold;">weeks</span>
            </div>
          </div>

          <div class="container"> 
            <div style="display: flex; gap: 3; flex-wrap: wrap; margin-top:40px">

              <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
                <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >

                  The UK <span style="color: rgb(3,174,239);">produces</span> more than 
                  1 #  million tonnes of rubbish <br/> 2 # On average, each person in the UK
                  <span style="color: rgb(3,174,239);">throws away</span>  their own weight in rubbish every seven 3 # 
                  <p
                  style="
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: green;
                    color: white;
                    text-align: center;
                    font-size: 18px;
              
                  "
                  >
                  A
                  </p>
                </div>
              </div>




              <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
                <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >
            
                  Plastic bags <span style="color: rgb(3,174,239);">damage</span> the environment and kill marine animals. They <span style="color: rgb(3,174,239);">pollute</span> our towns and cities. We <span style="color: rgb(3,174,239);">use</span> each plastic bag for an average of only twelve <br/> 4 # 
                  <p
                  style="
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: green;
                    color: white;
                    text-align: center;
                    font-size: 18px;
              
                  "
                  >
                  B
                  </p>
                </div>
              </div>


              <div class="col-4" style="margin-top: 10px; margin-bottom: 20px;">
                <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >


                  Recycling one aluminium can <span style="color: rgb(3,174,239);">saves</span> enough energy to power a television for three  5 # It takes six weeks to <span style="color: rgb(3,174,239);">recycle</span> your old drink can into parts of a plane, a car or a new can!
                  
                  <p
                  style="
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: green;
                    color: white;
                    text-align: center;
                    font-size: 18px;
              
                  "
                  >
                  C
                  </p>
                </div>
              </div>


              <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
                <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >
                  We must <span style="color: rgb(3,174,239);">protect</span> our world. We’re <span style="color: rgb(3,174,239);">running out of</span> natural  resources and <span style="color: rgb(3,174,239);">destroying</span>  rainforests. Twenty-four trees  make one tonne of newspaper. A tonne of recycled newspaper saves 6 # trees.
                  <p
                  style="
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: green;
                    color: white;
                    text-align: center;
                    font-size: 18px;
              
                  "
                  >
                  D
                  </p>
                </div>
              </div>



              <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
                <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >

                  In some parts of the world, people can’t <span style="color: rgb(3,174,239);">afford</span> to buy  food. In other parts, people  buy more than they need.
                  A typical UK family <span style="color: rgb(3,174,239);">wastes</span>  approximately <br/> 7 # of food every year
                
                  <p
                  style="
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: green;
                    color: white;
                    text-align: center;
                    font-size: 18px;
              
                  "
                  >
                  E
                  </p>
                </div>
              </div>



              <div class="col-4" style="margin-top: 10px; margin-bottom: 10px;">
                <div style="position: relative; padding: 20px; background-color:rgb(225,226,228) ;border-radius: 10px;" >

                  Having a shower instead of a bath <span style="color: rgb(3,174,239);">reduces</span>  the amount of water we waste. A toilet made before <br/>
                  8 # uses 60% more water than toilets made since then
                
                  <p
                  style="
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: green;
                    color: white;
                    text-align: center;
                    font-size: 18px;
              
                  "
                  >
                  F
                  </p>
                </div>
              </div>


            </div>
          </div>
        </div>

        
        `,
        answer: [
          "100",
          "every year",
          "weeks",
          "minutes",
          "hours",
          "seventeen",
          "£700",
          "1993",
        ],
      },
    ],
  },
};

export default json;
