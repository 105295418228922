import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import DesignUnderLine2 from "../../components/ExcerciseTypes/Design/DesignUnderLine2";
const json = {
	1: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P17-E1',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page17/E1/Key/answerKey.png',
		component: T6,
		inputSize: 35,
		titleQuestion: [
			{
				num: '1',
				title: `Read the fact file. What things were popular in the eighties? Work in pairs. <br/>Tell your partner what was popular in the eighties using the phrases.`,
				color: '#01a951',
				prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questionImage: [],
		hideBtnFooter: true,
		questions: [
			{
				title: `
              <div style='width: 100vh; display: flex; justify-content:center'><img src='img/FriendsPlus/Page17/E1/1.jpg' width=110% /></div>
              <div style='width: 100vh; display: flex; justify-content:center'><img src='img/FriendsPlus/Page17/E1/2.jpg' /></div>
        `,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P17-E2',
		audio: '',
		video: '',
		isHiddenCheck:true,
		exerciseKey: 'img/FriendsPlus/Page17/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `Read the fact file again and find the words for giving examples in the box.`,
				color: '#01a951',
			},
		],
		component: DesignUnderLine2,
    totalInput: 4,
    titleImage: "",
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
		{ url: 	"img/FriendsPlus/Page17/E2/1.jpg" },],[
		{ url: 	"img/FriendsPlus/Page17/E2/2.jpg" },
		{ url: 	"img/FriendsPlus/Page17/E2/3.jpg",input:1 },
		{ url: 	"img/FriendsPlus/Page17/E2/4.jpg" },],[
		{ url: 	"img/FriendsPlus/Page17/E2/5.jpg" },
		{ url: 	"img/FriendsPlus/Page17/E2/6.jpg",input:1  },
		{ url: 	"img/FriendsPlus/Page17/E2/7.jpg" },
		{ url: 	"img/FriendsPlus/Page17/E2/8.jpg",input:6 },
		{ url: 	"img/FriendsPlus/Page17/E2/9.jpg",input:7,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/10.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/11.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/12.jpg",input:1 },
		{ url: "img/FriendsPlus/Page17/E2/13.jpg",input:2 },
		{ url: "img/FriendsPlus/Page17/E2/14.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/15.jpg",input:7,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/16.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/17.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/18.jpg",input:2 },
		{ url: "img/FriendsPlus/Page17/E2/19.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/20.jpg",input:7,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/21.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/22.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/23.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/24.jpg",input:3 },
		{ url: "img/FriendsPlus/Page17/E2/25.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/26.jpg",input:8,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/27.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/28.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/29.jpg",input:3 },
		{ url: "img/FriendsPlus/Page17/E2/30.jpg",input:4,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/31.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/32.jpg",input:8,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/33.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/34.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/35.jpg",input:4,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/36.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/37.jpg",input:8,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/38.jpg",input:9 },
		{ url: "img/FriendsPlus/Page17/E2/39.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/40.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/41.jpg",input:4,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/42.jpg",input:5,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/43.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/44.jpg",input:9 },
		{ url: "img/FriendsPlus/Page17/E2/45.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/46.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/47.jpg",input:5,isCorrect:true },
		{ url: "img/FriendsPlus/Page17/E2/48.jpg" },
		{ url: "img/FriendsPlus/Page17/E2/49.jpg",input:9 },
		{ url: "img/FriendsPlus/Page17/E2/50.jpg" },],[
		{ url: "img/FriendsPlus/Page17/E2/51.jpg" },
		
	],
       
      
    ],
  },
	3: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P17-E3',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page17/E3/Key/answerKey.png',
		component: T6,
		inputSize: 110,
		stylesTextInput: {
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '3',
				title: `Complete 1–4 with the words from the box in exercise 2 and your own ideas. `,
				color: '#01a951',
			},
		],
		isHiddenCheck: true,

		questionImage: [],
		questions: [
			{
				title: `
          <div style='line-height:2.5'>
		  <div style='display: flex; justify-content:center; margin: 10px'>
		  <span style='border:2px solid rgb(1, 169, 81);border-radius: 15px; padding: 0 10px 0 10px;'>for example &emsp; for instance &emsp; like &emsp; such as</span>
	   </div>
		 <div style='display: flex;'>
		   <div><b>1. &emsp;</b></div>
		   <div>You can do a lot with mobile phones: <input id="0" width="200px"/>, you can … .</div>
		 </div>
		 <div style='display: flex;'>
		   <div><b>2. &emsp;</b></div>
		   <div>You can buy a lot of gadgets now, <input id="1" width="180px"/> … .</div>
		 </div>
		 <div style='display: flex;'>
		   <div><b>3. &emsp;</b></div>
		   <div>My parents like bands # … .</div>
		 </div>
		 <div style='display: flex;'>
		   <div><b>4. &emsp;</b></div>
		   <div>When my grandad / grandma was young, he / she did other things # … .</div>
		 </div> 
		  </div>
        `,
				answer: [
					`for instance / for example`,
					`for example / for instance`,
					`such as`,
					`like`,
				],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P17-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page17/E4/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title: `Follow the steps in the writing guide.`,
				color: '#01a951',
				prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
			},
		],
		textareaStyle: { width: 400 },
		hideBtnFooter: true,
		questions: [
			{
				title: `
              <div style="display: flex; flex-direction:row; flex-wrap:nowrap;">
                <div  style="display: flex;flex:1"><textarea id="0" rows="12" ></textarea><div>
                <div  style="display: flex;flex:1"><img src='img/FriendsPlus/Page17/E4/1.png' width=400px  /></div>
              </div>
        `,
				answer: [],
			},
		],
	},
}

export default json
