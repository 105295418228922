import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
	1: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P86-E1",
		audio: "",
		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page86/key/E1answerKey.png",
		component: T6v2,
		// maxLength: 1,
		inputSize: 120,
		stylesTextInput: {
			//   background: "transparent",
			//   border: "none",
			fontSize: "24px",
			fontWeight: "bold",
			//   textAlign: "center",
		},
		titleQuestion: [
			{
				num: "1",
				title: `
			Complete the verbs for the definitions.
										`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">1</span>
					<span style="font-size: 25px">
						stop something from being allowed
						<span style="font-size: 25px;font-weight: bold;">b</span>
					 	#
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">2</span>
					<span style="font-size: 25px">
						suggest a plan 
						<span style="font-size: 25px;font-weight: bold;">p</span>
					 	#
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">3</span>
					<span style="font-size: 25px">
						speak or act against something you don’t agree with  
						<span style="font-size: 25px;font-weight: bold;">p</span>
					 	#
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">4</span>
					<span style="font-size: 25px">
						organise activities to try to achieve something 
						<span style="font-size: 25px;font-weight: bold;">c</span>
					 	#
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">5</span>
					<span style="font-size: 25px">
						provide help  
						<span style="font-size: 25px;font-weight: bold;">s</span>
					 	#
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">6</span>
					<span style="font-size: 25px">
						give money, clothes, etc. to charity
						<span style="font-size: 25px;font-weight: bold;">d</span>
					 	#
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">7</span>
					<span style="font-size: 25px">
						help at an organisation or charity
						<span style="font-size: 25px;font-weight: bold;">v</span>
					 	#
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">8</span>
					<span style="font-size: 25px">
						write your name on a document to show you agree with what it says
						<span style="font-size: 25px;font-weight: bold;">s</span>
					 	#
					</span>
				</div>
	
				
		
				
										`,
				answer: [
					"an",
					"ropose",
					"rotest",
					"ampaign",
					"upport",
					"onate",
					"olunteer",
					"ign",
				],
			},
		],
	},
	2: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P86-E2",
		audio: "",
		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page86/key/E2answerKey.png",
		component: T6v2,
		// maxLength: 1,
		inputSize: 150,
		stylesTextInput: {
			//   background: "transparent",
			//   border: "none",
			fontSize: "24px",
			fontWeight: "bold",
			textAlign: "center",
		},
		titleQuestion: [
			{
				num: "2",
				title: `
		Complete the sentences with the correct verb
		or noun form of action, access or benefit.
										`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">1</span>
					<span style="font-size: 25px">
						Her life was saved because the firefighters #
						promptly.
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">2</span>
					<span style="font-size: 25px">
						I'm sure you will # greatly from
						doing this course.
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">3</span>
					<span style="font-size: 25px">
						Meeting new people is one of the #
						of travelling.
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">4</span>
					<span style="font-size: 25px">
						Students need a password to get #
						to the library internet.
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">5</span>
					<span style="font-size: 25px">
						The father's quick # helped his
						son balance on the bike again.
					</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">6</span>
					<span style="font-size: 25px">
						The resort is not easily # by
						public transport.
					</span>
				</div>

										`,
				answer: ["acted", "benefit", "benefit", "access", "action", "accessed"],
			},
		],
	},
	3: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P86-E3",
		audio: "",
		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page86/key/E3answerKey.png",
		component: T6v2,
		maxLength: 255,
		inputSize: 650,
		stylesTextInput: {
			//   background: "transparent",
			//   border: "none",
			fontSize: "22px",
			fontWeight: "bold",
			//   textAlign: "center",
		},
		titleQuestion: [
			{
				num: "3",
				title: `
		Complete the following sentences.
										`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;">1</span>
					<span style="font-size: 25px">
						'I always eat lots of fresh fruit and drink
						lots of milk.'<br/>
						<span style="font-size: 25px;margin-left:36px">
							My friend told me that #
						</span>
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">2</span>
					<span style="font-size: 25px">
						'I can call a taxi very quickly.'<br/>
						<span style="font-size: 25px;margin-left:36px">
							The boy said that #
						</span>
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">3</span>
					<span style="font-size: 25px">
						'We are going on holiday to Spain.'<br/>
						<span style="font-size: 25px;margin-left:36px">
							They said that #
						</span>
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">4</span>
					<span style="font-size: 25px">
						'I'll think more about your idea.'<br/>
						<span style="font-size: 25px;margin-left:36px">
							The teacher told me that #
						</span>
						
					</span>
				</div>

										`,
				answer: [
					"he / she always ate lots of fresh fruit and drank lots of milk.",
					"he could call a taxi very quickly.",
					"they were going on holiday to Spain.",
					"he would think more about my idea.",
				],
			},
		],
	},
	4: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P86-E4",
		audio: "Audios/2.35.mp3",
		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page86/key/E4answerKey.png",
		component: T6v2,
		// maxLength: 1,
		inputSize: 150,
		stylesTextInput: {
			//   background: "transparent",
			//   border: "none",
			fontSize: "22px",
			fontWeight: "bold",
			textAlign: "center",
		},
		titleQuestion: [
			{
				num: "4",
				title: `
		<headphone name="2.35"></headphone >
		Listen to Molly and her grandma. Complete the sentences.
										`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;">1</span>
					<span style="font-size: 25px">
					Molly thinks she is sympathetic #, # ,  generous and #.
						</span>
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">2</span>
					<span style="font-size: 25px">
					Molly’s grandma thinks she’s very #  because she always plans her
					activities.
						</span>
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">3</span>
					<span style="font-size: 25px">
					Molly thinks Ruby is #
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">4</span>
					<span style="font-size: 25px">
					Molly’s grandma remembers that Molly
					was # last year.
						</span>
						
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">5</span>
					<span style="font-size: 25px">
					According to Molly, Katniss Everdeen is #
						</span>
						
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">6</span>
					<span style="font-size: 25px">
					Molly’s grandma says that Molly isn’t #
						</span>
						
					</span>
				</div>

										`,
				answer: [
					"honest",
					"thoughtful",
					"patient",
					"organised",
					"clever",
					"brave",
					"heroic",
					"confident",
				],
			},
		],
	},
};

export default json;
