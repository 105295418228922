import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P63-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
			Study these sentences. Then complete rules 1–4.
										`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style="margin-top:10px">
	
				<div style="font-size: 25px;">
					<span >
					You 
						<span style="font-weight: bold;">have to</span>
						be a really strong swimmer.
					</span>
				</div>
	
				<div style="font-size: 25px;">
					<span >
						I 
						<span style="font-weight: bold;">must</span>
						get really good marks.  
					 </span>
				</div>
	
				<div style="font-size: 25px;">
					<span >
						I  
						<span style="font-weight: bold;">don’t have to</span>
						make a decision right now.  
					</span>
				</div>

				<div style="font-size: 25px;">
					<span >
						My friends say I    
						<span style="font-weight: bold;">should</span>
						become a nurse. 
					</span>
				</div>

				<div style="font-size: 25px;">
					<span >
						You      
						<span style="font-weight: bold;">mustn’t</span>
						do something you aren’t sure about 
					</span>
				</div>

				<div style="font-size: 25px;">
					<span >
						I        
						<span style="font-weight: bold;">don’t need</span>
						to do an apprenticeship. 
					</span>
				</div>
			</div>


			<div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px;margin-top:30px">
			<div
				style="
				background-color: rgb(28, 190, 215);
				border-radius: 40px;
				margin: 10px 10px;
				"
			>
				<h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
			</div>

				<div style="margin: 10px 10px;font-size:22px">
				<span style="font-weight: bold;">1 </span>
				We use # to say something is necessary because of a situation or a rule.<br/>
				We use # and need to when the speaker feels that it is very important to do
				something, or when we talk about written rules.
				</div>

				<div style="margin: 10px 10px;font-size:22px">
				<span style="font-weight: bold;">2 </span>
				We use # and its negative form # to give advice, or to say something is a good or bad idea.
				</div>

				<div style="margin: 10px 10px;font-size:22px">
				<span style="font-weight: bold;">3 </span>
				We use # to talk about something that is prohibited.
				
				</div>

				<div style="margin: 10px 10px;font-size:22px">
				<span style="font-weight: bold;">4 </span>
				We use # and # to say something isn’t necessary.
				</div>

			</div>
	
										`,
        answer: [
          "have to",
          "must",
          "should",
          "shouldn't",
          "mustn't",
          "don't have to",
          "don't need to",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P63-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		Complete the school rules using the verbs in
		exercise 1. 
										`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left:100px;position: relative;">
			<img src="img/FriendsPlus/Page63/12.jpg" />
			<p style="position: absolute;top: 190px; left:293px;transform: rotate(2deg);">#</p>
			<p style="position: absolute;top: 267px; left:288px;transform: rotate(2deg);">#</p>

			<p style="position: absolute;top: 302px; left:412px;transform: rotate(2deg);">#</p>
			<p style="position: absolute;top: 376px; left:292px;transform: rotate(2deg);">#</p>

			<p style="position: absolute;top: 420px; left:282px;transform: rotate(2deg);">#</p>
			<p style="position: absolute;top: 498px; left:289px;transform: rotate(2deg);">#</p>

		</div>
	
										`,
        answer: ["must", "don't have to", "must", "mustn't", "must", "should"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P63-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 400,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		Study these situations. Write sentences describing the solution using the verbs in
		brackets.
 
										`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<p style="font-size: 25px;">
			Lee wants to be a doctor. He spends all his time watching TV. (need to)
		</p>

		<p >
			<i style="font-weight: bold;font-size: 18px;">Lee needs to study more</i
		</p>

		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px" >1</span>
			<span>Chad has an interview for college on Monday. He has a
			busy weekend planned with his friends. (shouldn’t)</span> <br/>
			#
		</p>

		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px" >2</span>
			<span>Rea is studying to be a physicist. She isn’t very good at maths.
			(have to)</span> <br/>
			#
		</p>

		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px" >3</span>
			<span>Marina talks too much in class. She failed her last history test.
			(mustn’t)</span> <br/>
			#
		</p>

		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px" >4</span>
			<span>Aidan is a homeschooler. He wants to have more friends. (should)
			</span> <br/>
			#
		</p>

		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px" >5</span>
			<span>It’s the summer holidays. Nicola’s still
			studying. (don’t have to)</span> <br/>
			#
		</p>
		
		
	
										`,
        answer: [
          "Chad shouldn't go out with his friends.",
          "Rea has to practise maths.",
          "Marina mustn't talk in class.",
          "Aidan should go to school.",
          "Nicola doesn't have to study.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P63-E4",
    audio: "Audios/2.16.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
		<headphone name="2.16"></headphone >
		<span style="color:rgb(28,190,215) ;">prONUNCIaTION: Silent letters</span> Listen. Which letters are silent? Practise
		saying the sentences.

 
										`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px" >1</span>
			<span>You mustn’t speak during the exam.</span>
		</p>
		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px" >2</span>
			<span>Jo should apply for this course.</span>
		</p>
		<h1 style="margin-top: 30px">Answers</h1>
		<div style="display: flex;flex-wrap: wrap;gap:20px">
			<p style="font-size: 25px;">
				<span style="font-weight: bold;margin-right:10px" >1</span>
				<span>#</span>
			</p>

			<p style="font-size: 25px;">
				<span style="font-weight: bold;margin-right:10px" >2</span>
				<span>#</span>
			</p>
		</div>
	
										`,
        answer: ["First 't' in mustn't", "'l' in should"],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P63-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    textareaStyle: { width: 700 },
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Talk about the rules at your school. Which rules would you change? Would you add any? Decide which two rules are the most important.
        `,
        color: "rgb(28,190,215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    finished: {
      text: "Use the key phrases to tell a partner about your ideal school.",
    },
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       
        <img style='height:40mm' src="img/FriendsPlus/Page63/14.jpg"/>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P63-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    textareaStyle: { width: 700 },
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },

    finished: {
      text: "Use the key phrases to tell a partner about your ideal school.",
    },
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       
        <img style='height:32mm' src="img/FriendsPlus/Page63/13.jpg"/>
        <textarea id='0' rows='4'></textarea>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
