import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E1/Key/answerKey.png",
    component: T6v2,
    inputSize: 120,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
					Complete the crossword.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page26/E1/1.jpg' width='45%' /></div>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>It’s a new smartphone #.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>She’s got 100,000 # on Twitter.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>It’s only a short #. People will forget about it soon.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div># a comment on my blog.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>How many # does that video have?</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>6</span>
							<div>Social # are changing ways of communication.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>7</span>
							<div>We use electronic #, such as tablets.</div>
						</div>
					</div>
        `,
        answer: [
          "app",
          "followers",
          "craze",
          "post",
          "views",
          "media",
          "gadgets",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
					Choose the correct words.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `generally / obviously`,
          `generally / obviously`,
          `Obviously / Amazingly`,
          `apparently / amazingly`,
        ],
        answers: ["0-0", "1-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
				<div style='display: flex; gap: 20px; flex-direction: column;'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>1</span>
						<div>Jack is in a basketball team. They <input id='0' type='Circle' /> play about once a week.</div>
						
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>2</span>
						<div>It <input id='1' type='Circle' /> takes more time to get from London to Rome by car than by plane.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>3</span>
						<div><input id='2' type='Circle' />, some people spend over two hours on social media every day.</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>4</span>
						<div>I thought this app was free, but <input id='3' type='Circle' /> I have to pay &pound;3 for it.</div>
						
					</div>
				</div>
			  `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E3/Key/answerKey.png",
    component: T6v2,
    inputSize: 350,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences using the correct 
				form of <i>used to</i> and the verb in brackets.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>She # (not like) hip hop music, but she loves it now.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div># (your brother / watch) a lot of cartoons?</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>They # (go) to the cinema often. Now, they watch films online.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>I # (take) that bus, but I don’t any more.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>He # (not have) a laptop, but he bought one last year.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>6</span>
							<div>What time # (eat) dinner when you were younger?</div>
						</div>
					</div>
        `,
        answer: [
          `didn't use to like`,
          `Did your brother use to watch`,
          `used to go`,
          `used to take`,
          `didn't use to have`,
          `did you use to eat`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E4",
    audio: "Audios/1.21.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E4/Key/answerKey.png",
    component: T6v2,
    inputSize: 40,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					<headphone name='1.21'></headphone >
					Listen to Lena telling her friend Ethan 
					about their trip to Italy. Which clothes did 
					Lena and her family bring from England and 
					which ones did they buy in Italy? Write E for 
					England or I for Italy.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div># baggy hoodie</div>
					<div># black boots</div>
					<div># black trousers</div>
					<div># colourful hat</div>
					<div># jeans</div>
					<div># patterned jacket</div>
					<div># plain white blouse</div>
					<div># shorts</div>
					<div># short-sleeved tops</div>
					<div># summer dresses</div>
					<div># tight skirt</div>
					<div># trainers</div>
        `,
        answer: [`I`, `E`, `E`, `I`, `I`, `I`, `I`, `E`, `E`, `E`, `I`, `I`],
      },
    ],
  },
};

export default json;
