import { Input } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Col } from "reactstrap";
import styles from "./case1.module.css";

const Case1 = ({
  column,
  commonStyle,
  content,
  contentStyle,
  handleContent,
  submitted,
  userAnswers,
}) => {
  return (
    <Col
      className={styles.container}
      style={{
        ...commonStyle,
        ...contentStyle,
      }}
    >
      {content?.map((v, position) => {
        return v === "#" ? (
          <Input
            key={position}
            className={styles.input}
            onChange={(e) => handleContent(column, position, e.target.value)}
            style={{
              color: submitted
                ? userAnswers.find(
                    (x) => x.column === column && x.position === position
                  )?.isCorrect
                  ? "green"
                  : "red"
                : "black",
            }}
          />
        ) : (
          <div key={position} className={styles.text}>
            {v}
          </div>
        );
      })}
    </Col>
  );
};

Case1.propTypes = {
  column: PropTypes.number.isRequired,
  commonStyle: PropTypes.object,
  content: PropTypes.array.isRequired,
  contentStyle: PropTypes.object,
  handleContent: PropTypes.func,
  submitted: PropTypes.bool,
  userAnswers: PropTypes.array,
};

export default Case1;
