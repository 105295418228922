import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P9-E1",
    exerciseKey: "img/FriendsPlus/Page9/E1/Key/answerKey.png",
    component: T6v2,
    inputSize: 110,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
					Complete sentences 1-5 with the words in the box. Then answer questions a-c.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    hintBox: [
      {
        src: ["bought", "did", "didn't", "started", "wasn't"],
        width: 500,
        borderColor: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `

					<div style='margin-top: 15px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>I # scared, but I was nervous.</div>
							<hintbox id='0'></hintbox>

						</div>
						<div style='display: flex; gap: 10px;margin-top:-20px'>
							<span style='font-weight: bold;'>2</span>
							<div>I # some new sport clothes.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>I # see you at the sports centre.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>What # you do last weekend?</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>She # to sing professionally when she was only six.</div>
						</div>
					</div>

					<div style='margin-top: 15px; display: flex; gap: 12px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>a</span>
									<div>Which of the verbs in the box are regular and which are irregular?</div>
							</div>
							<div>
								<div style='display:flex'>
								<span style='font-weight: bold; font-style: italic;'>Answer:</span> 
								<div style='padding-left: 30px; display: flex; flex-direction: column'>
									<div style='font-weight: bold;'>regular: #</div>
									<div style='font-weight: bold; '>irregular: #, #,  <input id='8' width='150px' /> </div>
								</div>
								</div>
								 
							</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>b</span>
									<div>How do we form the past simple of regular verbs?</div>
							</div>
							<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> <input id='9' width='500px' /></div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>c</span>
									<div>Which verbs do we use to form questions and negatives?</div>
							</div>
							<div ><span style='font-weight: bold; font-style: italic;'>Answer:</span> #, #</div>
						</div>
					</div>
        `,
        answer: [
          `wasn't`,
          "bought",
          `didn't`,
          "did",
          "started",
          "started",
          `wasn't`,
          "bought",
          `did / didn't | did | didn't `,
          `Add -ed to stem / Add -ed to base form | Add -ed to stem | Add -ed to base form`,
          "did",
          `didn't`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P9-E2",
    exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    component: T6v2,
    inputSize: 110,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the interview using the correct past simple form of <i>be</i>.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; flex-direction: column; gap: 10px;'>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Dan</div>
							<div>
								How old <sup>1</sup> # you when you moved to France?
							</div>	
						</div>

						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Joe</div>
							<div>
								I <sup>2</sup> # four or five. I lived with my mum and my older brother and sister. 
								My dad travelled a lot. He <sup>3</sup> # at home much.
							</div>	
						</div>

						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Dan</div>
							<div>
							<sup>4</sup> # your brother and sister happy to live in France?
							</div>	
						</div>

						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Joe</div>
							<div>
								No, they <sup>5</sup> #. 
								They <sup>6</sup> # lonely because their friends <sup>7</sup> # all in England.
							</div>	
						</div>

							<div style='font-weight: bold;'>Dan</div>
							<div>
								<sup>8</sup> # it a happy time?
							</div>	
						</div>

						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold;'>Dan</div>
							<div>
								No, it <sup>9</sup> # a happy time for my brother and sister. 
								But it <sup>10</sup> # a great time for me!
							</div>	
						</div>
					</div>
        `,
        answer: [
          "were",
          "was",
          `wasn't`,
          "Were",
          `weren't`,
          "were",
          "were",
          "Was",
          `wasn't`,
          "was",
        ],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P9-E3",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 900,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					Write sentences that are true for you using the past simple and the ideas in 1-6. 
					Add a second sentence for each one giving extra information.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
						<div>(go) to a football match last year</div>
						<div style='font-style: italic;'>
							I went to a football match last year. It was between Hoàng Anh Gia Lai and Hà Nội FC.
						</div>
					</div>

					<div style='margin-top: 20px; display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
									<div>(watch) a good TV programme last night</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
									<div>(listen) to music yesterday</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
									<div>(meet) friends on Sunday</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
									<div>(do) homework yesterday</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
									<div>(play) video games this morning</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>6</span>
									<div>(ride) a bicycle last week</div>
							</div>
							<div>#</div>
						</div>
					</div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P9-E3",
    exerciseKey: "img/FriendsPlus/Page9/E4/Key/answerKey.png",
    component: T6v2,
    inputSize: 450,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					Study the object and subject questions below. Then find two more examples of each question in the memory quiz on page 8.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='position: relative;'>
						<div><img src='img/FriendsPlus/Page8/E1/1.jpg' width='55%' /></div>
						
						<div style='position: absolute; top: 10px; left: 500px;'>
							<img src='img/FriendsPlus/Page9/E4/1.jpg' width='65%' />
						</div>

						<div style='position: absolute; top: 277px; left: 500px; border: 2px solid rgb(167, 207, 59); border-radius: 10px; padding: 10px;'>
							<div style='font-weight: bold'>Answers</div>
							<div style='margin-left: 30px;'>
								<div style='font-weight: bold'>Object questions:</div>		
								<div>#</div>
								<div>#</div>
								<div>
									<div style='font-weight: bold'>Subject questions:</div>		
									<div>#</div>
									<div>#</div>
									<div>#</div>
								</div>
							</div>
						</div>						
					</div>
        `,
        answer: [
          "When did you last go",
          "Who did you speak to",
          "Who taught you",
          "Who spoke to you",
          "How many people gave you presents",
        ],
      },
    ],
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P9-E5",
    exerciseKey: "img/FriendsPlus/Page9/E5/Key/answerKey.png",
    component: T6v2,
    inputSize: 410,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					Write object or subject questions using the past simple.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>Who (buy) a present for Rachel?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>What (they / get) her?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>How many people (go) to her party?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>Who (you / meet) there?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>Why (you / leave) early?</div>
							</div>
							<div>#</div>
						</div>
					</div>
        `,
        answer: [
          "Who bought a present for Rachel?",
          "What did they get her?",
          "How many people went to her party?",
          "Who did you meet there?",
          "Why did you leave early?",
        ],
      },
    ],
  },
  6: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P9-E6",
    exerciseKey: "img/FriendsPlus/Page9/E6/Key/answerKey.png",
    component: T6v2,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
				Read Ed’s text message. Then write questions for answers 1-5. Use <i>Who</i> and <i>How many</i>.
				
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='width: 600px; padding: 10px; border-radius: 10px; background: rgb(255, 209, 17);'>
						Kyle told Lucy about the football match and
						they came with me. Kyle bought the tickets
						and they met me at the ground. There were
						70,000 people at the match. Mesut Özil
						scored two goals.
					</div>

					<div style='margin-top: 10px;'>
						<div>Kyle told her.</div>
						<i>Who told Lucy about the match?</i>
					</div>
					
					<div style='margin-top: 10px; display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>Kyle, Lucy and Ed went.</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>Kyle bought them.</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>Kyle and Lucy met Ed there.</div>
							</div>
							<div><input id="2" width="800px" /></div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>There were 70,000 people.</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>He scored two.</div>
							</div>
							<div>#</div>
						</div>
					</div>
        `,
        answer: [
          "Who went to the match?",
          "Who bought the tickets?",
          "Who met Ed at the ground? | Who did Kyle and Lucy meet at the ground? | Who did Kyle and Lucy meet at the ground? / Who met Ed at the ground? | Who did Kyle and Lucy meet at the ground?/Who met Ed at the ground? | Who did Kyle and Lucy meet at the ground?/ Who met Ed at the ground? | Who did Kyle and Lucy meet at the ground? /Who met Ed at the ground?",
          "How many people were there at the match?",
          "How many goals did Mesut Ozil score?",
        ],
      },
    ],
  },
  7: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P9-E7",
    audio: "",
    video: "",
    component: T6v2,
    hintBox: [
      {
        src: ["What was the last event you went to?"],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 1,
        width: 450,
      },
      {
        src: ["It was a funfair last September."],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 2,
        width: 380,
      },
      {
        src: ["Who told you about the funfair?"],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 1,
        width: 380,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
					Work in pairs. Ask and answer
					questions about the events in the box. Use
					both subject and object questions.
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<img src='img/FriendsPlus/Page9/E7/1.jpg'>
					
				`,
        answer: [],
      },
    ],
  },
};

export default json;
