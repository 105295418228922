import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P6-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/answerKey.png",
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "1",
        title: `Study the words in the box. Which of these things do you do every day?`,
        color: "rgb(0, 104, 180)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          borderRadius: "50%",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "cook",
          "do_my_homework",
          "finish",
          "get_home",
          "get_up",
          "go_shopping",
          "go_to_bed",
          "go_to_school",
          "go_to_work",
          "have_breakfast",
          "listen_to_music",
          "relax",
          "tidy_my_room",
          "wake_up",
          "watch_videos",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
				<div style='margin-top: 30px; display: flex; flex-wrap: wrap; gap: 20px; justify-content: center;
					max-width: 750px; padding: 10px; border-radius: 20px; border: 2px solid rgb(0, 104, 180);'>
					<input id='0' type='Circle' />
					<input id='1' type='Circle' />
					<input id='3' type='Circle' />
					<input id='4' type='Circle' />
					<input id='5' type='Circle' />
					<input id='6' type='Circle' />
					<input id='7' type='Circle' />
					<input id='8' type='Circle' />
					<input id='9' type='Circle' />
					<input id='10' type='Circle' />
					<input id='11' type='Circle' />
					<input id='12' type='Circle' />
					<input id='13' type='Circle' />
					<input id='14' type='Circle' />
				</div>
			  `,
    },
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P6-E2",
    audio: "Audios/1.02.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/E2/Key/answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "17px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.02' src='Audios/1.02.mp3'></headphone>
					Complete the interviews using the correct form of the verbs in exercise 1. Then listen and check.
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='position: relative;'>
              <div><img src='img/FriendsPlus/Page6/E2/1.jpg' width='80%' /></div>
              <div style='position: absolute; top: 128px; left: 220px; transform: rotate(-4deg);'>#</div>
              <div style='position: absolute; top: 205px; left: 312px; transform: rotate(-4deg);'>#</div>
              <div style='position: absolute; top: 246px; left: 312px; transform: rotate(-4deg);'>#</div>
              <div style='position: absolute; top: 276px; left: 151px; transform: rotate(-4deg);'>#</div>
              <div style='position: absolute; top: 298px; left: 124px; transform: rotate(-4deg);'>#</div>
              <div style='position: absolute; top: 318px; left: 167px; transform: rotate(-4deg);'>#</div>
              <div style='position: absolute; top: 359px; left: 159px; transform: rotate(-4deg);'>#</div>
              <div style='position: absolute; top: 392px; left: 632px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 412px; left: 609px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 435px; left: 523px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 473px; left: 628px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 491px; left: 732px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 533px; left: 642px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 573px; left: 612px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 634px; left: 648px; transform: rotate(-1deg);'>#</div>
              <div style='position: absolute; top: 674px; left: 709px; transform: rotate(-1deg);'>#</div>
            </div>
          </div>
        `,
        answer: [
          `go shopping`,
          `get up`,
          `wakes`,
          `up`,
          `goes to work`,
          `have breakfast`,
          `go to school`,
          `finish`,
          `do my homework`,
          `get home`,
          `relax`,
          `listen to music`,
          `watch videos`,
          `tidy your room`,
          `cooks`,
          `go to bed`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P6-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/E3/Key/answerKey.png",
    component: T6v2,
    checkDuplicated: true,
    inputSize: 80,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center	",
    },
    titleQuestion: [
      {
        num: "3",
        title: `Look at the words in <span style='color: rgb(0, 173, 238);'>blue</span> in the dialogues in 
				exercise 2. Where do adverbs of frequency go
				in a sentence? Think of more adverbs.`,
        color: "rgb(0, 104, 180)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='margin-bottom: 30px;'>
						<div>Where do adverbs of frequency go in a sentence?</div>
						<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> <input id='0' width='840px' /></div>
						<div>Think of more adverbs.</div>
						<div><span style='font-weight: bold; font-style: italic;'>Answer:</span> #, #</div>
					</div>
					<div><img src='img/FriendsPlus/Page6/E2/1.jpg' width='80%' /></div>
        `,
        answer: [
          `Adverbs of frequency go after the subject and before the verb in a sentence.`,
          "often | never",
          "never | often",
        ],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P6-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/E4/Key/answerKey.png",
    component: T6v2,
    hintBox: [
      {
        src: ["What time do you get up?"],
        borderColor: "rgb(0, 104, 180)",
        arrow: true,
        position: 1,
        width: 350,
      },
      {
        src: [
          "I usually get up at seven o’clock on weekdays. I get up later at the weekend.",
        ],
        borderColor: "rgb(0, 104, 180)",
        arrow: true,
        position: 2,
        width: 350,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
				Work in pairs. Ask and answer
				questions to compare your daily routines.
				Use time expressions, adverbs of frequency
				and the words and phrases in exercises 1 and 2.
				Are your routines similar?
				`,
        color: "rgb(0, 104, 180)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page6/E4/1.jpg' width='50%' /></div>
					<div style='margin-top: 30px; display: flex; gap: 50px;'>
						<hintbox id='0'></hintbox>
						<hintbox id='1'></hintbox>
					</div>
				`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P6-E5",
    audio: "",
    video: "",
    component: T6v2,
    textareaStyle: { width: 740 },
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				Use the information about your partner in exercise 4 to write a paragraph about their daily routine.
				`,
        color: "rgb(0, 104, 180)",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
