import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P97-E1",
    audio: "",
    video: "",
    component: T6v2,
    maxLength: 12,
    inputSize: 150,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page97/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
				Make compound nouns using the words in
the boxes. Then scan the text to find three
compound nouns from the list. What do they
mean?


				
									`,
        color: "#1dc1d8",
      },
    ],
    textareaStyle: { width: 800 },
    // stylesTextInput: {
    //   fontSize: 22,
    //   height: 24,
    //   background: "none",
    //   borderBottom: "none",
    //   color: "white",
    // },
    hintBox: [
      {
        src: ["best", "book", "story", "work"],
        width: 400,
        borderColor: "#1dc1d8",
      },
      {
        src: ["sellers", "shop", "telling", "worm"],
        width: 400,
        borderColor: "#1dc1d8",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='position: relative;margin-left:20px'>
            <img src="img/FriendsPlus/Page97/E1/1.jpg" />
            <span style='position: absolute; top: 7px; left: 485px'>
              <div><hintbox id='0'></hintbox></div>
              <div style='margin:20px 0px'><hintbox id='1'></hintbox></div>
            </span>
            <span style='position: absolute; top: 170px; left: 520px'>
              <div>#,#</div>
              <div style='margin:45px 0px'>#,#</div>
              <div>#,#</div>
            </span>
        </div>

				
			
									`,
        answer: [
          "bestsellers ",
          "booksellers ",
          "bookshop ",
          "bookworm ",
          "storytelling ",
          "workshop",
        ],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P97-E2",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "2",

        title: `
				Read the web page to find out which one is
older, the book festival or the book street.
									`,
        color: "#1dc1d8",
      },
    ],
    component: T6v2,
    // maxLength: 13,
    inputSize: 640,
    checkUppercase: true,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page97/Key/E2answerKey.png",
    textareaStyle: { width: 400 },
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    questions: [
      {
        title: `
						
        <div style='position: relative;margin-left:20px'>
            <img src="img/FriendsPlus/Page97/E1/1.jpg" />
            <span style='position: absolute; top:47px; left: 485px'>
              <u style='font-style:italic;font-weight:bold'>Answers</u>
              <div><textarea id='0' rows='3'></textarea></div>
            </span>
        </div>
									`,
        answer: [
          "International Agatha Christie Festival – it started in 2004.",
        ],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P97-E3",
    audio: "Audios/3.09.mp3",
    video: "",
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name="3.09"></headphone >
        Read and listen to the text again
and answer the questions.`,
        color: "#1dc1d8",
      },
    ],
    component: T6v2,
    // maxLength: 13,
    inputSize: 640,
    checkUppercase: true,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page97/Key/E3answerKey.png",
    textareaStyle: { width: 425 },
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    questions: [
      {
        title: `
            
          <div style='display:flex'>
            <img style='height:90%' src="img/FriendsPlus/Page97/E1/1.jpg" />

            <div style='display:flex;width:20cm;margin-left:-420px'>
                        <div>
                            <div>
                                <b>2</b>&ensp;You like acting and want to attend a
                                suitable workshop. Which one would you
                                go to, the book festival or the book street?
                                Why?<br>
                                <textarea id='1' rows='5'></textarea>
                            </div>


                        </div>
                        <div style='margin:0px 20px'>
                            <div>
                              <b>1</b>&ensp;which one can you attend all year round?<br>
                              <textarea id='0' rows='2'></textarea>
                            </div>

                            <div>
                              <b>3</b>&ensp;Your best friend hopes to meet his
                              favourite writer in person. Which one
                              would you recommend? Why? <br>
                              <textarea id='2' rows='3'></textarea>
                            </div>
                            <div>
                              <b>4</b>&ensp;You don't like reading about murders.
                              Which one should you not go to? Why?<br>
                              <textarea id='3' rows='4'></textarea>
                            </div>
                            <div>
                              <b>5</b>&ensp;You prefer to have your bookssigned by the
                              writers. Which one is your first choice? Why?<br>
                              <textarea id='4' rows='3'></textarea>
                            </div>
                        </div>
                </div>

        
        </div>
									`,
        answer: [
          "I can attend Nguyễn Văn Bình Book Street all year round.",
          "I’d go to the International Agatha Christie Festival because I can watch theatre group perform her plays, take part in writing and drama workshops.",
          "I would recommend Nguyễn Văn Bình Book Street because famous writers will be there.",
          "I shouldn’t go to the International Agatha Christie Festival because Agatha Christie is a detective novelist.",
          "My first choice is going to Nguyễn Văn Bình Book Street because there will be book signing.",
        ],
      },
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P97-E4",
    audio: "",
    video: "",
    fontSize: 25,
    component: D1,
    hideBtnFooter: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Write about a book
        or film festival in your country. Do research
        online and complete the table.`,
        color: "#1dc1d8",
        prefix: { icons: ["fas fa-pencil-alt"], text: "YOUR CULTURE" },
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page97/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page97/E4/2.jpg" },
        { url: "img/FriendsPlus/Page97/E4/3.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page97/E4/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page97/E4/5.jpg" },
        { url: "img/FriendsPlus/Page97/E4/6.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page97/E4/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page97/E4/8.jpg" },
        { url: "img/FriendsPlus/Page97/E4/9.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page97/E4/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page97/E4/11.jpg" },
        { url: "img/FriendsPlus/Page97/E4/12.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page97/E4/13.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page97/E4/14.jpg" },
        { url: "img/FriendsPlus/Page97/E4/15.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page97/E4/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page97/E4/17.jpg" }],
    ],
  },
  5: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P97-E5",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Tell your partner
        about the festival you wrote about in excersice 4.`,
        color: "#1dc1d8",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
  },
};

export default json;
